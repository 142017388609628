<template>
  <modal-layout class="min-h-full" scroll title="Selected products" width="1750">
    <Loading v-if="isLoading" class="z-50" />
    <div v-else>
      <Draggable
        v-if="selectedItems.length"
        :animation="160"
        class="relative grid grid-cols-5 items-stretch gap-4"
        drag-class="draggable-dragging"
        ghost-class="draggable-ghost"
        :value="selectedItems"
        @input="setItemsOrder"
      >
        <div
          v-for="(item, index) in selectedItems"
          :key="`${item.campaignVariantId}.${index}`"
          ref="selectedProducts"
          class="group relative flex cursor-grab flex-col"
        >
          <StoreCampaignCard
            :campaign="item.campaign"
            :campaign-variant-id="item.campaignVariantId"
            class="flex-auto group-hover:border-gray-300"
          >
            <CampaignVariantPicker
              :campaign="item.campaign"
              :value="item.campaignVariantId"
              @input="replaceCampaignVariant(index, $event)"
            />
          </StoreCampaignCard>

          <div
            class="absolute top-0 right-0 m-4 cursor-pointer opacity-50 hover:opacity-100"
            @click="removeItem(index)"
          >
            <RIcon name="trash" />
          </div>
        </div>
      </Draggable>

      <div v-else class="flex h-full flex-auto flex-col items-center justify-center space-y-4 text-center">
        <div class="text-gray-500">There are no products selected right now</div>
        <RButton label="Add more products" prefix-icon="plus" varaint="secondary" @click="addCampaign()" />
      </div>
    </div>

    <template #footer>
      <div class="flex justify-between">
        <RButton label="Add more products" prefix-icon="plus" varaint="secondary" @click="addCampaign()" />
        <RButton :disabled="!selectedItems.length" variant="primary" @click="submit()">Confirm selected</RButton>
      </div>
    </template>
  </modal-layout>
</template>

<script lang="ts">
import { intersection } from 'lodash'
import Vue, { PropType } from 'vue'
import Draggable from 'vuedraggable'

import CampaignVariantPicker from '@/components/dashboard/store/forms/CampaignVariantPicker.vue'
import CampaignPickerModal from '@/components/dashboard/store/modals/CampaignPickerModal.vue'
import StoreCampaignCard from '@/components/dashboard/store/StoreCampaignCard.vue'
import Loading from '@/components/Loading.vue'
import RButton from '@/components/ui/RButton.vue'
import RIcon from '@/components/ui/RIcon.vue'
import { useStoreDesignerStore } from '@/packs/dashboard/storeDesignerStore'
import { Campaign } from '@/types'

type CampaignVariantItem = {
  campaign: Campaign
  campaignVariantId: number
}

export default Vue.extend({
  components: {
    RButton,
    RIcon,
    CampaignVariantPicker,
    StoreCampaignCard,
    Draggable,
    Loading
  },

  props: {
    availableCampaigns: {
      type: Array as PropType<Campaign[]>,
      default: () => undefined
    },

    selectedCampaignVariantIds: {
      type: Array as PropType<number[]>,
      default: () => []
    }
  },

  data() {
    return {
      campaignVariantIds: [...this.selectedCampaignVariantIds],
      designerStore: useStoreDesignerStore(),
      isLoading: false
    }
  },

  computed: {
    selectedItems(): CampaignVariantItem[] {
      return this.campaignVariantIds.reduce((acc, campaignVariantId: number) => {
        const campaign = this.campaigns.find((campaign: Campaign) =>
          campaign.campaign_variants.some(({ id }) => id === campaignVariantId)
        )

        return campaign ? [...acc, { campaign, campaignVariantId }] : acc
      }, [])
    },
    campaigns(): Campaign[] {
      return this.availableCampaigns || this.designerStore.availableCampaigns
    },
    requiredAvailableCampaignsLoaded(): boolean {
      return (
        intersection(this.selectedCampaignVariantIds, this.designerStore.avialableCampaignVariantIds).length ===
        this.selectedCampaignVariantIds.length
      )
    }
  },

  async mounted() {
    // we are usign store designer store for loading campaigns
    if (!this.availableCampaigns) {
      // load required campaigns (they may be on a page which is not yet loaded)
      while (!this.requiredAvailableCampaignsLoaded) {
        this.isLoading = true
        if (this.designerStore.allAvailableCampaignsLoaded) {
          break
        }
        await this.designerStore.loadAvailableCampaigns()
      }
      this.isLoading = false
    }
  },

  methods: {
    submit() {
      this.$emit('close', this.campaignVariantIds)
    },

    async addCampaign() {
      const campaigns = await this.$modal(CampaignPickerModal, {
        campaigns: this.campaigns
      })

      if (!campaigns.length) {
        return
      }

      this.campaignVariantIds = [
        ...this.campaignVariantIds,
        ...campaigns.map((campaign) => campaign.default_campaign_variant_id)
      ]

      this.$nextTick(() => {
        this.$refs.selectedProducts[this.$refs.selectedProducts.length - 1].scrollIntoView?.({
          behavior: 'smooth',
          block: 'nearest'
        })
      })
    },

    removeItem(index: number) {
      this.campaignVariantIds.splice(index, 1)
    },

    setItemsOrder(items: CampaignVariantItem[]) {
      this.campaignVariantIds = items.map((item) => item.campaignVariantId)
    },

    replaceCampaignVariant(index: number, campaignVariantId: number) {
      this.campaignVariantIds.splice(index, 1, campaignVariantId)
    }
  }
})
</script>
