<template>
  <div class="Snackbar fixed top-8 right-0 z-10 my-8 md:top-0 md:m-8">
    <transition-group
      enter-active-class="transition"
      enter-class="opacity-0 translate-y-[-10%]"
      leave-active-class="transition"
      leave-to-class="opacity-0 translate-y-[-10%]"
    >
      <div
        v-for="(message, index) in messagesInReverse"
        :key="message.id"
        class="pointer-events-none absolute top-0 right-0 flex w-screen max-w-3xl justify-end"
      >
        <div
          class="mx-4 w-[calc(100vw-2rem)] pb-2 transition md:mx-0 md:w-auto"
          :style="{ transform: `translateY(${100 * index}%)` }"
        >
          <div
            class="pointer-events-auto inline-flex cursor-default select-none items-start rounded py-2 px-6 text-white"
            :class="{
              'bg-black': message.type === 'success',
              'bg-red-700': message.type === 'error'
            }"
          >
            {{ message.text }}

            <div
              class="-my-2 -mr-3 ml-3 inline-flex cursor-pointer items-center justify-center p-3 text-sm font-semibold opacity-50 hover:opacity-100"
              @click="$emit('remove-message', message.id)"
            >
              <RIcon class="w-4" name="x" />
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import RIcon from '@/components/ui/RIcon.vue'
import { Message } from '@/plugins/snackbar'

export default Vue.extend({
  components: {
    RIcon
  },

  props: {
    messages: {
      type: Array as PropType<Message[]>,
      default: () => []
    }
  },

  computed: {
    messagesInReverse(): Message[] {
      return [...this.messages].reverse()
    }
  }
})
</script>
