import { isArray } from 'lodash'

import { blockBlueprints } from '@/constants/store'
import { MenuItem } from '@/types'
import { BlockData, BlockDataWithBlueprint, ContentItem, Page, PerDeviceData, Store } from '@/types/store'

export const devices = ['desktop', 'mobile']
export const defaultDevice = 'desktop'

export function retrieveDeviceValue(data: any, field: string, device: string = 'desktop'): unknown {
  if (device === defaultDevice) {
    return data[field]
  }

  const value = data.device?.[device]?.[field]

  if (value === undefined) {
    return data[field]
  }

  return value
}

export function retrieveDataPerDevice<T extends PerDeviceData = PerDeviceData>(
  data: T,
  device: typeof devices[number] = 'desktop'
): Omit<T, 'device'> {
  return Object.keys(data)
    .filter((name) => name !== 'device')
    .reduce((acc, key) => ({ ...acc, [key]: retrieveDeviceValue(data, key, device) }), {} as Omit<T, 'device'>)
}

export function updateDeviceValue<T extends PerDeviceData = PerDeviceData>(
  data: T,
  field: string,
  value: unknown,
  device: typeof devices[number] = 'desktop'
) {
  if (device === defaultDevice) {
    return { ...data, [field]: value }
  }

  return {
    ...data,
    device: {
      ...data.device,
      [device]: {
        ...data.device?.[device],
        [field]: value
      }
    }
  }
}

export function joinBlockDataWithBlueprints(data: BlockData[]): BlockDataWithBlueprint[] {
  return data
    .map((data) => {
      const blueprint = blockBlueprints.find(({ id }) => id === data.blueprint_id)

      if (!blueprint) {
        return
      }

      return {
        data,
        blueprint
      }
    })
    .filter(Boolean)
}

export function getActivePageBySlug(store: Store, slug: string): Page {
  const content = store?.customization?.content
  if (!content || !isArray(content)) {
    // XXX: fix for the old customization
    return undefined
  }
  const pages = (content as ContentItem[]).filter(({ type }) => type !== 'link') as Page[]
  const activePage = pages.find((page) => page.slug === slug)

  return activePage || pages[0]
}

export function retrieveMenuItems(store: Store, customDomain?: boolean): MenuItem[] {
  return store.customization.content
    .filter((item) => item.type === 'link' || !item.unlisted)
    .map((item) => {
      if (item.type === 'link') {
        return {
          title: item.title,
          url: item.url
        }
      }

      return {
        title: item.title,
        location: {
          name: customDomain ? 'pages' : 'store',
          params: { pageSlug: item.slug || undefined, slug: store.slug }
        }
      }
    })
}
