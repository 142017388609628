<template>
  <component :is="wrapperComponent" @snap="handleSnap">
    <HeaderLayout
      v-bind="$attrs"
      :background-color="store?.customization.header.background_color"
      :cart-items="cartItems"
      :full-width="store?.customization.header.full_width"
      :layout="store?.customization.header.layout"
      :menu-items="menuItems"
      :text-color="store?.customization.header.text_color"
      :transparent="hasTransparentHeader"
      with-cart
    >
      <template #logo>
        <!-- Custom Domain – Show Logo and/or Store title -->
        <router-link class="flex items-center gap-3 md:gap-4" :title="store?.title" :to="storeHomeRoute">
          <img
            v-if="storeLogo"
            :alt="store?.title"
            :class="{ 'h-12 md:h-14': hasCenteredHeader, 'h-12 md:h-12': !hasCenteredHeader }"
            :src="storeLogo.url"
          />
          <RepresentLogo v-else class="h-10 md:h-14" />
        </router-link>
      </template>
    </HeaderLayout>
  </component>
</template>

<script lang="ts">
import Vue, { Component, PropType } from 'vue'
import { Location } from 'vue-router'

import HeaderLayout from '@/components/Header/HeaderLayout.vue'
import StickyWrapper from '@/components/Header/StickyWrapper.vue'
import RepresentLogo from '@/components/RepresentLogo.vue'
import { config } from '@/config'
import { retrieveMenuItems } from '@/services/CustomizationService'
import { CartItem, MenuItem } from '@/types'
import { Medium } from '@/types/common'
import { Store } from '@/types/store'

export default Vue.extend({
  components: {
    HeaderLayout,
    RepresentLogo,
    StickyWrapper
  },

  props: {
    store: {
      type: Object as PropType<Store>,
      default: null
    },

    cartItems: {
      type: Array as PropType<CartItem[]>,
      default: () => []
    },

    allowsTransparent: Boolean
  },

  data() {
    return {
      isSearchOpen: false,
      isSnapping: false,
      homeRoute: { name: 'Home' } as Location
    }
  },

  computed: {
    wrapperComponent(): Component | string {
      return this.store?.customization.header.static ? 'div' : StickyWrapper
    },

    storeHomeRoute(): Location {
      return this.store ? { name: 'store', params: { slug: this.store?.slug } } : this.homeRoute
    },

    hasTransparentHeader(): boolean {
      return this.allowsTransparent && this.store?.customization.header.transparent && !this.isSnapping
    },

    hasCenteredHeader(): boolean {
      return this.store?.customization.header?.layout === 'centered'
    },

    menuItems(): MenuItem[] {
      return this.store ? retrieveMenuItems(this.store, config.custom_domain) : []
    },

    storeLogo(): Medium {
      return this.store?.customization.urls.logo_url?.[0]
    }
  },

  methods: {
    handleSnap(isSnapping: boolean) {
      this.isSnapping = isSnapping
    }
  }
})
</script>
