import { type AxiosResponse } from 'axios'

import {
  createProductPromoCode,
  getProductPromoCodes,
  updateProductPromoCode
} from '@/services/dashboard/ProductService'
import { createStorePromoCode, getStorePromoCodes, updateStorePromoCode } from '@/services/StoreService'
import { Dashboard } from '@/types/dashboard'

export enum PromoCodeOwner {
  Product,
  Store
}

export function emptyPromoCode(): Dashboard.PromoCode {
  return {
    code: '',
    amount: 10,
    type: 'relative',
    times_used: 0,
    is_active: true,
    valid_until: null,
    id: null,
    max_available: null,
    is_eternal: true,
    is_unlimited: true
  }
}

export function apiResponseToPromoCodeList(data: any): Array<Dashboard.PromoCode> {
  return data.map((promo): Dashboard.PromoCode => {
    return {
      id: promo.id,
      code: promo.code,
      amount: promo.amount,
      type: promo.type,
      times_used: promo.times_used ? Number(promo.times_used) : 0,
      valid_until: promo.valid_until ? new Date(promo.valid_until) : null,
      is_active: promo.is_active,
      max_available: promo.max_available ? Number(promo.max_available) : null,
      is_unlimited: promo.is_unlimited,
      is_eternal: promo.is_eternal
    }
  })
}

export async function getPromoCodes(
  ownerId: number,
  ownerType: PromoCodeOwner
): Promise<Array<Dashboard.PromoCode>> | null {
  switch (ownerType) {
    case PromoCodeOwner.Product:
      return getProductPromoCodes(ownerId)
    case PromoCodeOwner.Store:
      return getStorePromoCodes(ownerId)
    default:
      throw new Error(`Promo code owner ${ownerType} is not implemented`)
  }
}

export async function updatePromoCode(ownerId: number, ownerType: PromoCodeOwner, promo: Dashboard.PromoCode) {
  switch (ownerType) {
    case PromoCodeOwner.Product:
      return updateProductPromoCode(ownerId, promo)
    case PromoCodeOwner.Store:
      return updateStorePromoCode(ownerId, promo)
    default:
      throw new Error(`Promo code owner ${ownerType} is not implemented`)
  }
}

export async function createPromoCode(ownerId: number, ownerType: PromoCodeOwner, promo: Dashboard.PromoCode) {
  switch (ownerType) {
    case PromoCodeOwner.Product:
      return createProductPromoCode(ownerId, promo)
    case PromoCodeOwner.Store:
      return createStorePromoCode(ownerId, promo)
    default:
      throw new Error(`Promo code owner ${ownerType} is not implemented`)
  }
}
