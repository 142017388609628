<template>
  <component :is="component" class="RIcon" />
</template>

<script lang="ts">
import Vue from 'vue'

import { resolveGlobImport } from '@/utils/resolveGlobImport'

const icons = import.meta.glob('../icons/*.vue', { eager: true })

export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      component: undefined
    }
  },

  watch: {
    name: {
      immediate: true,
      handler() {
        this.component = resolveGlobImport(icons, this.name + '.vue')
      }
    }
  }
})
</script>

<style lang="postcss" scoped>
.RIcon {
  @apply inline-block w-6 aspect-square flex-none align-middle;
}
</style>
