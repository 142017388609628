<template>
  <div class="sticky top-0 z-30">
    <slot />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      isSnapping: false
    }
  },

  mounted() {
    document.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll() {
      const scrollY = window.scrollY
      const screenY = this.$el.getBoundingClientRect().y
      const isSnapping = screenY < 2 && scrollY > 0
      if (isSnapping !== this.isSnapping) {
        this.isSnapping = isSnapping
        this.$emit('snap', isSnapping)
      }
    }
  }
})
</script>
