<template>
  <QuillEditor
    class="WysiwygInput r-input free-text"
    :content="value"
    :options="editorOption"
    @change="$emit('input', $event.html)"
  />
</template>

<script lang="ts">
import Vue from 'vue'

const QuillEditor = () =>
  Promise.all([
    import('vue-quill-editor'),
    import('quill/dist/quill.core.css'),
    import('quill/dist/quill.snow.css')
  ]).then(([{ quillEditor }]) => quillEditor)

export default Vue.extend({
  components: {
    QuillEditor
  },

  props: {
    value: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: [['bold', 'italic'], ['link'], [{ 'header': 1 }, { 'header': 2 }]],
          clipboard: {
            matchVisual: false
          }
        }
      }
    }
  }
})
</script>

<style lang="postcss">
.WysiwygInput {
  @apply p-0;

  .ql-toolbar {
    @apply border-x-0 border-t-0 border-b !important;
  }

  .ql-container {
    @apply border-none !important;
  }
}
</style>
