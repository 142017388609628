import { RouteConfig } from 'vue-router'

import { breakpoints } from '@/plugins/breakpoints'
import PageWrapper from '@/views/base/PageWrapper.vue'

const representDomainRoutes: RouteConfig[] = [
  {
    path: '/shop',
    component: () => import('@/views/shop_page/Shop.vue'),
    name: 'shop'
  },
  {
    path: '/store/:slug',
    component: PageWrapper,
    props: {
      loadStore: true,
      fullWidth: true
    },
    redirect: {
      name: 'store'
    },
    meta: {
      hasVisibleNavigation: true
    },
    children: [
      {
        path: ':pageSlug?',
        component: () => import('@/views/StorePage.vue'),
        name: 'store',
        meta: {
          allowsTransparentHeader: true
        },
        props: (route) => ({
          pageSlug: route.params.pageSlug,
          analytics: {
            pageviewTemplate(route) {
              return {
                page: route.path,
                title: document.title,
                location: window.location.href
              }
            }
          }
        })
      }
    ]
  },

  {
    path: '/:productSlug/:longSlug?',
    component: PageWrapper,
    props: {
      fullWidth: false
    },
    redirect: {
      name: 'product'
    },
    children: [
      {
        path: '',
        component: () => import('@/views/product_page/Product.vue'),
        name: 'product',
        props: (route) => ({
          productSlug: route.params.productSlug,
          longSlug: route.params.longSlug,
          variantId: Number(route.query.variant) || undefined
        }),
        meta: {
          footerPadding: breakpoints.isMobile ? 85 : 0,
          analytics: {
            pageviewTemplate(route) {
              return {
                page: route.path,
                title: document.title,
                location: window.location.href
              }
            }
          }
        }
      }
    ]
  }
]

export default representDomainRoutes
