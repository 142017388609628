<template>
  <div class="Loading flex h-full w-full justify-center text-center" :class="small ? 'space-x-1' : 'space-x-3'">
    <RIcon
      :class="small ? 'w-3' : 'w-12'"
      name="spinner"
      :style="{
        'stroke': $color('RIcon.text.muted')
      }"
    />

    <slot />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import RIcon from '@/components/ui/RIcon.vue'

export default Vue.extend({
  components: {
    RIcon
  },

  props: {
    small: Boolean
  }
})
</script>
