<template>
  <div>
    <!-- Loading -->
    <transition v-if="isLoading" leave-active-class="transition duration-200" leave-to-class="opacity-0">
      <div class="absolute inset-0 z-50 flex" :style="{ 'background-color': $color('layout.background.primary') }">
        <Loading class="m-auto" />
      </div>
    </transition>

    <!-- Store landing page -->
    <LandingPage v-else-if="showLandingPage" />

    <!-- Content -->
    <div
      v-else-if="showContent"
      class="flex flex-col flex-1 w-full min-h-screen PageWrapper"
      :class="`PageWrapper--${$route.name}`"
      :style="{
        'background-color': $color('layout.background.primary'),
        'color': $color('layout.text.default'),
        'padding-bottom': `${footerPadding}px`
      }"
    >
      <Discount v-if="discount" :discount="discount" />

      <StoreHeader
        v-if="!isHeaderHidden"
        :allows-transparent="$route.meta.allowsTransparentHeader"
        :cart-items="items"
        :store="store"
      />

      <Alert />

      <div class="flex flex-col flex-1 w-full mx-auto" :class="{ 'max-w-4xl': !fullWidth }">
        <router-view class="grow" />
      </div>
    </div>

    <!-- Not found -->
    <div v-else>
      <StoreHeader :allows-transparent="$route.meta.allowsTransparentHeader" :cart-items="items" :store="store" />
      <NotFound />
    </div>
  </div>
</template>

<script lang="ts">
import isEmpty from 'lodash/isEmpty'
import Vue from 'vue'
import { mapGetters } from 'vuex'

import Alert from '@/components/Alert.vue'
import Discount from '@/components/Discount.vue'
import Loading from '@/components/Loading.vue'
import NotFound from '@/components/NotFound.vue'
import StoreHeader from '@/components/store/next/StoreHeader.vue'
import { config } from '@/config'
import BugsnagService from '@/services/BugsnagService'
import { getActivePageBySlug } from '@/services/CustomizationService'
import FbPixelService from '@/services/FbPixelService'
import VisitService from '@/services/VisitService'
import { Page } from '@/types/store'
import { dynamicallyLoadScript, loadAttentive } from '@/utils/scriptLoader'
import LandingPage from '@/views/landing_page/LandingPage.vue'

export default Vue.extend({
  components: {
    Discount,
    Alert,
    StoreHeader,
    LandingPage,
    Loading,
    NotFound
  },

  props: {
    loadStore: Boolean,
    fullWidth: Boolean,
    hasLegacyCSS: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      customDomain: config.custom_domain,
      isLoading: false
    }
  },

  computed: {
    ...mapGetters('cart', ['discount', 'items']),
    ...mapGetters('main', ['store']),

    showLandingPage() {
      return this.store?.status !== 'active' && this.store?.show_landing_page
    },

    showContent() {
      return !this.loadStore || (this.loadStore && this.store)
    },

    footerPadding(): number {
      return this.$route.meta.footerPadding || 0
    },

    page(): Page {
      return getActivePageBySlug(this.store, this.$route.params.pageSlug)
    },

    isHeaderHidden(): boolean {
      return Boolean(this.$route?.name !== 'product' && this.page?.hideHeader)
    },

    isFooterHidden(): boolean {
      return Boolean(this.$route?.name !== 'product' && this.page?.hideFooter)
    }
  },

  watch: {
    '$route.params.slug'() {
      if (this.loadStore) {
        this.fetchStore()
      }
    }
  },

  async created() {
    if (this.loadStore) {
      await this.fetchStore()
    }

    if (this.store && loadAttentive(this.store.hide_attentive)) {
      dynamicallyLoadScript('https://cdn.attn.tv/represent/dtag.js')
    }

    // XXX: Custom font for /tommyinnit
    if (this.store?.slug === 'tommyinnit') {
      try {
        let root = document.documentElement

        const bodyFont = new FontFace(
          'ProvidenceSans',
          'url("https://d2v48i7nl75u94.cloudfront.net/fonts/Providence%20Sans%20Regular.ttf")',
          {
            style: 'normal',
            weight: '400',
            sizeAdjust: '120%'
          }
        )

        bodyFont.load().then((fontFace) => {
          document.fonts.add(fontFace)
          root.style.setProperty('--r-typeface-body', ['ProvidenceSans', 'sans-serif'].join(','))
          root.style.setProperty('--r-typeface-display', ['ProvidenceSans', 'sans-serif'].join(','))
        })
      } catch (error) {
        BugsnagService.notify(error, 'Error when loading custom typography for store')
      }
    }

    // XXX: Custom font for /jackharlow
    if (this.store?.id === 30458) {
      try {
        let root = document.documentElement

        const displayFont = new FontFace(
          'Benton Sans Black',
          'url("https://d2v48i7nl75u94.cloudfront.net/fonts/BentonSans-Black.ttf")',
          {
            style: 'normal',
            weight: '400'
          }
        )
        const bodyFont = new FontFace(
          'Benton Sans Black',
          'url("https://d2v48i7nl75u94.cloudfront.net/fonts/BentonSans-Regular.ttf")',
          {
            style: 'normal',
            weight: '400'
          }
        )

        bodyFont.load().then((fontFace) => {
          document.fonts.add(fontFace)
          root.style.setProperty('--r-typeface-body', ['Benton Sans Regular', 'sans-serif'].join(','))
        })

        displayFont.load().then((fontFace) => {
          document.fonts.add(fontFace)
          root.style.setProperty('--r-typeface-display', ['Benton Sans Black', 'sans-serif'].join(','))
        })
      } catch (error) {
        BugsnagService.notify(error, 'Error when loading custom typography for store')
      }
    }

    VisitService.saveVisit(this.$route.query)
  },

  methods: {
    async fetchStore() {
      const slug = this.$route.params.slug || config.slug
      const preview = this.$route.query.preview
      const reviewToken = await this.getReviewToken()

      try {
        this.isLoading = true
        await this.$store.dispatch('main/loadStore', { slug, reviewToken: reviewToken, preview })

        document.documentElement.classList.add(`store-${this.store?.slug}`)

        if (!isEmpty(this.store?.fb_pixel_ids)) {
          FbPixelService.trackPageView(this.store.fb_pixel_ids)
        }
      } catch (error) {
        BugsnagService.notify(error, 'Error when loading store')
      } finally {
        this.isLoading = false
      }
    },

    async getReviewToken(): Promise<string> {
      const slug = this.$route.params.slug || config.slug
      return await this.$store.dispatch('main/getReviewToken', slug)
    }
  }
})
</script>
