<template>
  <div class="relative flex flex-auto select-none flex-col rounded-lg border bg-white p-4">
    <img
      class="pointer-events-none mt-auto aspect-square w-full flex-none object-contain"
      loading="lazy"
      :src="campaignVariant.images.front"
    />

    <div class="mt-auto flex-none">
      <div class="truncate pt-4 font-bold">
        {{ campaign.title }}
      </div>

      <div class="truncate text-sm text-gray-500">
        {{ campaign.slug }}
      </div>
    </div>

    <slot />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { Campaign, CampaignVariant } from '@/types'

export default Vue.extend({
  props: {
    campaign: {
      type: Object as PropType<Campaign>,
      required: true
    },

    campaignVariantId: {
      type: Number as PropType<number>,
      default: undefined
    }
  },

  computed: {
    campaignVariant(): CampaignVariant {
      // Not sure if we really do need all of these fallbacks
      return (
        this.campaign.campaign_variants.find(({ id }) => id === this.campaignVariantId) ||
        this.campaign.campaign_variants.find(({ id }) => id === this.campaign.default_campaign_variant_id) ||
        this.campaign.campaign_variants[0]
      )
    }
  }
})
</script>
