<template>
  <modal-layout class="h-1/2" scroll width="1024">
    <template #header>
      <div class="flex items-center justify-between">
        <div class="ModalLayout__title">Select Font</div>

        <RInput v-model="searchQuery" class="w-64" placeholder="Search fonts…" prefix-icon="search" type="search" />
      </div>
    </template>

    <div class="grid grid-cols-3 gap-3">
      <div
        v-for="font in filteredFonts"
        :key="font"
        class="flex cursor-pointer justify-center truncate rounded border p-8 py-6 text-4xl hover:bg-gray-50"
        :class="{ 'border-primary': selectedFont == font }"
        @click="submit(font)"
      >
        <img class="max-h-[24px]" :src="googleFontImageUrl(font)" />
      </div>
    </div>

    <template #footer>
      <div class="flex items-center gap-2">
        <button v-if="selectedFont" class="link link--danger text-sm" tabindex="0" @click="submit('Montserrat')">
          Reset
        </button>

        <RButton class="ml-auto" label="Cancel" @click="cancel()" />
      </div>
    </template>
  </modal-layout>
</template>

<script lang="ts" setup>
import RButton from '@/components/ui/RButton.vue'
import RInput from '@/components/ui/RInput.vue'
import { googleFonts } from '@/constants/googleFonts'
import { googleFontImageUrl } from '@/utils/googleFontImageUrl'
import { useSearchFilter } from '@/utils/useSearchFilter'

defineProps<{
  selectedFont: string
}>()

const emit = defineEmits<{
  (e: 'close', selectedFont?: string)
}>()

const fonts = Object.values(googleFonts)

const { filteredItems: filteredFonts, searchQuery } = useSearchFilter(fonts)

const close = (result?: string) => emit('close', result)
const cancel = () => close()
const submit = (font: string) => close(font)
</script>
