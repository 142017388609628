<template>
  <button class="ShareButton button" data-test-id="shareButton" @click="share()">
    <RIcon class="mr-2" name="share" />
    {{ $t('js.social_share.share') }}
  </button>
</template>

<script lang="ts">
import Vue from 'vue'

import RIcon from '@/components/ui/RIcon.vue'
import ShareModal from '@/modals/ShareModal.vue'

export default Vue.extend({
  components: {
    RIcon
  },

  props: {
    campaignName: {
      type: String,
      required: true
    }
  },

  methods: {
    share() {
      if (this.$breakpoints.isMobile && navigator.share) {
        navigator.share({
          title: this.campaignName,
          url: window.location.href
        })
      } else {
        this.$modal(ShareModal, {
          campaignName: this.campaignName
        })
      }
    }
  }
})
</script>
