<template>
  <modal-layout title="Share on Social Networks">
    <div class="flex items-center justify-center space-x-12 py-12">
      <a
        v-for="network in networks"
        :key="network.id"
        class="group block min-w-[5rem] space-y-3"
        :data-test-id="`sharingLink.${network.id}`"
        :href="network.link"
        rel="noindex, nofollow"
        target="_blank"
      >
        <div
          class="mx-auto flex h-24 w-24 rounded-full text-white transition-transform duration-200 group-hover:scale-105"
          :class="{
            'bg-[#4066ac]': network.id === 'facebook',
            'bg-[#1b95e0]': network.id === 'twitter',
            'bg-gray-500': network.id === 'email'
          }"
        >
          <RIcon class="m-auto w-12" :name="network.icon" />
        </div>

        <div class="text-center text-lg font-semibold">{{ network.label }}</div>
      </a>
    </div>

    <div class="flex items-stretch">
      <input
        class="flex-auto appearance-none rounded-l border bg-gray-100 px-4 outline-none"
        readonly
        type="text"
        :value="baseUrl"
        @click="
          $event.target.select()
          copy()
        "
      />

      <RButton class="w-16 flex-none rounded-l-none px-0" @click="copy()">
        <RIcon v-if="hasBeenCopied" key="copied" name="checkmark" />
        <RIcon v-else name="clipboard-list" />
      </RButton>
    </div>
  </modal-layout>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import RButton from '@/components/ui/RButton.vue'
import RIcon from '@/components/ui/RIcon.vue'

interface SocialLink {
  (baseUrl: string, campaignName?: string): string
}

const campaignUrl = (baseUrl, medium: 'facebook' | 'twitter' | 'email' | 'facebook'): string => {
  return encodeURIComponent(`${encodeURI(baseUrl)}?var=sharing-${medium}`)
}

const emailHref: SocialLink = (baseUrl, productName) =>
  'mailto:?subject=' +
  encodeURIComponent(productName) +
  '&body=Hi, I thought you might want to check ' +
  'this out - it’s ' +
  encodeURIComponent(productName) +
  ' campaign on Represent! Have a look at ' +
  campaignUrl(baseUrl, 'email')

const twitterHref: SocialLink = (baseUrl, productName) =>
  'https://twitter.com/intent/tweet?url=' +
  campaignUrl(baseUrl, 'twitter') +
  '&via=represent&text=Check out ' +
  encodeURIComponent(productName)

const facebookHref: SocialLink = (baseUrl) =>
  'https://www.facebook.com/sharer/sharer.php?display=popup&ref=plugin&u=' + campaignUrl(baseUrl, 'facebook')

export default Vue.extend({
  components: {
    RIcon,
    RButton
  },

  props: {
    campaignName: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      hasBeenCopied: false
    }
  },

  computed: {
    baseUrl() {
      return window.location.href
    },

    networks() {
      return [
        { id: 'facebook', label: 'Facebook', icon: 'facebook', link: facebookHref(this.baseUrl) },
        { id: 'twitter', label: 'Twitter', icon: 'twitter', link: twitterHref(this.baseUrl, this.campaignName) },
        { id: 'email', label: 'Email', icon: 'mail', link: emailHref(this.baseUrl, this.campaignName) }
      ]
    }
  },

  methods: {
    copy() {
      navigator.clipboard.writeText(this.baseUrl)
      this.hasBeenCopied = true
    }
  }
})
</script>
