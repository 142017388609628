import isEmpty from 'lodash/isEmpty'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

import { RootState } from '@/packs/main/store'
import StorageService from '@/services/StorageService'

const VISIT_STORAGE_KEY = 'visit'

const storedVisit = StorageService.load(VISIT_STORAGE_KEY, {})

export type State = {
  kw: string
  var: string
  click: string
  keyword: string
  utm_campaign: string
  referrer_url: string
  landing_url: string
  path: string[]
}

const state = (): State => ({
  kw: storedVisit.kw || '',
  var: storedVisit.var || '',
  click: storedVisit.click || '',
  keyword: storedVisit.keyword || '',
  utm_campaign: storedVisit.utm_campaign || '',
  referrer_url: storedVisit.referrer_url || '',
  landing_url: storedVisit.landing_url || '',
  path: storedVisit.path || []
})

const types = {
  SET_VISIT: 'SET_VISIT',
  CLEAR_VISIT: 'CLEAR_VISIT'
}

const actions: ActionTree<State, RootState> = {
  setVisit({ commit, dispatch }, visit) {
    commit(types.SET_VISIT, { visit })
  },

  clearVisit({ commit, dispatch }) {
    commit(types.CLEAR_VISIT)
  }
}

const mutations: MutationTree<State> = {
  [types.SET_VISIT](state, { visit }) {
    state.kw = visit.kw || state.kw
    state.var = visit.var || state.var
    state.click = visit.click || state.click
    state.keyword = visit.keyword || state.keyword
    if (!isEmpty(visit.utm_source) && !isEmpty(visit.utm_medium)) {
      state.utm_campaign = visit.utm_campaign || state.utm_campaign
    }
    // For referrer and landing url we want to keep first url value
    state.referrer_url = state.referrer_url || visit.referrer_url
    state.landing_url = state.landing_url || visit.landing_url

    let pathValue = visit.var || visit.kw
    // Add value to path only if it's already not present
    if (!isEmpty(pathValue) && state.path.indexOf(pathValue) === -1) {
      state.path.push(pathValue)
    }
    StorageService.persist(VISIT_STORAGE_KEY, state)
  },

  [types.CLEAR_VISIT](state) {
    state.kw = ''
    state.var = ''
    state.click = ''
    state.keyword = ''
    state.utm_campaign = ''
    state.referrer_url = ''
    state.landing_url = ''
    state.path = []
    StorageService.remove(VISIT_STORAGE_KEY)
  }
}

const getters: GetterTree<State, RootState> = {
  visit(state) {
    return state
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
