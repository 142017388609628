<template>
  <div>
    <div v-if="unavailable">
      <div class="my-10 flex flex-col items-center pt-1">
        <img :alt="resourceName" class="w-32" :src="iconSource" />

        <p
          class="mt-3 text-center text-xl font-light md:text-2xl"
          :style="{
            'color': $color('notFound.color.darker')
          }"
        >
          We are sorry,
          <br />
          this {{ resourceName }} is not available anymore.
        </p>

        <div class="relative mb-5 h-10 md:my-5">
          <CampaignSearch class="md:w-128" placeholder="Search other campaigns" />
        </div>
      </div>
    </div>

    <div v-else class="my-8 mx-5 max-w-sm rounded py-12 px-10 pt-1 md:my-16 md:mx-auto">
      <div class="title-3 text-center">
        {{ $t('js.not_found.title') }}
      </div>
      <div class="mt-8 text-center">
        <router-link class="r-button r-button--primary w-full" :to="{ name: 'Home' }">
          {{ $t('js.shopping_cart_empty_state.link') }}
        </router-link>
      </div>
    </div>

    <div v-if="showFeaturedProducts" class="_popular_stores">
      <div
        class="mx-5 border-b pb-6 text-center text-xl font-semibold md:mx-10"
        :style="{
          'border-color': $color('notFound.border.light')
        }"
      >
        {{ $t('js.not_found.looking') }}
      </div>

      <FeaturedProducts kw-tag="not_found" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { MetaInfo } from 'vue-meta'

import CampaignSearch from '@/components/CampaignSearch.vue'
import FeaturedProducts from '@/components/FeaturedProducts.vue'
import { config } from '@/config'
import { resolveGlobImport } from '@/utils/resolveGlobImport'

const images = import.meta.glob('../../assets/assets/images/blank-slate/*.svg', { eager: true })

export default Vue.extend({
  components: {
    CampaignSearch,
    FeaturedProducts
  },

  props: {
    unavailable: {
      type: Boolean
    },

    resourceName: {
      type: String,
      default: 'campaign'
    }
  },

  computed: {
    showFeaturedProducts(): boolean {
      return !config.custom_domain
    },

    iconSource() {
      return resolveGlobImport(images, `${this.resouceName}.svg`)
    }
  },

  // XXX: It is a bit less transparent to set the meta tags outside of a view-component level,
  // but we do want to have them present whenever we display the "not found" message
  metaInfo(): MetaInfo {
    return {
      meta: [
        { name: 'robots', content: 'noindex' },
        { name: 'prerender-status-code', content: '404' }
      ]
    }
  }
})
</script>