import Vue, { VueConstructor } from 'vue'

const sm = 375
const xl = 1280
const xxl = 1440

export const breakpoints: {
  isMobile: boolean
  isDesktop: boolean
  isLargeDesktop: boolean
  device: 'desktop' | 'mobile'
} = Vue.observable({
  isMobile: false,
  isDesktop: false,
  isLargeDesktop: false,
  device: 'desktop'
})

const handleResize = () => {
  breakpoints.isMobile = window.innerWidth < xl
  breakpoints.isDesktop = window.innerWidth >= xl
  breakpoints.isLargeDesktop = window.innerWidth >= xxl
  breakpoints.device = breakpoints.isMobile ? 'mobile' : 'desktop'
}

handleResize()

window.addEventListener('resize', handleResize)

export default {
  install(Vue: VueConstructor) {
    handleResize()
    Vue.prototype.$breakpoints = breakpoints
  }
}
