<template>
  <header
    class="RepresentHeader relative leading-none"
    :class="{
      'shadow': !isTransparent,
      'h-screen': isOpen,
      'transition-colors duration-150': !isOpen
    }"
    data-test-id="storeHeader"
    :style="{
      '--r-input-textColor': textColor,
      '--r-input-bgColor': isTransparent ? 'transparent' : backgroundColor,
      'color': textColor,
      'background-color': isTransparent ? 'transparent' : backgroundColor
    }"
  >
    <!-- Mobile header -->
    <div
      class="relative flex min-h-15 items-center justify-between py-1.5 px-6 md:hidden"
      data-test-id="storeHeader.mobileLayout"
    >
      <!-- Logo -->
      <div :class="{ 'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2': isCentered }">
        <slot name="logo" />
      </div>

      <!-- Cart -->
      <div v-if="withCart" :class="isCentered ? 'order-2' : 'ml-auto mr-5'">
        <CartWidget :cart-items="cartItems" class="h-6 w-6" @click.native="toggle(false)" />
      </div>

      <!-- Navigation -->
      <div>
        <button
          v-if="hasMoreMenuItems"
          aria-label="Toggle menu"
          class="relative flex h-6 w-6 select-none outline-none"
          :class="{ 'order-1': isCentered }"
          tabindex="-1"
          @click.prevent="toggle()"
        >
          <Hamburger class="m-auto" :is-open="isOpen" />
        </button>
      </div>
    </div>

    <!-- Desktop header -->
    <div
      class="border-t md:border-0"
      :class="{ 'hidden md:block py-5 px-6 md:py-3 md:px-8': !isOpen, 'container': !fullWidth }"
      data-test-id="storeHeader.desktopLayout"
    >
      <div class="md:mt-0 md:flex md:items-center md:justify-between md:pt-0">
        <!-- Logo -->
        <div
          aria-label="Logo"
          class="hidden md:block"
          :class="{ 'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2': isCentered }"
        >
          <slot name="logo" />
        </div>

        <!-- Content -->
        <slot />

        <!-- Navigation -->
        <div class="flex flex-col md:flex-row md:items-center" :class="isCentered ? 'order-1' : 'ml-auto'">
          <nav
            aria-label="Primary"
            class="divide-y divide-gray-200 md:mt-0 md:flex md:items-center md:space-x-5 md:divide-y-0"
          >
            <div v-for="(item, index) in menuItems" :key="index" class="w-full md:w-auto" @click="toggle(false)">
              <component
                :is="item.location ? 'router-link' : 'a'"
                class="block cursor-pointer select-none py-5 px-4 md:p-0 md:font-bold"
                :href="item.url"
                :to="item.location"
              >
                {{ item.title }}
              </component>
            </div>

            <slot name="navigation" />
          </nav>
        </div>

        <!-- Cart -->
        <div v-if="withCart" class="hidden md:ml-4 md:block" :class="{ 'order-2': isCentered }">
          <CartWidget :cart-items="cartItems" class="h-12 w-12" @click.native="toggle(false)" />
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import CartWidget from '@/components/CartWidget.vue'
import Hamburger from '@/components/Header/Hamburger.vue'
import RIcon from '@/components/ui/RIcon.vue'
import { CartItem, MenuItem } from '@/types'

export default Vue.extend({
  components: {
    Hamburger,
    CartWidget,
    RIcon
  },

  props: {
    withCart: {
      type: Boolean
    },

    cartItems: {
      type: Array as PropType<CartItem[]>,
      default: () => []
    },

    transparent: {
      type: Boolean
    },

    layout: {
      type: String,
      default: undefined
    },

    fullWidth: {
      type: Boolean
    },

    menuItems: {
      type: Array as PropType<MenuItem[]>,
      default: () => []
    },

    backgroundColor: {
      type: String,
      default: 'white'
    },

    textColor: {
      type: String,
      default: 'inherit'
    }
  },

  data() {
    return {
      isOpen: false
    }
  },

  computed: {
    isTransparent(): boolean {
      return this.transparent && !this.isOpen
    },

    isCentered(): boolean {
      return this.layout === 'centered'
    },

    hasMoreMenuItems(): boolean {
      return this.menuItems?.length
    }
  },

  methods: {
    toggle(state?: boolean) {
      this.isOpen = state === undefined ? !this.isOpen : state
    }
  }
})
</script>
