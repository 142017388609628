import groupBy from 'lodash/groupBy'
import uniq from 'lodash/uniq'
import { v4 as uuid } from 'uuid'

import { abTests } from '@/abTests'
import store from '@/packs/main/store'
import { apiClient } from '@/services/ApiService'
import { CampaignVariant, CartItem, CartQuotes, ShippingAddress } from '@/types'
import { join } from '@/utils/join'
import { makeUrl } from '@/utils/url'

export default {
  async getQuotes({
    email,
    cartId,
    cartToken,
    items,
    shipping,
    validateAddress = false,
    removeInactiveProducts = false
  }: {
    email: string
    cartId: string
    cartToken: string
    items: CartItem[]
    shipping: ShippingAddress
    validateAddress?: boolean
    removeInactiveProducts?: boolean
  }): Promise<CartQuotes> {
    const parsedItems = items.map((item) => {
      return {
        campaign_variant_id: item.product_variant_id,
        campaign_promotion_id: item.promotion_id,
        size: item.size,
        quantity: item.quantity
      }
    })

    const address = {
      country: shipping.country_code || 'US',
      state: shipping.state || '',
      shipping1: shipping.address1 || '',
      shipping2: shipping.address2 || '',
      city: shipping.city || '',
      zip: shipping.zip || '',
      full_name: join([shipping.first_name, shipping.last_name], ' ') || ''
    }

    const payload = {
      email: email,
      cart_id: cartId,
      cart_token: cartToken,
      address: address,
      items: parsedItems,
      validate_address: validateAddress,
      remove_inactive_products: removeInactiveProducts,
      discount_code: store.getters['cart/discount']?.code,
      shipping_change: abTests.shippingChange
    }

    return (await apiClient.post('/carts/quote', payload)).data
  },

  async getUpsellData(items: CartItem[]): Promise<CampaignVariant[]> {
    const payload = {
      campaign_variant_ids: uniq(items.map((item) => item.product_variant_id)),
      campaign_ids: uniq(items.map((item) => item.campaign_id)),
      promotion_ids: uniq(items.map((item) => item.promotion_id))
    }

    return (await apiClient.post('/carts/upsell', payload)).data || []
  },

  async getPaymentIntent(paymentIntentId: string) {
    return (await apiClient.get(`/carts/payment-intent/${paymentIntentId}`)).data
  },

  async loadAbandonedCart(cartId: number, cartToken: string) {
    return (await apiClient.get(`/carts/load-abandoned-cart/${cartId}/${cartToken}`)).data
  }
}

export function createCartItem(
  campaignVariant: CampaignVariant,
  size: string,
  {
    quantity = 1,
    promotion,
    digitalProduct,
    upsell
  }: {
    quantity?: number
    promotion?: any
    digitalProduct?: { is_digital: boolean; request: string }
    upsell?: boolean
  }
): CartItem {
  const cartItem: CartItem = {
    id: uuid(),
    active: campaignVariant.active,
    size,
    quantity,
    price: campaignVariant.price,
    original_price: campaignVariant.price,
    promotion_id: promotion?.id,
    upsell: upsell,
    title: campaignVariant.campaign_title,
    square_image: campaignVariant.store_preview_image,
    product_variant_id: campaignVariant.id,
    campaign_id: campaignVariant.campaign_id,
    product_id: campaignVariant.product_id,
    product_slug: campaignVariant.product_slug,
    ships_by: campaignVariant.ships_by,
    style_name: campaignVariant.style.name,
    eu_sizes: Object.keys(campaignVariant.style?.european_sizing || {}),
    variant_name: campaignVariant.variant.name,
    variant_id: campaignVariant.variant.id,
    variant: campaignVariant.variant,
    bundled_campaign_variants: campaignVariant.bundled_campaign_variants,
    stock: campaignVariant.stock,
    european_shipping: campaignVariant.european_shipping,
    // TODO: Original cart compatibility - remove after migration is done
    campaign_variant_id: campaignVariant.id,
    image_url: campaignVariant.store_preview_image,
    campaign_name: campaignVariant.campaign_title,
    color: campaignVariant.variant.name,
    name: campaignVariant.style.name,
    campaign_url: 'https://merch.cameo.com/' + campaignVariant.product_slug,
    is_digital: digitalProduct?.is_digital,
    youtube_brand: campaignVariant.youtube_brand,
    recommender_enabled: campaignVariant.recommender_enabled
  }

  if (promotion) {
    cartItem.promotion = {
      amount: promotion.amount,
      type: promotion.type
    }
  }

  if (digitalProduct?.is_digital) {
    cartItem.video_recording_request = digitalProduct.request
  }

  return cartItem
}

export function getItemUrl(item: CartItem) {
  return makeUrl('/' + item.product_slug, { variant: item.product_variant_id })
}

export type CartItemsByDate = {
  [date: string]: CartItem[]
}

export function groupCartItemsByShipppingDate(cartItems: CartItem[]): CartItemsByDate {
  return groupBy<CartItem>(cartItems, 'ships_by')
}
