<template>
  <div class="RFormGroup" :class="{ 'RFormGroup--invalid': error }">
    <div v-if="label" class="mb-1.5 flex items-center space-x-1">
      <label class="tracking-wide text-[0.8rem] font-semibold uppercase" :for="$props.for">
        {{ label }}
      </label>

      <div v-if="required" class="font-normal leading-none text-error">*</div>
    </div>

    <div
      v-if="$slots.description || description"
      class="-mt-1.5 max-w-3xl text-[0.85rem] text-muted"
      :class="{ 'mb-2': $slots.default }"
    >
      <slot name="description">
        {{ description }}
      </slot>
    </div>

    <slot />

    <div v-if="$slots.hint || hint" class="mt-1.5 max-w-2xl text-[0.85rem] text-muted">
      <slot name="hint">
        {{ hint }}
      </slot>
    </div>

    <div v-if="error && typeof error === 'string'" class="mt-1.5 text-[0.8rem] text-error">
      {{ error }}
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    label: {
      type: String,
      default: ''
    },

    for: {
      type: String,
      default: ''
    },

    labelSpacer: {
      type: [Number, String],
      default: 0
    },

    required: {
      type: Boolean
    },

    error: {
      type: [String, Boolean],
      default: ''
    },

    description: {
      type: String,
      default: ''
    },

    hint: {
      type: String,
      default: ''
    }
  }
})
</script>

<style lang="postcss" scoped>
.RFormGroup {
  @apply w-full;

  &--invalid {
    :deep() .r-input,
    :deep() .r-input:hover {
      --borderColor: var(--r-color-danger);
    }
  }
}
</style>
