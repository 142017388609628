import Vue, { VueConstructor } from 'vue'

import SnackbarStack from '@/components/SnackbarStack.vue'

export type MessageType = 'success' | 'error'

export interface Message {
  id: number
  text: string
  type: MessageType
}

export interface ShowMessage {
  (message: string, type?: MessageType, duration?: number): void
}

export interface Snackbar {
  success: (message: string) => void
  error: (message: string) => void
  removeAllMessages: () => void
}

const DEFAULT_MESSAGE_DURATION = 10_000
const MAX_MESSAGES = 5

export const snackbar = {
  install(Vue: VueConstructor) {
    let iterator = 1
    let container = null as Vue | null

    const state = Vue.observable({
      messages: [] as Message[]
    })

    const removeMessage = (messageId: number) => {
      state.messages = state.messages.filter(({ id }) => id !== messageId)
    }

    const removeAllMessages = () => {
      state.messages = []
    }

    const createSnackbarContainer = (parent: Vue) => {
      const placeholder = document.createElement('div')
      document.body.appendChild(placeholder)

      return new Vue({
        parent,
        render: (h) =>
          h(SnackbarStack, { props: { messages: state.messages }, on: { 'remove-message': removeMessage } })
      }).$mount(placeholder)
    }

    const showMessage: ShowMessage = (text, type = 'success', duration = DEFAULT_MESSAGE_DURATION) => {
      if (!container) {
        container = createSnackbarContainer(this.$root)
      }

      const id = iterator++

      state.messages.push({ text, type, id })

      if (state.messages.length > MAX_MESSAGES) {
        state.messages.shift()
      }

      if (duration > 0) {
        setTimeout(() => removeMessage(id), duration)
      }
    }

    Vue.prototype.$snackbar = <Snackbar>{
      success: (message: string) => showMessage(message),
      error: (message: string) => showMessage(message, 'error'),
      removeAllMessages: () => removeAllMessages()
    }
  }
}

export default snackbar

declare module 'vue/types/vue' {
  interface Vue {
    $snackbar: Snackbar
  }
}
