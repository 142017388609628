import { isNil, omitBy } from 'lodash'

import { apiAuthClient } from '@/services/ApiService'
import { Paginated } from '@/types/common'
import { Dashboard } from '@/types/dashboard'

// Type helpers

export function emptyBasicProductInfo(): Dashboard.BasicProductInfo {
  return {
    title: '',
    image: '',
    slug: '',
    short_description: '',
    status: Dashboard.ProductStatus.Active,
    published: false,
    is_featured: false,
    ends_at: null,
    rolling_fulfilment_batch: null,
    rolling_fulfilment_period: null,
    default_variant_id: null,
    default_view: null
  }
}

export function emptyBasicProductAnalytics(): Dashboard.BasicProductAnalytics {
  return {
    orders_count: 0,
    items_count: 0,
    profit: 0,
    page_views: 0
  }
}

export function emptyBasicProductSeo(): Dashboard.BasicProductSeo {
  return {
    seo_title: '',
    seo_description: '',
    long_slug: ''
  }
}

export function emptyProductCollectionContainer(): Dashboard.ProductCollectionContainer {
  return {
    used: [] as Array<Dashboard.ProductCollection>,
    available: [] as Array<Dashboard.ProductCollection>
  }
}

// Get Actions

export async function getProductListPaginated(filters: {
  page: number
  from: string
  to: string
  status: 'all' | 'active'
}): Promise<Paginated<Dashboard.BasicProductInfo>> {
  return (
    await apiAuthClient.get(`/dashboard/products`, {
      params: filters
    })
  ).data
}

export async function getBasicProductInfo(productId: number): Promise<Dashboard.BasicProductInfo> {
  return (await apiAuthClient.get(`/dashboard/products/${productId}`)).data
}

export async function getBasicProductAnalytics(productId: number): Promise<Dashboard.BasicProductAnalytics> {
  return (await apiAuthClient.get(`/dashboard/products/${productId}/analytics`)).data
}

export async function getMerchandise(productId: number): Promise<Dashboard.Merchandise> {
  return (await apiAuthClient.get(`/dashboard/products/${productId}/merchandise`)).data
}

export async function getBasicProductSeo(productId: number): Promise<Dashboard.BasicProductSeo> {
  return (await apiAuthClient.get(`/dashboard/products/${productId}/seo`)).data
}

export async function getProductCollections(productId: number): Promise<Dashboard.ProductCollectionContainer> {
  return (await apiAuthClient.get(`/dashboard/products/${productId}/collections`)).data
}

export async function getProductPromoCodes(productId: number): Promise<Array<Dashboard.PromoCode>> {
  return (await apiAuthClient.get(`/dashboard/products/${productId}/promo-codes`)).data
}

// Update Actions

export async function updateBasicInfo(productId: number, info: Dashboard.BasicProductInfo) {
  await apiAuthClient.post(`/dashboard/products/${productId}/info`, omitBy(info, isNil))
}

export async function updateProductPromoCode(productId: number, campaign_promotion: Dashboard.PromoCode) {
  await apiAuthClient.post(`/dashboard/products/${productId}/promo-codes/${campaign_promotion.id}`, {
    campaign_promotion
  })
}

export async function updateBasicProductSeo(productId: number, seo: Dashboard.BasicProductSeo) {
  await apiAuthClient.post(`/dashboard/products/${productId}/seo`, seo)
}

export async function updateProductCollections(productId: number, used: Dashboard.ProductCollection) {
  await apiAuthClient.post(`/dashboard/products/${productId}/collections`, { used })
}

// Create Actions

export async function createProductPromoCode(productId: number, campaign_promotion: Dashboard.PromoCode) {
  await apiAuthClient.post(`/dashboard/products/${productId}/promo-codes`, { campaign_promotion })
}
