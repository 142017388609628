<template>
  <modal-layout class="min-h-full" scroll width="1750">
    <template #header>
      <div class="relative">
        <div class="ModalLayout__title">Add product</div>

        <RInput
          ref="search"
          v-model="searchQuery"
          class="absolute top-1/2 left-1/2 w-128 -translate-x-1/2 -translate-y-1/2"
          placeholder="Search products…"
          prefix-icon="search"
          type="search"
          @keydown.esc="handleEscKeyPress($event)"
        />
      </div>
    </template>

    <div class="relative grid grid-cols-5 gap-4">
      <StoreCampaignCard
        v-for="campaign in filteredCampaigns"
        :key="campaign.id"
        :campaign="campaign"
        class="cursor-pointer"
        :class="[selectedCampaignsById[campaign.id] ? 'border-primary' : 'hover:border-gray-400']"
        @click.native="toggleCampaign(campaign)"
      >
        <div
          v-if="selectedCampaignsById[campaign.id]"
          class="absolute top-0 right-0 m-4 rounded-full bg-primary p-1 text-white"
        >
          <RIcon name="check" />
        </div>
      </StoreCampaignCard>
      <div class="col-span-5 flex justify-center py-3">
        <RButton
          v-if="!designerStore.allAvailableCampaignsLoaded"
          :disabled="designerStore.isLoadingAvailableCampaigns"
          :loading="designerStore.isLoadingAvailableCampaigns"
          variant="secondary"
          @click="loadMoreCampaigns"
        >
          Load more
        </RButton>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-end gap-2">
        <RButton variant="secondary" @click="$emit('close')">Cancel</RButton>

        <RButton :disabled="!hasCampaignsSelected" variant="primary" @click="submitSelectedCampaigns">
          Add selected
        </RButton>
      </div>
    </template>
  </modal-layout>
</template>

<script lang="ts">
import { matchSorter } from 'match-sorter'
import Vue, { PropType } from 'vue'

import StoreCampaignCard from '@/components/dashboard/store/StoreCampaignCard.vue'
import RButton from '@/components/ui/RButton.vue'
import RIcon from '@/components/ui/RIcon.vue'
import RInput from '@/components/ui/RInput.vue'
import { useStoreDesignerStore } from '@/packs/dashboard/storeDesignerStore'
import { Campaign } from '@/types'

export default Vue.extend({
  components: {
    StoreCampaignCard,
    RInput,
    RIcon,
    RButton
  },

  props: {
    availableCampaigns: {
      type: Array as PropType<Campaign[]>,
      default: () => undefined
    }
  },

  data() {
    return {
      searchQuery: '',
      selectedCampaignsById: {} as Record<number, Campaign>,
      designerStore: useStoreDesignerStore()
    }
  },

  computed: {
    filteredCampaigns(): Campaign[] {
      const query = this.searchQuery.trim()

      if (query.length === 0) {
        return this.campaigns
      }

      return matchSorter(this.campaigns, query, {
        keys: ['title']
      })
    },

    numberOfSelectedCampaigns(): number {
      return Object.values(this.selectedCampaignsById).length
    },

    hasCampaignsSelected(): boolean {
      return this.numberOfSelectedCampaigns > 0
    },

    campaigns(): Campaign[] {
      return this.availableCampaigns || this.designerStore.availableCampaigns
    }
  },

  mounted() {
    this.$refs.search?.$refs.input.focus()
  },

  methods: {
    toggleCampaign(campaign: Campaign) {
      if (this.selectedCampaignsById[campaign.id]) {
        const { [campaign.id]: _, ...campaigns } = this.selectedCampaignsById
        this.selectedCampaignsById = campaigns
      } else {
        this.selectedCampaignsById = { ...this.selectedCampaignsById, [campaign.id]: campaign }
      }
    },

    handleEscKeyPress(event: KeyboardEvent) {
      if (this.searchQuery.length) {
        event.preventDefault()
        this.searchQuery = ''
      }
    },

    submitSelectedCampaigns() {
      this.$emit('close', Object.values(Object.values(this.selectedCampaignsById)))
    },

    loadMoreCampaigns() {
      this.designerStore.loadAvailableCampaigns()
    }
  }
})
</script>
