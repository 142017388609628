import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import Vue from 'vue'

import { config } from '@/config'

Bugsnag.start({
  apiKey: '52cde0b03d464ce4fa3cd122097b814c',
  plugins: [new BugsnagPluginVue(Vue)],
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: config.env,
  enabledErrorTypes: {
    unhandledRejections: false
  }
})

export default {
  notify(error: Error, message?: string) {
    Bugsnag.notify(error, (event) => {
      event.addMetadata('metaData', { message, errorObject: error })
    })
  },

  info(errorMessage, message) {
    Bugsnag.notify(new Error(errorMessage), (event) => {
      event.severity = 'info'
      event.addMetadata('metaData', { message: message })
    })
  }
}
