export const knownSocialNetworks: {
  urlPattern: RegExp
  key: string
  icon: string
  label: string
  urlPlaceholder: string
}[] = [
  {
    key: 'facebookUrl',
    urlPattern: /facebook/,
    icon: 'facebook',
    label: 'Facebook',
    urlPlaceholder: 'https://facebook.com/represent'
  },
  {
    key: 'twitterUrl',
    urlPattern: /twitter/,
    icon: 'twitter',
    label: 'Twitter',
    urlPlaceholder: 'https://twitter.com/represent'
  },
  {
    key: 'instagramUrl',
    urlPattern: /instagram/,
    icon: 'instagram',
    label: 'Instagram',
    urlPlaceholder: 'https://instagram.com/represent'
  },
  {
    key: 'youtubeUrl',
    urlPattern: /youtube/,
    icon: 'youtube',
    label: 'Youtube',
    urlPlaceholder: 'https://youtube.com/represent'
  },
  {
    key: 'twitchUrl',
    urlPattern: /twitch/,
    icon: 'twitch',
    label: 'Twitch',
    urlPlaceholder: 'https://twitch.tv/represent'
  },
  {
    key: 'discordUrl',
    urlPattern: /discordapp|discord\.gg/,
    icon: 'discord',
    label: 'Discord',
    urlPlaceholder: 'https://discord.app/represent'
  },
  {
    key: 'tikTokUrl',
    urlPattern: /tiktok\.com/,
    icon: 'tiktok',
    label: 'TikTok',
    urlPlaceholder: 'https://vm.tiktok.com/eYPUa7/'
  }
]
