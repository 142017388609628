<template>
  <div class="space-y-2">
    <div v-for="(link, index) in value" :key="index" class="group">
      <RInput
        placeholder="Profile URL"
        :prefix-icon="resolveIcon(link) || 'globe-alt'"
        :value="link"
        @input="updateLink(index, $event)"
      >
        <template #suffix>
          <div
            class="invisible -ml-4 flex w-12 cursor-pointer opacity-50 hover:opacity-100 group-hover:visible"
            @click="removeLink(index)"
          >
            <RIcon class="m-auto w-5" name="trash" />
          </div>
        </template>
      </RInput>
    </div>

    <div>
      <span class="link" @click="addLink()">Add link</span>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import RIcon from '@/components/ui/RIcon.vue'
import RInput from '@/components/ui/RInput.vue'
import { parseSocialLink } from '@/utils/parseSocialLink'

export default Vue.extend({
  components: {
    RInput,
    RIcon
  },

  props: {
    value: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },

  methods: {
    resolveIcon(link: string) {
      return parseSocialLink(link).icon
    },

    addLink() {
      const value = [...this.value, '']
      this.$emit('input', value)
    },

    removeLink(index: number) {
      const value = [...this.value]
      value.splice(index, 1)
      this.$emit('input', value)
    },

    updateLink(index: number, value: string) {
      const links = [...this.value]
      links[index] = value
      this.$emit('input', links)
    }
  }
})
</script>
