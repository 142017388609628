import { apiAuthClient, apiClient } from '@/services/ApiService'
import { shutdownIntercom } from '@/services/dashboard/IntercomService'
import { Credentials, User } from '@/types'

let userPromise: Promise<User> | null = null

export async function signIn(credentials: Credentials, recaptchaResponse: string): Promise<{ user: any }> {
  const { ...user } = (
    await apiAuthClient.post(
      '/session/sign-in',
      { user: credentials, 'g-recaptcha-response': recaptchaResponse },
      { checkCurrentSessionOnUnauthorized: false }
    )
  ).data

  userPromise = Promise.resolve(user)

  return { user }
}

export async function signUp(signUpData, recaptchaResponse: string): Promise<{ user: any }> {
  const { ...user } = (
    await apiAuthClient.post('/session/sign-up', { user: signUpData, 'g-recaptcha-response': recaptchaResponse })
  ).data

  userPromise = Promise.resolve(user)

  return { user }
}

export async function resetPassword(email: string, recaptchaResponse: string) {
  ;(
    await apiAuthClient.post('/session/reset-password', {
      user: { email: email },
      'g-recaptcha-response': recaptchaResponse
    })
  ).data
}

export async function changePassword(
  password: string,
  resetPasswordToken: string,
  recaptchaResponse: string
): Promise<boolean> {
  await apiAuthClient.post('/session/change-password', {
    user: {
      password: password,
      reset_password_token: resetPasswordToken
    },
    'g-recaptcha-response': recaptchaResponse
  })

  return true
}

export async function signOut(reloadWindow: boolean = true) {
  await apiAuthClient.delete('/session/sign-out')

  userPromise = null
  shutdownIntercom()

  if (reloadWindow) {
    window.location.reload()
  }
}

export async function sendLoginCode(email: string) {
  return (await apiClient.post('/session/send-login-code', { email: email })).data
}

export async function codeLogin(email: string, token: string): Promise<User> {
  return (await apiAuthClient.post('/session/login-code', { email: email, token: token })).data
}

async function loadCurrentUser(): Promise<User> {
  return (await apiAuthClient.get('/session', { checkCurrentSessionOnUnauthorized: false })).data
}

export function resolveCurrentUser(): Promise<User | null> {
  if (!userPromise) {
    userPromise = loadCurrentUser().catch((error) => {
      if (error.response?.status === 401) {
        return null
      }

      return error
    })
  }

  return userPromise
}
