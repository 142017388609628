import each from 'lodash/each'

import { config } from '@/config'

// global `fbq` object injected via inline script
declare var fbq: any

export default {
  initializeUserPixels(pixelIds) {
    pixelIds.forEach((pixelId) => {
      if (typeof fbq.getState === 'undefined') {
        fbq('set', 'autoConfig', false, pixelId)
        fbq('addPixelId', pixelId)
      } else {
        // Initialize user pixel if not already initialized
        const pixel = fbq.getState().pixels.find((pixel) => {
          return pixel.id === pixelId
        })
        if (!pixel) {
          fbq('set', 'autoConfig', false, pixelId)
          fbq('addPixelId', pixelId)
        }
      }
    })
  },

  trackPageView(pixelIds) {
    this.initializeUserPixels(pixelIds)
    fbq('track', 'PageView')
  },

  trackContentView(productVariant, pixelIds) {
    this.trackPageView(pixelIds)
    fbq('track', 'ViewContent', {
      'content_ids': [productVariant.id],
      'content_type': 'product_group'
    })
  },

  trackAddToCart(item, pixelIds) {
    this.initializeUserPixels(pixelIds)
    fbq('track', 'AddToCart', {
      'value': item.price,
      'currency': 'USD',
      'content_type': 'product_group',
      'content_ids': [item.product_variant_id],
      'content_name': item.campaign_name
      // TODO enable when categorization is created (test if its working correctly)
      // 'content_category': item.category
    })
  },

  trackCheckout(items, price, pixelData) {
    fbq('trackSingle', config.fb_pixel_id, 'InitiateCheckout', {
      'value': price,
      'currency': 'USD',
      'content_type': 'product_group',
      'content_ids': items.map((item) => item.product_variant_id),
      'num_items': items.reduce((sum, item) => sum + item.quantity, 0)
      // TODO enable when categorization is created (test if its working correctly)
      // 'content_category': (_.uniq _.sortBy _.pluck(items, 'category'), (cat) -> cat).toString()
    })

    each(pixelData, (pixel) => {
      fbq('set', 'autoConfig', false, pixel.id)
      fbq('addPixelId', pixel.id)

      fbq('trackSingle', pixel.id, 'InitiateCheckout', {
        value: pixel.price,
        currency: 'USD',
        content_type: 'product_group',
        // campaign variant in pixel doesn't contain product slug so take one from passed items
        content_ids: pixel.campaign_variants.map(
          (campaign_variant) =>
            items.find((item) => campaign_variant.id === item.campaign_variant_id).product_variant_id || ''
        ),
        num_items: pixel.campaign_variants.reduce(
          (sum, campaign_variant) =>
            sum + (items.find((item) => campaign_variant.id === item.campaign_variant_id).quantity || 0),
          0
        )
      })
    })
  },

  trackPurchase(orderData) {
    // Fire Represent facebook audience pixel ID
    fbq('trackSingle', config.fb_pixel_id, 'Purchase', {
      value: orderData.orderItems.reduce((sum, item) => sum + parseFloat(item.price), 0),
      currency: 'USD',
      content_type: 'product_group',
      content_ids: orderData.orderItems.map((item) => item.product_variant_id),
      num_items: orderData.orderItems.reduce((sum, item) => sum + item.quantity, 0)
    })

    // Fire Seller facebook custom audience pixel for each campaign
    each(orderData.order.fb_pixel_data, (pixel) => {
      fbq('set', 'autoConfig', false, pixel.id)
      fbq('addPixelId', pixel.id)

      fbq('trackSingle', pixel.id, 'Purchase', {
        value: pixel.seller_profit,
        currency: 'USD',
        content_type: 'product_group',
        // order items in pixel doesn't contain product slug so take one from order -> order items
        content_ids: pixel.order_items.map(
          (order_item) => orderData.orderItems.find((item) => order_item.id === item.id).product_variant_id
        ),
        num_items: pixel.order_items.reduce((sum, item) => sum + item.quantity, 0)
      })
    })
  }
}
