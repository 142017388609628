<template>
  <div class="RNumberInput flex gap-4">
    <RButton prefix-icon="minus" :size="size" variant="secondary" @click="decrease" />

    <RInput
      ref="input"
      v-bind="$attrs"
      v-model="internalValue"
      :aria-label="$attrs.placeholder || 'input'"
      class="RNumberInput_input flex-auto text-center"
      :disabled="disabled"
      :invalid="invalid"
      :max="max"
      :min="min"
      :size="size"
      :type="$attrs.type || 'text'"
    />

    <RButton prefix-icon="plus" :size="size" variant="secondary" @click="increase" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import RButton from '@/components/ui/RButton.vue'
import RInput from '@/components/ui/RInput.vue'

const sizes = ['normal', 'small'] as const

type Size = typeof sizes[number]

export default Vue.extend({
  components: {
    RButton,
    RInput
  },

  inheritAttrs: false,

  props: {
    value: {
      type: [Number, String],
      default: ''
    },

    min: {
      type: [Number, String],
      default: undefined
    },

    max: {
      type: [Number, String],
      default: undefined
    },

    size: {
      type: String as PropType<Size>,
      default: 'normal',
      validator: (value: Size) => sizes.includes(value)
    },

    disabled: Boolean,

    invalid: Boolean
  },

  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    decrease() {
      if (this.min && this.internalValue == this.min) {
        return
      }
      this.internalValue = Number(this.internalValue) - 1
    },
    increase() {
      if (this.max && this.internalValue == this.max) {
        return
      }
      this.internalValue = Number(this.internalValue) + 1
    }
  }
})
</script>

<style lang="postcss" scoped>
.RNumberInput_input :deep() input:read-only::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
}
</style>
