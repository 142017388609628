<template>
  <div class="Timer">
    <div v-if="!showExpiredMessage" class="flex items-center justify-center leading-8" data-test-id="timer">
      <div v-for="(value, units, index) in timeFragments" :key="units" class="flex">
        <div v-if="index" class="px-1" :class="[size]">:</div>
        <div class="text-center" :class="withBackground ? 'w-14' : 'w-12'">
          <div :class="[size, { 'bg-black-500 text-white py-1': withBackground }]">
            <span v-if="units === 'days'">
              {{ value }}
            </span>
            <span v-else>
              {{ value | twoDigits }}
            </span>
          </div>
          <div v-if="showUnits" class="text-small" :class="{ 'font-semibold': isLarge, 'mt-1': withBackground }">
            {{ $t('js.campaign_timer.' + units) }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center" :class="[size]">
      {{ expireMessage }}
    </div>
  </div>
</template>

<script lang="ts">
import { padStart } from 'lodash'
import Vue, { PropType } from 'vue'

const sizes = ['text-sm', 'text-normal', 'text-xl', 'text-2xl'] as const

type Size = typeof sizes[number]

export default Vue.extend({
  filters: {
    twoDigits(value: string | number) {
      return padStart(value.toString(), 2, '0')
    }
  },

  props: {
    endTime: {
      type: [String, Date],
      required: true
    },
    size: {
      type: String as PropType<Size>,
      default: 'text-xl',
      validator: (value: Size) => sizes.includes(value)
    },
    withBackground: {
      type: Boolean,
      default: false
    },
    showUnits: {
      type: Boolean,
      default: true
    },
    lastDay: {
      type: Boolean,
      default: false
    },
    expireMessage: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      interval: null,
      timeNow: null,
      date: null,
      diff: 0
    }
  },

  computed: {
    timeFragments(): object {
      let fragments = {
        days: Math.trunc(this.diff / 60 / 60 / 24),
        hours: Math.trunc(this.diff / 60 / 60) % 24,
        minutes: Math.trunc(this.diff / 60) % 60,
        seconds: Math.trunc(this.diff) % 60
      }

      if (this.lastDay) {
        delete fragments.days
      }

      return fragments
    },

    isLarge(): boolean {
      return this.size === 'large'
    },

    showExpiredMessage(): boolean {
      return this.expireMessage && this.diff <= 0
    }
  },

  watch: {
    timeNow(timeNow) {
      this.diff = this.date - timeNow

      if (this.diff <= 0) {
        this.diff = 0
        clearInterval(this.interval)
      }
    }
  },

  created() {
    this.date = Math.trunc(Date.parse(this.endTime) / 1000)
    this.timeNow = Math.trunc(new Date().getTime() / 1000)

    this.interval = setInterval(() => {
      this.date = Math.trunc(Date.parse(this.endTime) / 1000)
      this.timeNow = Math.trunc(new Date().getTime() / 1000)
    }, 1000)
  }
})
</script>
