import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'

export type Validator = (options: {
  value: any
  data: any
  resolve: () => void
  reject: (message?: any) => void
}) => Promise<void> | void

export type ValidationErrors<T = any> = {
  [key in keyof T]?: any
}

export type ValidationResults<T = any> = {
  isValid: boolean
  errors: ValidationErrors<T>
}

export type ValidationRules<T = any> = {
  [key in keyof T]?: Validator[]
}
export interface ValidationFormInterface<T = any> {
  validate: () => Promise<ValidationResults<T>>
}

const ValidationService = {
  _validateAttrValue(data: any, attr: string, validator: Validator) {
    const value = data[attr]

    if (typeof validator !== 'function') {
      return undefined
    }

    return new Promise((resolve) => {
      validator({
        value,
        data,
        resolve: () => resolve(undefined),
        reject: (messsage) => resolve(messsage || true)
      })
    })
  },

  async validate<T = any>(data: any, rules: ValidationRules<T>): Promise<ValidationResults<T>> {
    const promises = (Object.entries(rules) as [string, Validator[]][]).map(async ([attr, validators]) => {
      const useValidator = (validator: Validator) => this._validateAttrValue(data, attr, validator)

      const results = await Promise.all(validators.map(useValidator))
      const errors = results.filter(Boolean)
      return { attr, errors }
    })

    const errors = (await Promise.all(promises))
      .filter(({ errors }) => errors.length > 0)
      .reduce(
        (errorsByAttr, error) => ({
          ...errorsByAttr,
          [error.attr]: error.errors.shift()
        }),
        {}
      )

    return {
      isValid: isEmpty(errors),
      errors
    }
  },

  async validateAny<T = any>(validations: any[], validationError: ValidationErrors<T>): Promise<ValidationResults<T>> {
    const results: ValidationResults<T>[] = await Promise.all(
      validations.map(async (validation) => this.validate(validation.item, validation.rules))
    )

    if (results.some((result) => result.isValid === true)) {
      return {
        isValid: true,
        errors: {}
      }
    } else {
      return {
        isValid: false,
        errors: { ...Object.assign({}, ...results.map((result) => result.errors)), ...validationError }
      }
    }
  },

  async validateAll<T = any>(
    items: T[],
    rules: object | ((item: T) => ValidationRules)
  ): Promise<ValidationResults<T>> {
    const validations = items.map(async (item) =>
      this.validate(item, typeof rules === 'function' ? rules(item) : rules)
    )

    const results: ValidationResults<T> = (await Promise.all(validations)).reduce(
      (results, { isValid, errors }, index) => {
        return {
          isValid: results.isValid && isValid,
          errors: isEmpty(errors) ? results.errors : { ...results.errors, [index]: errors }
        }
      },
      {
        isValid: true,
        errors: {}
      }
    )

    return results
  }
}

export default ValidationService

// Constants

export const maxTextLength = 511

// Validators

export const isRequired = (message?: string): Validator => {
  return ({ value, resolve, reject }) => {
    !value && value !== 0 ? reject(message) : resolve()
  }
}

export const isMaxLength = (length: number, message?: string): Validator => {
  return ({ value, resolve, reject }) => {
    ;((value && value.length) || 0) > length ? reject(message) : resolve()
  }
}

export const isMinLength = (length: number, message?: string): Validator => {
  return ({ value, resolve, reject }) => {
    ;((value && value.length) || 0) < length ? reject(message) : resolve()
  }
}

export const isMinValue = (number: number, message?: string): Validator => {
  return ({ value, resolve, reject }) => {
    !isNaN(value) && parseInt(value) < number ? reject(message) : resolve()
  }
}

export const isInRange = (min: number, max: number, message?: string): Validator => {
  return ({ value, resolve, reject }) => {
    !isNaN(value) && parseInt(value) > min && parseInt(value) <= max ? resolve() : reject(message)
  }
}

export const isEmailValid = (message?: string): Validator => {
  return ({ value, resolve, reject }) => {
    !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? reject(message) : resolve()
  }
}

export const isPhoneValid = (message?: string): Validator => {
  return ({ value, resolve, reject }) => {
    value && value.length < 5 ? reject(message) : resolve()
  }
}

export const isTextValid = (message?: string): Validator => {
  return ({ value, resolve, reject }) => {
    value && value.length > maxTextLength ? reject(message) : resolve()
  }
}

export const isDateValid = (message?: string): Validator => {
  return ({ value, resolve, reject }) => {
    value && value.length > 0 && dayjs(value).isValid() ? resolve() : reject(message)
  }
}

export const isDateInFuture = (message?: string): Validator => {
  return ({ value, resolve, reject }) => {
    value && value.length > 0 && dayjs(value).isAfter(dayjs(), 'day') ? resolve() : reject(message)
  }
}

export const isURLValid = (message?: string): Validator => {
  return ({ value, resolve, reject }) => {
    ;/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(value)
      ? resolve()
      : reject(message)
  }
}
