import { ActionTree, GetterTree, MutationTree } from 'vuex'

import { RootState } from '@/packs/main/store'
import StorageService from '@/services/StorageService'
import StoreService from '@/services/StoreService'
import { Store } from '@/types/store'

const reviewTokenStorageKey = 'reviewTokens'
const authenticityTokenStorageKey = 'authenticityToken'
const promotionCodeStorageKey = 'promotionCode'
const currentUserKey = 'current_user'
const defaultReviewTokenKey = 'default_review_token_key'

const types = {
  STORE_SET: 'STORE_SET',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  REVIEW_TOKEN_SET: 'REVIEW_TOKEN_SET',
  AUTHENTICITY_TOKEN: 'AUTHENTICITY_TOKEN',
  PROMOTION_CODE_SET: 'PROMOTION_CODE_SET'
}

export type State = {
  store: Store
  currentUser: any
  reviewToken?: Record<string, string>
  authenticityToken?: string
  promotionCode?: string
}

const state = (): State => ({
  store: null,
  currentUser: StorageService.load(currentUserKey),
  reviewToken: StorageService.load(reviewTokenStorageKey) || {},
  authenticityToken: StorageService.load(authenticityTokenStorageKey)
})

const actions: ActionTree<State, RootState> = {
  async loadStore(
    { commit },
    { slug, reviewToken, preview }: { slug: string; reviewToken?: string; preview?: string }
  ) {
    const store = await StoreService.store(slug, reviewToken, preview)
    commit(types.STORE_SET, { store })
  },

  setCurrentUser({ commit }, user) {
    commit(types.SET_CURRENT_USER, { user })
    StorageService.persist(currentUserKey, user)
  },

  setReviewToken({ commit, state }, { reviewToken, slug }: { reviewToken?: string; slug?: string }) {
    const storeSlug = slug || state.store?.slug || defaultReviewTokenKey

    commit(types.REVIEW_TOKEN_SET, { storeSlug, reviewToken })

    let reviewTokens = state.reviewToken

    if (reviewToken) {
      reviewTokens[storeSlug] = reviewToken
    } else {
      delete reviewTokens[storeSlug]
    }

    StorageService.persist(reviewTokenStorageKey, reviewTokens)
  },

  setAuthenticityToken({ commit }, authenticityToken?: string) {
    commit(types.AUTHENTICITY_TOKEN, { authenticityToken })

    if (authenticityToken) {
      StorageService.persist(authenticityTokenStorageKey, authenticityToken)
    } else {
      StorageService.remove(authenticityTokenStorageKey)
    }
  },

  setPromotionCode({ commit }, promotionCode?: string) {
    commit(types.PROMOTION_CODE_SET, { promotionCode })

    if (promotionCode) {
      StorageService.persist(promotionCodeStorageKey, promotionCode)
    } else {
      StorageService.remove(promotionCodeStorageKey)
    }
  },

  getReviewToken({ state }, storeSlug?: string) {
    const slug = storeSlug || state.store?.slug
    return state.reviewToken[slug] || state.reviewToken[defaultReviewTokenKey]
  }
}

const mutations: MutationTree<State> = {
  [types.STORE_SET](state, { store }) {
    state.store = store
  },

  [types.SET_CURRENT_USER](state, { user }) {
    state.currentUser = user
  },

  [types.REVIEW_TOKEN_SET](state, { storeSlug, reviewToken }) {
    state.reviewToken[storeSlug] = reviewToken
  },

  [types.AUTHENTICITY_TOKEN](state, { authenticityToken }) {
    state.authenticityToken = authenticityToken
  },

  [types.PROMOTION_CODE_SET](state, { promotionCode }) {
    state.promotionCode = promotionCode
  }
}

const getters: GetterTree<State, RootState> = {
  store(state) {
    return state.store ? { ...state.store, customization: state.store?.next_customization } : null
  },

  currentUser(state) {
    return state.currentUser
  },

  authenticityToken(state): string {
    return state.authenticityToken
  },

  promotionCode(state): string {
    return state.promotionCode
  },

  authorizedUser(state): boolean {
    return Boolean(
      state.currentUser &&
        ['admin', 'customer_support', 'campaign_manager'].some((role) => state.currentUser.roles.includes(role))
    )
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
