import dayjs from 'dayjs'
import { mapKeys, merge, snakeCase } from 'lodash'

import { config } from '@/config'
import { User } from '@/types'

export function loadIntercom() {
  ;(function () {
    var w = window as any
    var ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      var d = document
      var i: any = function () {
        i.c(arguments)
      }
      i.q = []
      i.c = function (args) {
        i.q.push(args)
      }
      w.Intercom = i
      var l = function () {
        var s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = 'https://widget.intercom.io/widget/' + config.intercom_app_id
        var x = d.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(s, x)
      }
      if (document.readyState === 'complete') {
        l()
      } else if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }
  })()
}

export function initializeIntercom(user: User) {
  // We want to load Intercom only if the user is approved and we're not in preview iframe of store designer
  if ((window as any).Intercom && !window.location.pathname.includes('preview') && user.approvalStatus === 'approved') {
    const customData = mapKeys(user.intercomCustomData || {}, (_, key) => snakeCase(key))
    const intercomData = merge(
      {
        app_id: config.intercom_app_id,
        user_id: user.id,
        user_hash: user.intercomUserHash,
        email: user.email,
        created_at: dayjs(user.createdAt).unix(),
        full_name: user.name,
        phone: user.phone,
        city: user.city,
        state: user.state,
        country: user.country,
        zip: user.zip,
        time_zone: user.timeZone
      },
      customData
    )

    ;(window as any).Intercom('boot', intercomData)
  }
}

export function shutdownIntercom() {
  if ((window as any).Intercom) {
    ;(window as any).Intercom('shutdown')
  }
}
