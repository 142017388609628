import { defineStore } from 'pinia'

import { loadAvailableCampaigns } from '@/services/DesignerService'
import { Campaign } from '@/types'
import { Pagination } from '@/types/common'

export const useStoreDesignerStore = defineStore('store_designer', {
  state: (): {
    availableCampaigns: Campaign[]
    availableCampaignsPagination: Pagination
    isLoadingAvailableCampaigns: Boolean
  } => ({
    availableCampaigns: [],
    availableCampaignsPagination: null,
    isLoadingAvailableCampaigns: false
  }),

  getters: {
    allAvailableCampaignsLoaded: (state) =>
      state.availableCampaignsPagination?.current_page &&
      state.availableCampaignsPagination.current_page === state.availableCampaignsPagination.total_pages,
    avialableCampaignVariantIds: (state) =>
      state.availableCampaigns
        .map((campaign: Campaign) => campaign.campaign_variants.map((variant) => variant.id))
        .flat()
  },

  actions: {
    async loadAvailableCampaigns() {
      if (this.allAvailableCampaignsLoaded) {
        return
      }

      this.isLoadingAvailableCampaigns = true
      const page = this.availableCampaignsPagination?.current_page
        ? this.availableCampaignsPagination?.current_page + 1
        : 1
      const paginatedAvailableCampaigns = await loadAvailableCampaigns(page)
      this.availableCampaignsPagination = paginatedAvailableCampaigns.pagination
      this.availableCampaigns = [...this.availableCampaigns, ...paginatedAvailableCampaigns.items]
      this.isLoadingAvailableCampaigns = false
    }
  }
})
