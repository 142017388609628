import * as VueGtag from 'vue-gtag'

import { config } from '@/config'
import { CampaignVariant, CartItem, Order } from '@/types'

const getProductDataFromCartItem = (item: CartItem): any => ({
  id: String(item.product_id),
  name: item.campaign_name,
  brand: `represent:${item.youtube_brand}`,
  style: item.style_name,
  variant: item.variant_name,
  price: item.price,
  quantity: item.quantity
})

const getProductDataFromCampaignVariant = (variant: CampaignVariant, quantity: number = 1): any => ({
  id: String(variant.product_id),
  name: variant.campaign_title,
  brand: `represent:${variant.youtube_brand}`,
  style: variant.style.name,
  variant: variant.variant.name,
  price: variant.price,
  quantity
})

const getGA4ProductDataFromCartItem = (item: CartItem) => ({
  item_brand: `represent:${item.youtube_brand || item.campaign_id}`,
  item_id: item.product_id,
  item_name: item.campaign_name,
  item_variant: item.variant?.name,
  price: item.price,
  quantity: item.quantity
})

const getGA4ProductDataFromCampaignVariant = (variant: CampaignVariant, quantity: number = 1) => ({
  item_brand: `represent:${variant.youtube_brand || variant.store?.slug}`,
  item_category: variant.category,
  item_id: variant.product_id,
  item_name: variant.campaign_title,
  item_variant: variant.variant?.name,
  price: variant.price,
  quantity: quantity
})
const addToDataLayer = (event: string, ecommerce: any) => {
  ;(window as any).dataLayer = (window as any).dataLayer || []
  ;(window as any).dataLayer.push({ event, ecommerce })
}

export default {
  // TODO: validation - send only data when valid (product_id && campaign_name present)
  trackProductView(product: CampaignVariant) {
    const data = getProductDataFromCampaignVariant(product)

    VueGtag.event('view_item', {
      currency: 'USD',
      value: product.price,
      items: [getGA4ProductDataFromCampaignVariant(product)]
    })
  },

  trackAddToCart(item: CartItem) {
    const data = getProductDataFromCartItem(item)

    VueGtag.event('add_to_cart', {
      currency: 'USD',
      items: [getGA4ProductDataFromCartItem(item)]
    })
  },

  trackRemoveFromCart(item: CartItem) {
    const data = getProductDataFromCartItem(item)

    VueGtag.event('remove_from_cart', {
      currency: 'USD',
      items: [getGA4ProductDataFromCartItem(item)]
    })
  },

  trackCheckout(items: CartItem[], { step = 1, option }: { step?: number; option?: string } = {}) {
    const data = items.map(getProductDataFromCartItem)

    const cartItems = items.map((item) => getGA4ProductDataFromCartItem(item))
    const cartValue = items.map((item) => item.price * item.quantity).reduce((a, b) => a + b, 0)
    const ga4CheckoutStepEvent = { 1: 'begin_checkout', 2: 'add_shipping_info', 3: 'add_payment_info' }

    if (ga4CheckoutStepEvent[step]) {
      VueGtag.event(ga4CheckoutStepEvent[step], {
        currency: 'USD',
        items: cartItems,
        value: cartValue,
        ...(option ? { payment_type: option } : {})
      })
    }
  },

  trackPurchase(order: Order) {
    const analyticsData = order.analytics_data

    analyticsData.forEach((campaignData) => {
      const items = analyticsData.flatMap((campaignData) =>
        campaignData.order_items.map((item, index) => ({
          coupon: item.coupon || undefined,
          index: index + 1,
          item_brand: `represent:${item.youtube_brand || campaignData.campaign_id}`,
          item_category: item.category,
          item_id: item.id,
          item_name: item.name,
          item_variant: item.variant,
          price: Number(item.price),
          quantity: Number(item.quantity)
        }))
      )

      VueGtag.event('purchase', {
        affiliation: campaignData.affiliation,
        coupon: campaignData.coupon || undefined,
        currency: 'USD',
        items,
        shipping: Number(order.payment?.quoted_shipping_price),
        tax: Number(order.payment?.sales_tax),
        transaction_id: order.id,
        value: Number(order.payment?.amount_paid)
      })
    })
  },

  trackYoutubeBrand(youtubeBrand: string) {
    if (!youtubeBrand) {
      const representYoutube = 'represent:' + youtubeBrand

      VueGtag.set({
        dimension1: representYoutube,
        brand: representYoutube
      })
    }
  },

  trackPageView(path: string) {
    VueGtag.pageview({ page_path: path })
  },

  // Google Optimize
  // This method activates the experiment through the custom event
  activateExperiment(id: string) {
    ;(window as any).dataLayer.push({
      'event': `optimize.activate.${id}`
    })
  },

  // This method returns `true` for users in the test group and `false` for users in the control group
  isExperimentActive(id: string): Promise<boolean> {
    return new Promise((resolve) => {
      function gtag(..._args: any[]) {
        ;(window as any).dataLayer = (window as any).dataLayer || []
        ;(window as any).dataLayer?.push(arguments)
      }

      gtag('event', 'optimize.callback', { name: id, callback: (value: string) => resolve(value === '1') })
    })
  },

  resetDataLayer() {
    ;(window as any).google_tag_manager?.[config.google_tag_manager_id]?.dataLayer.reset()
  }
}
