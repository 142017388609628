import axios from 'axios'

import { AppConfig, Continent, Country, CountryCode, IpBasedLocation, LocalEstimateData } from '@/types'

const isCustomDomain =
  import.meta.env.VITE_APP_ENV !== 'development' &&
  !window.location.host.includes('localhost') &&
  !window.location.host.includes('amplifyapp.com') &&
  !window.location.host.includes('represent.com') &&
  !window.location.host.includes('represent-staging.com') &&
  !window.location.host.includes('merch.cameo.com') &&
  !window.location.host.includes('merch-staging.cameo.com')
const customDomains = import.meta.env.VITE_APP_CUSTOM_DOMAINS ? JSON.parse(import.meta.env.VITE_APP_CUSTOM_DOMAINS) : []
const slug = customDomains[window.location.host]?.slug
const oneTrustId = import.meta.env.VITE_APP_ONE_TRUST_ID
  ? import.meta.env.VITE_APP_ONE_TRUST_ID === 'null'
    ? null
    : import.meta.env.VITE_APP_ONE_TRUST_ID
  : null

export let config: AppConfig = {
  env: import.meta.env.VITE_APP_ENV as AppConfig['env'],
  braintree_mode: import.meta.env.VITE_APP_BRAINTREE_MODE,
  stripe_publishable_key: import.meta.env.VITE_APP_STRIPE_PUBLISHABLE_KEY,
  klarna_enabled: import.meta.env.VITE_APP_KLARNA_ENABLED === 'true',
  klarna_client_id: import.meta.env.VITE_APP_KLARNA_CLIENT_ID,
  attentive_enabled: import.meta.env.VITE_APP_ATTENTIVE_ENABLED === 'true',
  avs_enabled: import.meta.env.VITE_APP_AVS_ENABLED === 'true',
  apple_pay_enabled: import.meta.env.VITE_APP_APPLE_PAY_ENBALED === 'true',
  paypal_enabled: import.meta.env.VITE_APP_PAYPAL_ENABLED === 'true',
  recommender_enabled: import.meta.env.VITE_APP_RECOMMENDER_ENABLED === 'true',
  upsell_enabled: import.meta.env.VITE_APP_UPSELL_ENABLED === 'true',
  google_analytics_4_id: import.meta.env.VITE_APP_GOOGLE_ANALYTICS_4_ID,
  google_tag_manager_id: import.meta.env.VITE_APP_GOOGLE_TAG_MANAGER_ID,
  represent_api_url: import.meta.env.VITE_APP_API_URL,
  represent_url: import.meta.env.VITE_APP_REPRESENT_URL,
  represent_app_url: import.meta.env.VITE_APP_REPRESENT_APP_URL,
  stores_url: import.meta.env.VITE_APP_STORES_URL,
  fb_pixel_id: import.meta.env.VITE_APP_FB_PIXEL_ID,
  recaptcha_site_key: import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY,
  sitewide_message_error: import.meta.env.VITE_APP_SITEWIDE_MESSAGE_ERROR,
  sitewide_message_warn: import.meta.env.VITE_APP_SITEWIDE_MESSAGE_WARN,
  sitewide_message_info: import.meta.env.VITE_APP_SITEWIDE_MESSAGE_INFO,
  ignore_ab_tests: import.meta.env.VITE_APP_IGNORE_AB_TESTS === 'true',
  api_url: import.meta.env.VITE_APP_API_URL,
  slug: slug,
  cameo_url: import.meta.env.VITE_APP_CAMEO_URL,
  custom_domain: isCustomDomain,
  custom_store: customDomains[window.location.host]?.custom_store == 'true' || false,
  support_email_general: 'support@represent.com',
  support_email_orders: 'orders@represent.com',
  support_email_sellers: 'campaigns@represent.com',
  support_email_legal: 'agent@cameo.com',
  support_email_finance: 'robbie@represent.com',
  paypal_client_id: import.meta.env.VITE_APP_PAYPAL_CLIENT_ID,
  intercom_app_id: import.meta.env.VITE_APP_INTERCOM_APP_ID,
  color_palette:
    isCustomDomain && import.meta.env[`VITE_APP_COLOR_PALETTE_${slug}`]
      ? JSON.parse(import.meta.env[`VITE_APP_COLOR_PALETTE_${slug}`])
      : undefined,
  one_trust_id: oneTrustId
}

export async function shippingCountries(): Promise<Country[]> {
  return await getConfigVariable('shipping-countries')
}

export async function countriesByContinent(): Promise<Continent[]> {
  return await getConfigVariable('countries-by-continent')
}

export async function countriesWithRequiredState(): Promise<CountryCode[]> {
  return await getConfigVariable('countries-with-required-state')
}

export async function countriesWithRequiredFiscalCode(): Promise<CountryCode[]> {
  return await getConfigVariable('countries-with-required-fiscal-code')
}

export async function countriesRequiringQuotesOnZip(): Promise<CountryCode[]> {
  return await getConfigVariable('countries-with-required-quote-on-zip-change')
}

export async function localEstimateData(): Promise<LocalEstimateData> {
  return await getConfigVariable('local-estimate-data')
}

export async function ipBasedLocation(): Promise<IpBasedLocation> {
  return await getConfigVariable('ip-based-location')
}

async function getConfigVariable(variable: string) {
  const underscoreVar = variable.replaceAll('-', '_')

  if (!config[underscoreVar]) {
    config[underscoreVar] = axios.get(`/fe-configuration/${variable}`).then((response) => response.data)
  }

  return config[underscoreVar]
}

axios.defaults.baseURL = config.api_url
