import { v4 as uuid } from 'uuid'

import FontPicker from '@/components/dashboard/store/forms/FontPicker.vue'
import MediaInput from '@/components/dashboard/store/forms/MediaInput.vue'
import ProductsPicker from '@/components/dashboard/store/forms/ProductsPicker.vue'
import SocialLinksInput from '@/components/dashboard/store/forms/SocialLinksInput.vue'
import WysiwygInput from '@/components/dashboard/store/forms/WysiwygInput.vue'
import RCheckbox from '@/components/ui/RCheckbox.vue'
import RColorInput from '@/components/ui/RColorInput.vue'
import RInput from '@/components/ui/RInput.vue'
import RNumberInput from '@/components/ui/RNumberInput.vue'
import RSelect from '@/components/ui/RSelect.vue'
import RTextarea from '@/components/ui/RTextarea.vue'
import UTCDateTimeInput from '@/components/UTCDateTimeInput.vue'
import { FormStructure } from '@/types/forms'
import {
  AnnouncementsBanner,
  BlockBlueprint,
  Blocks,
  Footer,
  Header,
  headerLayouts,
  Link,
  Page,
  Theme
} from '@/types/store'

const regularSellerBlueprintsBlacklist = ['html', 'store_password']

const commonColorsFormFields: FormStructure[number]['fields'] = {
  background_color: { component: RColorInput, props: { caption: 'Background color' } },
  text_color: { component: RColorInput, props: { caption: 'Text color' } }
}

const commonLayoutFormFields: FormStructure[number]['fields'] = {
  full_width: { component: RCheckbox, props: { caption: 'Full width' } }
}

const commonPaddingFormFields: FormStructure[number]['fields'] = {
  padding_vertical: { component: RCheckbox, props: { caption: 'Vertical padding' } },
  padding_horizontal: { component: RCheckbox, props: { caption: 'Horizontal padding' } }
}

export const generalFormStructure: FormStructure = [
  {
    title: 'General settings',
    fields: {
      title: { component: RInput, label: 'Title' },
      slug: { component: RInput, label: 'Slug' },
      description: { component: RTextarea, label: 'Description' },
      logo: {
        component: MediaInput,
        label: 'Logo',
        props: { preview: 'contain' },
        value: {
          getter: (data: any) => data.customization.urls?.logo_url,
          setter: (value: any, data: any) => ({
            ...data,
            customization: { ...data.customization, urls: { ...data.customization.urls, logo_url: value } }
          })
        }
      },
      preview_image: {
        component: MediaInput,
        label: 'Preview image',
        value: {
          getter: (data: any) => data.customization.urls?.image_url,
          setter: (value: any, data: any) => ({
            ...data,
            customization: { ...data.customization, urls: { ...data.customization.urls, image_url: value } }
          })
        }
      },
      favicon: {
        component: MediaInput,
        label: 'Favicon',
        value: {
          getter: (data: any) => data.customization.urls?.favicon_url,
          setter: (value: any, data: any) => ({
            ...data,
            customization: { ...data.customization, urls: { ...data.customization.urls, favicon_url: value } }
          })
        }
      },
      social_links: { component: SocialLinksInput, label: 'Social media' },
      status: {
        component: RCheckbox,
        label: 'Status',
        props: { caption: `Password protected` },
        value: {
          getter: (data: any) => data.status === 'password_protected',
          setter: (value: any, data: any) => ({
            ...data,
            status: value ? 'password_protected' : data.published === true ? 'active' : 'inactive'
          })
        },
        condition: (_data, user) => user.roles.includes('celebrity')
      },
      external_review_token: {
        component: RInput,
        label: 'Password',
        condition: (_data, user) => user.roles.includes('celebrity')
      }
    }
  }
]

export const themeFormStructure: FormStructure<Theme> = [
  {
    title: 'Theme',
    fields: {
      display_typeface: { component: FontPicker, label: 'Display Typeface' },
      body_typeface: { component: FontPicker, label: 'Body Typeface' }
    }
  }
]

export const pageFormStructure: FormStructure<Page> = [
  {
    title: 'Page',
    fields: {
      title: { component: RInput, label: 'Title' },
      slug: { component: RInput, label: 'Slug' },
      unlisted: { component: RCheckbox, label: 'Visibility', props: { caption: 'Hide from menu' } },
      hideHeader: { component: RCheckbox, label: 'Header', props: { caption: 'Hide header' } },
      hideFooter: { component: RCheckbox, label: 'Footer', props: { caption: 'Hide footer' } },
      background: { component: MediaInput, label: 'Background' }
    }
  }
]

export const linkFormStructure: FormStructure<Link> = [
  {
    title: 'Link',
    fields: {
      title: { component: RInput, label: 'Title' },
      url: { component: RInput, label: 'URL' }
    }
  }
]

export const headerFormStructure: FormStructure<Header> = [
  {
    title: 'Header',
    fields: {
      layout: { component: RSelect, props: { options: headerLayouts }, label: 'Layout' },
      static: { component: RCheckbox, props: { caption: 'Static' }, label: 'Behavior' },
      transparent: { component: RCheckbox, props: { caption: 'Transparent' } }
    }
  },
  {
    title: 'Colors',
    fields: {
      background_color: {
        component: RColorInput,
        label: 'Colors',
        props: { caption: 'Background color', default: '#ffffff' }
      },
      text_color: { component: RColorInput, props: { caption: 'Text color', default: '#000000' } }
    }
  },
  {
    title: 'Layout',
    fields: {
      ...commonLayoutFormFields
    }
  }
]

export const footerFormStructure: FormStructure<Footer> = [
  {
    title: 'Footer',
    fields: {
      ...commonColorsFormFields,
      ...commonLayoutFormFields
    }
  }
]

export const announcementsBannerFormStructure: FormStructure<AnnouncementsBanner> = [
  {
    fields: {
      text: { component: RTextarea, label: 'Text' },
      call_to_action_url: { component: RInput, label: 'Call to action URL' },
      call_to_action_text: { component: RInput, label: 'Call to action text' },
      animated: { component: RCheckbox, props: { caption: 'Animated' } },
      ...commonColorsFormFields,
      ...commonLayoutFormFields
    }
  }
]

export const heroBlockBlueprint: BlockBlueprint<Blocks.Hero> = {
  id: 'hero',
  name: 'Hero banner',
  icon: 'image',
  component: () => import('@/components/store/blocks/Hero.vue'),
  formDeviceFields: Blocks.heroDeviceFields,
  formStructure: [
    {
      title: 'Hero banner',
      fields: {
        images: {
          component: MediaInput,
          label: 'Images or Video',
          props: { accept: 'image/*,video/*', multiple: true }
        },
        height: { component: RSelect, label: 'Height', props: { options: Blocks.heroHeights } },
        open_in_new_tab: { component: RCheckbox, label: 'Open in new tab', props: { caption: 'Open link in new tab' } },
        link: { component: RInput, label: 'Link' }
      }
    },
    {
      title: 'Animation',
      fields: {
        transition: { component: RSelect, label: 'Transition', props: { options: Blocks.heroTransitions } },
        delay: {
          component: RInput,
          label: 'Transition delay in seconds',
          props: { type: 'number', min: 3 }
        }
      }
    },
    {
      title: 'Colors',
      fields: {
        ...commonColorsFormFields
      }
    }
  ],
  create: () => ({
    id: uuid(),
    blueprint_id: 'hero',
    background_color: '',
    text_color: '',
    delay: 3,
    height: 'image',
    images: [],
    transition: 'fade',
    padding_vertical: false,
    padding_horizontal: false,
    full_width: true,
    device: { mobile: {} }
  })
}

export const productsBlockBlueprint: BlockBlueprint<Blocks.Products> = {
  id: 'products',
  name: 'Products',
  icon: 'tee-outline',
  component: () => import('@/components/store/blocks/Products.vue'),
  formDeviceFields: Blocks.productsDeviceFields,
  formStructure: (options) => [
    {
      title: 'Products',
      fields: {
        layout: { component: RSelect, label: 'Layout', props: { options: Blocks.productsLayouts } },
        items_per_row: {
          component: RNumberInput,
          label: 'Items per row',
          props: { type: 'number', min: '1', max: '5', readonly: true, size: 'small' }
        },
        number_of_rows: {
          component: RNumberInput,
          label: 'Visible rows',
          props: { type: 'number', min: '1', readonly: true, size: 'small' },
          condition: (data) => data.layout === 'grid'
        },
        source: { component: RSelect, label: 'Source', props: { options: Blocks.productsSources } },
        collection_id: {
          component: RSelect,
          label: 'Collection',
          props: {
            options: options.collections || [],
            optionValueKey: 'id',
            optionLabelKey: 'name',
            placeholder: 'Select a collection'
          },
          condition: (data) => data.source === 'collection'
        },
        product_ids: {
          component: ProductsPicker,
          label: 'Products',
          props: {
            availableCampaigns: options.availableCampaigns
          },
          condition: (data) => data.source === 'manual'
        },
        text_alignment: {
          component: RSelect,
          label: 'Text alignment',
          props: { options: Blocks.productsTextAlignments }
        }
        // default_sort: {
        //   component: RSelect,
        //   label: 'Sort products',
        //   props: { options: Blocks.productsSort },
        //   condition: (data) => ['all', 'collection'].includes(data.source)
        // }
      }
    },
    {
      title: 'Features',
      fields: {
        show_filters: { component: RCheckbox, props: { caption: 'Show filters' } },
        show_variants: { component: RCheckbox, props: { caption: 'Show variants' } },
        show_back_preview: { component: RCheckbox, props: { caption: 'Show back preview (on hover)' } }
      }
    },
    {
      title: 'Colors',
      fields: {
        ...commonColorsFormFields,
        product_background_color: {
          component: RColorInput,
          props: { caption: 'Card background color' }
        }
      }
    },
    {
      title: 'Layout',
      fields: {
        ...commonLayoutFormFields,
        ...commonPaddingFormFields
      }
    }
  ],
  create: () => ({
    id: uuid(),
    blueprint_id: 'products',
    layout: 'grid',
    source: 'all',
    default_sort: 'manual_with_sequence',
    items_per_row: 4,
    number_of_rows: 2,
    product_background_color: '',
    show_back_preview: true,
    show_filters: false,
    show_variants: false,
    text_alignment: 'left',
    text_color: '',
    background_color: '',
    full_width: true,
    padding_vertical: true,
    padding_horizontal: true,
    device: { mobile: {} }
  })
}

export const textBlockBlueprint: BlockBlueprint<Blocks.Text> = {
  id: 'text',
  name: 'Text',
  icon: 'type',
  component: () => import('@/components/store/blocks/Text.vue'),
  formStructure: [
    {
      title: 'Text',
      fields: {
        content: { component: WysiwygInput },
        layout: { component: RSelect, label: 'Layout', props: { options: Blocks.textLayouts } }
      }
    },
    {
      title: 'Colors',
      fields: {
        ...commonColorsFormFields
      }
    },
    {
      title: 'Layout',
      fields: {
        ...commonLayoutFormFields,
        ...commonPaddingFormFields
      }
    }
  ],
  create: () => ({
    id: uuid(),
    blueprint_id: 'text',
    content: '',
    layout: 'center',
    text_color: '',
    background_color: '',
    full_width: true,
    padding_vertical: true,
    padding_horizontal: true,
    device: { mobile: {} }
  })
}

export const imageBlockBlueprint: BlockBlueprint<Blocks.Image> = {
  id: 'image',
  name: 'Image',
  icon: 'image',
  component: () => import('@/components/store/blocks/Image.vue'),
  formDeviceFields: Blocks.imageDeviceFields,
  formStructure: [
    {
      title: 'Image',
      fields: {
        images: { component: MediaInput, props: { multiple: true, withLink: true } },
        layout: { component: RSelect, label: 'Layout', props: { options: Blocks.imageLayouts } },
        items_per_row: {
          component: RNumberInput,
          label: 'Items per row',
          props: { type: 'number', min: '1', max: '6', readonly: true, size: 'small' },
          condition: (data) => ['default', 'carousel'].includes(data.layout)
        }
      }
    },
    {
      title: 'Colors',
      fields: {
        ...commonColorsFormFields
      }
    },
    {
      title: 'Layout',
      fields: {
        spacing: { component: RSelect, label: 'Spacing', props: { options: Blocks.spacingOptions } },
        ...commonLayoutFormFields,
        ...commonPaddingFormFields
      }
    }
  ],
  create: () => ({
    id: uuid(),
    layout: 'default',
    items_per_row: 4,
    blueprint_id: 'image',
    images: [],
    padding_vertical: false,
    padding_horizontal: false,
    spacing: 'none',
    text_color: '',
    background_color: '',
    full_width: true,
    device: { mobile: {} }
  })
}

export const imageWithTextBlockBlueprint: BlockBlueprint<Blocks.ImageWithText> = {
  id: 'image_with_text',
  name: 'Image with text',
  icon: 'image',
  component: () => import('@/components/store/blocks/ImageWithText.vue'),
  formDeviceFields: Blocks.imageWithTextDeviceFields,
  formStructure: [
    {
      title: 'Image with text',
      fields: {
        images: { component: MediaInput, label: 'Image' },
        content: { component: WysiwygInput, label: 'Text Content' },
        layout: { component: RSelect, label: 'Layout', props: { options: Blocks.imageWithTextLayouts } }
      }
    },
    {
      title: 'Call to action',
      fields: {
        call_to_action_text: { component: RInput, label: 'Call to action text' },
        call_to_action_url: { component: RInput, label: 'Call to action url' }
      }
    },
    {
      title: 'Colors',
      fields: {
        ...commonColorsFormFields
      }
    },
    {
      title: 'Layout',
      fields: {
        ...commonLayoutFormFields,
        ...commonPaddingFormFields
      }
    }
  ],
  create: () => ({
    id: uuid(),
    blueprint_id: 'image_with_text',
    call_to_action_text: '',
    call_to_action_url: '',
    images: [],
    content: '',
    layout: 'centered_left',
    text_color: '',
    background_color: '',
    padding_vertical: true,
    padding_horizontal: false,
    spacing: 'medium',
    full_width: true,
    device: { mobile: {} }
  })
}

export const embedBlockBlueprint: BlockBlueprint<Blocks.Embed> = {
  id: 'embed',
  name: 'Embed',
  icon: 'play',
  component: () => import('@/components/store/blocks/Embed.vue'),
  formStructure: [
    {
      title: 'Embed',
      fields: {
        content: { component: RTextarea }
      }
    },
    {
      title: 'Colors',
      fields: {
        ...commonColorsFormFields
      }
    },
    {
      title: 'Layout',
      fields: {
        ...commonLayoutFormFields,
        ...commonPaddingFormFields
      }
    }
  ],
  create: () => ({
    id: uuid(),
    blueprint_id: 'embed',
    content: '',
    text_color: '',
    background_color: '',
    padding_vertical: true,
    padding_horizontal: false,
    full_width: true
  })
}

export const horizontalSpaceBlockBlueprint: BlockBlueprint<Blocks.HorizontalSpace> = {
  id: 'horizontal_space',
  name: 'Horizontal space',
  icon: 'minus',
  component: () => import('@/components/store/blocks/HorizontalSpace.vue'),
  formStructure: [
    {
      title: 'Horizontal space',
      fields: {
        layout: { component: RSelect, label: 'Size', props: { options: Blocks.horizontalSpaceLayout } }
      }
    },
    {
      title: 'Colors',
      fields: {
        ...commonColorsFormFields
      }
    }
  ],
  create: () => ({
    id: uuid(),
    blueprint_id: 'horizontal_space',
    full_width: true,
    layout: 'small'
  })
}

export const freeHtmlBlockBlueprint: BlockBlueprint<Blocks.Html> = {
  id: 'html',
  name: 'HTML',
  icon: 'terminal',
  component: () => import('@/components/store/blocks/Html.vue'),
  formStructure: [
    {
      title: 'HTML',
      fields: {
        content: { component: RTextarea }
      }
    },
    {
      title: 'Colors',
      fields: {
        ...commonColorsFormFields
      }
    },
    {
      title: 'Layout',
      fields: {
        ...commonLayoutFormFields,
        ...commonPaddingFormFields
      }
    }
  ],
  create: () => ({
    id: uuid(),
    blueprint_id: 'html',
    content: '',
    full_width: true,
    padding_vertical: false,
    padding_horizontal: false
  })
}

export const countdownTimerBlockBlueprint: BlockBlueprint<Blocks.CountdownTimer> = {
  id: 'countdown_timer',
  name: 'Countdown Timer',
  icon: 'clock',
  component: () => import('@/components/store/blocks/CountdownTimer.vue'),
  formStructure: [
    {
      title: 'Countdown Timer',
      fields: {
        date: { component: UTCDateTimeInput, label: 'Enter Date & Time (UTC)' }
      }
    },
    {
      title: 'Colors',
      fields: {
        ...commonColorsFormFields
      }
    },
    {
      title: 'Layout',
      fields: {
        ...commonLayoutFormFields,
        ...commonPaddingFormFields
      }
    }
  ],
  create: () => ({
    id: uuid(),
    blueprint_id: 'countdown_timer',
    date: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
    full_width: true,
    padding_vertical: true,
    padding_horizontal: true
  })
}

export const newsletterSignupBlockBlueprint: BlockBlueprint<Blocks.NewsletterSignup> = {
  id: 'newsletter_signup',
  name: 'Newsletter sign-up',
  icon: 'mail',
  component: () => import('@/components/store/blocks/NewsletterSignup.vue'),
  formStructure: [
    {
      title: 'Colors',
      fields: {
        ...commonColorsFormFields
      }
    },
    {
      title: 'Layout',
      fields: {
        ...commonLayoutFormFields,
        ...commonPaddingFormFields
      }
    }
  ],
  create: () => ({
    id: uuid(),
    blueprint_id: 'newsletter_signup',
    full_width: true,
    padding_vertical: true,
    padding_horizontal: true
  })
}

export const storePasswordBlockBlueprint: BlockBlueprint<Blocks.StorePassword> = {
  id: 'store_password',
  name: 'Store password form',
  icon: 'key',
  component: () => import('@/components/store/blocks/StorePassword.vue'),
  formStructure: [
    {
      title: 'Colors',
      fields: {
        ...commonColorsFormFields
      }
    },
    {
      title: 'Layout',
      fields: {
        ...commonLayoutFormFields,
        ...commonPaddingFormFields
      }
    }
  ],
  create: () => ({
    id: uuid(),
    blueprint_id: 'store_password',
    full_width: true,
    padding_vertical: true,
    padding_horizontal: true
  })
}

export const blockBlueprints: BlockBlueprint[] = [
  heroBlockBlueprint,
  productsBlockBlueprint,
  imageBlockBlueprint,
  textBlockBlueprint,
  imageWithTextBlockBlueprint,
  horizontalSpaceBlockBlueprint,
  embedBlockBlueprint,
  newsletterSignupBlockBlueprint,
  freeHtmlBlockBlueprint,
  countdownTimerBlockBlueprint,
  storePasswordBlockBlueprint
]

export const regularSellerBlueprints: BlockBlueprint[] = blockBlueprints.filter(
  (blueprint) => !regularSellerBlueprintsBlacklist.includes(blueprint.id)
)

export const createLink = (order: number): Link => ({
  id: uuid(),
  type: 'link',
  order,
  title: '',
  url: ''
})

export const createPage = (order: number): Page => ({
  id: uuid(),
  type: 'page',
  order,
  title: '',
  slug: '',
  blocks: []
})
