<template>
  <select
    class="r-input"
    v-bind="$attrs"
    :class="{ 'r-input--small': size === 'small' }"
    :value="value"
    v-on="listeners"
  >
    <option
      v-if="placeholder"
      :disabled="!allowEmpty"
      :selected="value === undefined || value === ''"
      :value="undefined"
    >
      {{ placeholder }}
    </option>

    <option
      v-for="option in normalizedOptions"
      :key="option.value"
      :disabled="option.disabled"
      :selected="value !== undefined && option.value == value"
      :value="option.value"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { transformInputListeners } from '@/components/mixins/transformInputListeners'
import { Extractor, normalizeOptions, Option } from '@/utils/normalizeOption'

const sizes = ['normal', 'small'] as const

type Size = typeof sizes[number]

export default Vue.extend({
  mixins: [transformInputListeners],

  props: {
    value: {
      type: [String, Number, Object],
      default: ''
    },

    options: {
      type: [Array, Object],
      required: true
    },

    optionLabelKey: {
      type: [Function, String, Number] as PropType<Extractor>,
      default: undefined
    },

    optionValueKey: {
      type: [Function, String, Number] as PropType<Extractor>,
      default: undefined
    },

    optionDisabledKey: {
      type: [Function, String, Number] as PropType<Extractor>,
      default: undefined
    },

    placeholder: {
      type: String,
      default: ''
    },

    size: {
      type: String as PropType<Size>,
      default: 'normal',
      validator: (value: Size) => sizes.includes(value)
    },

    allowEmpty: Boolean
  },

  computed: {
    normalizedOptions(): Option[] {
      return normalizeOptions(this.options, {
        value: this.optionValueKey,
        label: this.optionLabelKey,
        disabled: this.optionDisabledKey
      })
    }
  }
})
</script>
