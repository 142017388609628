<template>
  <modal-layout title="Image Link">
    <RInput
      ref="inputElement"
      v-model="link"
      placeholder="https://domain.com"
      prefix-icon="link"
      @keydown.enter="submit()"
    />

    <template #footer>
      <div class="flex items-center gap-2">
        <button v-if="link" class="link link--danger text-sm" tabindex="0" @click="close()">Remove link</button>

        <RButton class="ml-auto" label="Cancel" variant="secondary" @click="cancel()" />
        <RButton label="Use link" variant="primary" @click="submit()" />
      </div>
    </template>
  </modal-layout>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'

import RButton from '@/components/ui/RButton.vue'
import RInput from '@/components/ui/RInput.vue'

const props = defineProps<{
  link?: string
}>()

const emit = defineEmits<{
  (event: 'close', link?: string): void
}>()

const inputElement = ref()

const link = ref(props.link)

onMounted(() => {
  inputElement.value.input.focus()
  inputElement.value.input.select()
})

const close = (result?: string) => emit('close', result)

const cancel = () => close(props.link)

const submit = () => close(link.value)
</script>
