<template>
  <div
    class="_background h-full w-full"
    :style="`
      --background-image: url(${backgroundImage});
      --background-color: ${landingPageData.background_color};
      color: ${landingPageData.text_color || '#ffffff'};
    `"
  >
    <!-- LOGO -->
    <img v-if="landingPageData.logo" alt="logo" class="absolute mt-4 ml-4" :src="landingPageData.logo" />

    <div class="_center w-full px-4 text-center md:mx-auto md:w-1/3">
      <div class="_background-filter rounded p-4 md:p-10">
        <!-- TITLE -->
        <div class="text-5xl font-bold tracking-wider">{{ landingPageData.title }}</div>

        <!-- SUBTITLE -->
        <div class="text-lg font-semibold">{{ landingPageData.subtitle }}</div>

        <!-- EMAIL INPUT -->
        <form
          v-if="landingPageData.email_subscription"
          class="relative mt-12 flex items-stretch rounded border border-gray-300 bg-white"
          @submit.prevent="submit()"
        >
          <input
            v-model="email"
            autocomplete="email"
            class="flex-auto border-none bg-transparent px-4 text-black outline-none"
            :disabled="hasSubscribed"
            placeholder="email@example.com"
            required
            type="email"
          />

          <button
            class="_button button m-1.5 flex-none rounded px-4 md:px-8"
            :class="
              landingPageData.email_button_theme === 'light'
                ? 'bg-gray-100 text-black hover:gray-200 border border-black'
                : 'bg-black text-white hover:gray-700 border border-white'
            "
            :disabled="hasSubscribed || isSubmitting"
            type="submit"
          >
            Subscribe
          </button>

          <div
            v-if="hasSubscribed"
            class="absolute inset-0 flex items-center justify-center rounded border-2 border-green-500 bg-white font-bold text-green-500"
          >
            <RIcon class="mr-3 w-8" name="check" />
            You've been subscribed!
          </div>
        </form>
      </div>

      <Timer
        v-if="landingPageData.timer"
        class="mt-6 mb-4 w-full"
        :end-time="landingPageData.timer"
        :with-background="true"
      />

      <div
        class="_button my-5 mx-auto w-1/2 rounded md:w-1/3"
        :class="
          landingPageData.share_button_theme === 'light'
            ? 'bg-gray-100 text-black hover:gray-200 border border-black'
            : 'bg-black text-white hover:gray-700 border border-white'
        "
        :style="`background-color: ${landingPageData.button_color || '#000000'}`"
      >
        <ShareButton v-if="store" :campaign-name="store.slug" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import ShareButton from '@/components/product/ShareButton.vue'
import Timer from '@/components/Timer.vue'
import RIcon from '@/components/ui/RIcon.vue'
import EmailService from '@/services/EmailService'
import { Store } from '@/types/store'

export default Vue.extend({
  components: {
    RIcon,
    Timer,
    ShareButton
  },

  props: {
    store: {
      type: Object as PropType<Store>,
      default: null
    }
  },

  data() {
    return {
      email: '',
      errors: {},
      isSubmitting: false,
      isSent: false,
      hasSubscribed: false
    }
  },

  computed: {
    landingPageData() {
      return this.store?.customization?.landing_page || {}
    },

    backgroundImage() {
      return this.$breakpoints.isMobile
        ? this.landingPageData.background_image?.mobile
        : this.landingPageData.background_image?.desktop
    }
  },

  methods: {
    async submit() {
      this.isSubmitting = true

      try {
        await EmailService.subscribe(this.email, this.store.id, '')
      } catch (err) {
        this.$root.$emit('alert', 'error', 'Your email address is invalid!')
        this.$nextTick(() => window.scrollTo(0, 0))
        return
      }

      this.isSubmitting = false
      this.hasSubscribed = true
    }
  }
})
</script>

<style lang="postcss" scoped>
._center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

._background-filter {
  background: rgba(0, 0, 0, 0.4);
}

._background::before {
  content: '';
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: var(--background-image);
  background-color: var(--background-color);

  @apply absolute left-0 top-0 w-full h-full;
}

._button:hover {
  filter: brightness(70%);
}
</style>
