import map from 'lodash/map'

import { apiAuthClient } from '@/services/ApiService'
import { Campaign, Collection } from '@/types'
import { Medium, Paginated } from '@/types/common'
import { Designer } from '@/types/designer'
import { Store } from '@/types/store'
import { readFileAsDataURL } from '@/utils/readFileAsDataURL'

export default {
  async styles() {
    return (await apiAuthClient.get('/designer/styles')).data
  },

  async stylePricings() {
    return (await apiAuthClient.get('/designer/style-pricings')).data
  },

  async settings() {
    return (await apiAuthClient.get('/designer/settings')).data
  },

  async checkCampaignSlug(slug) {
    const params = {
      slug
    }

    return (await apiAuthClient.get('/designer/slug-availability', { params })).data
  },

  async uploadDesign(formData) {
    const headers = {
      'Content-Type': 'multipart/form-data'
    }

    return (await apiAuthClient.post('/designer/designs', formData, { headers })).data
  },

  async createCampaign(campaign, printableAreaGroups, designs) {
    campaign = {
      ...campaign,
      png: {
        front: designs.front?.url,
        back: designs.back?.url
      },
      printable_area_groups: map(printableAreaGroups, (data, _) => {
        return {
          styles: data.styles,
          svg: { front: data.front.svg, back: data.back?.svg },
          settings: data.settings
        }
      })
    }

    return (await apiAuthClient.post('/designer/create-campaign', { campaign })).data
  }
}

export async function loadStore(): Promise<Store> {
  return (await apiAuthClient.get('/designer/store')).data
}

export async function updateStore(store: Partial<Store>) {
  await apiAuthClient.post('/designer/store', { store })
}

export async function loadAvailableCampaigns(page?: number): Promise<Paginated<Campaign>> {
  return (await apiAuthClient.get('/designer/store/available-campaigns', { params: { page: page } })).data
}

export async function loadAvailableCollections(): Promise<Collection[]> {
  return (await apiAuthClient.get('/designer/store/available-collections')).data
}

export async function readMediumAsDataUrl(file: File): Promise<Medium> {
  const url = await readFileAsDataURL(file)

  return {
    url,
    type: file.type,
    preview_url: url
  }
}

export async function uploadMedium(file: File): Promise<Medium> {
  // Read file as data url instead of an actual upload in development mode
  if (import.meta.env.DEV) {
    return await readMediumAsDataUrl(file)
  }

  const formData = new FormData()

  formData.append('file', file)

  const response = await apiAuthClient.post('/designer/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response.data
}

export function slugify(text: string): string {
  return text
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-+|-+$/g, '')
}

export function getDefaultProductSettings(overrides: Partial<Designer.ProductSettings> = {}): Designer.ProductSettings {
  return {
    short_description:
      'Printed on-demand to prevent excess waste\n' +
      'International shipping available\n' +
      'Easy returns and exchanges',
    is_hidden: true,
    hide_from_listings: true,
    is_dormant: true,
    timer_appearance: 'hide',
    goal_settings: 'disabled',
    asian_shipping: false,
    launch_to_external_review: true,
    rolling_fulfilment_period: 24,
    ...overrides
  }
}
