export const REPRESENT_PALETTE = {
  base: {
    background: {
      primary: '#ffffff',
      secondary: '#FAFAFA',
      tertiary: '#f1f5f8'
    },

    color: {
      lightest: '#f8fafc',
      lighter: '#f1f5f8',
      light: '#dae1e7',
      base: '#c8cacc',
      dark: '#606060',
      darker: '#4b4b4b',
      darkest: '#333333',
      error: '#dc2626',
      link: '#3490dc'
    },

    border: {
      lightest: '#f8fafc',
      lighter: '#f1f5f8',
      light: '#dae1e7',
      base: '#b8c2cc',
      dark: '#8795a1',
      darker: '#606f7b',
      darkest: '#3d4852'
    },

    borderRadius: {
      base: '0.15rem',
      small: '0.125rem',
      large: '0.125rem'
    },

    text: {
      default: '#333333',
      muted: '#666666'
    },

    form: {
      input: {
        inactive: '#b3b7ba',
        background: '#ffffff',
        border: '#dae1e7',
        placeholder: '#A0AEBF'
      },

      control: {
        inactive: '#eeeeee',
        background: '#000000',
        border: '#000000'
      }
    },

    button: {
      primary: {
        background: '#000000',
        border: '#000000',
        borderWidth: '1px',
        text: '#ffffff',
        hoverBackground: '#000000',
        hoverText: '#ffffff'
      },

      secondary: {
        background: 'transparent',
        border: '#000000',
        borderWidth: '1px',
        text: '#000000',
        hoverBackground: '#000000',
        hoverText: '#ffffff'
      }
    }
  }
}

export const CAMEO_PALETTE = {
  base: {
    background: {
      primary: '#141114',
      secondary: '#1E1B1E',
      tertiary: '#2B262B'
    },

    color: {
      lightest: '#413D40',
      lighter: '#666666',
      light: '#888888',
      base: '#9ca3af',
      dark: '#fff',
      darker: '#fff',
      darkest: '#ffffff',
      error: '#dc2626',
      link: '#21CFE6'
    },

    border: {
      lightest: '#413D40',
      lighter: '#413D40',
      light: '#413D40',
      base: '#413D40',
      dark: '#BEBBBF',
      darker: '#BEBBBF',
      darkest: '#BEBBBF'
    },

    borderRadius: {
      base: '0.5rem',
      small: '0.375rem',
      large: '0.5rem'
    },

    text: {
      default: '#FFFFFF',
      muted: '#BEBBBF',
      extraMuted: '#413D40'
    },

    form: {
      input: {
        inactive: '#b3b7ba',
        background: 'transparent',
        border: '#3e3e3e',
        placeholder: '#707070'
      },

      control: {
        inactive: '#5a5a5a',
        background: '#FF037C',
        border: '#FF037C'
      }
    },

    button: {
      primary: {
        background: '#FF037C',
        border: '#FF037C',
        borderWidth: '1px',
        text: '#ffffff',
        hoverBackground: '#FF037C',
        hoverText: '#ffffff'
      },

      secondary: {
        background: 'transparent',
        border: 'rgba(255, 255, 255, 0.3)',
        borderWidth: '2px',
        text: '#ffffff',
        hoverBackground: '#ffffff',
        hoverText: '#000000'
      }
    }
  },
  slidebarLayout: {
    background: {
      primary: '#2B262B'
    }
  }
}
