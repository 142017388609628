import { Discount } from '@/types'
import { formatCurrency } from '@/utils/formatCurrency'

export function formatDiscount(discount: Discount): string {
  return discount
    ? discount.type === 'relative'
      ? `${Number(discount.amount).toFixed(0)}%`
      : formatCurrency(discount.amount)
    : ''
}
