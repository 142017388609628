import { NavigationGuard, RouteConfig } from 'vue-router'

import { config } from '@/config'
import { resolveCurrentUser, signOut } from '@/services/dashboard/AuthenticationService'

const checkCurrentUser: NavigationGuard = async (_to, _from, next) => {
  if (await resolveCurrentUser()) {
    window.location.replace(`${config.represent_url}/dashboard`)
    return
  }

  next()
}

export const commonStaticPagesRoutes: RouteConfig[] = [
  {
    path: '/charge',
    component: () => import('@/views/base/PageWrapper.vue'),
    children: [{ path: '', component: () => import('@/views/static_pages/TrackOrder.vue'), name: 'trackOrder' }]
  },
  {
    path: '/unsupported-countries',
    component: () => import('@/views/base/PageWrapper.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/static_pages/UnsupportedCountries.vue'),
        name: 'unsupportedCountries'
      }
    ]
  },
  {
    path: '/subscribed',
    component: () => import('@/views/base/PageWrapper.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/static_pages/Subscribed.vue'),
        name: 'subscribed',
        props: (route) => ({
          fingerprint: route.query.fingerprint,
          email: route.query.email
        })
      }
    ]
  },
  {
    path: '/subscription-settings',
    component: () => import('@/views/base/PageWrapper.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/static_pages/SubscriptionSettings.vue'),
        name: 'subscriptionSettings',
        props: (route) => ({
          fingerprint: route.query.fingerprint,
          email: route.query.email
        })
      }
    ]
  },
  {
    path: '/ccpa',
    beforeEnter(_to, from, next) {
      window.location.replace('https://legal.cameo.com/privacypolicy#notice-ca-va-other')
    }
  },
  {
    path: '/dmca',
    component: () => import('@/views/base/PageWrapper.vue'),
    children: [{ path: '', component: () => import('@/views/static_pages/DMCA.vue'), name: 'dmca' }]
  },
  {
    path: '/accessibility',
    beforeEnter(_to, _from, _next) {
      window.location.replace('https://www.cameo.com/accessibility')
    }
  },
  {
    path: '/interest-based-ads',
    beforeEnter(_to, _from, _next) {
      window.location.replace('https://legal.cameo.com/privacypolicy')
    }
  },
  {
    path: '/privacy-policy',
    beforeEnter(_to, _from, _next) {
      window.location.replace('https://legal.cameo.com/privacypolicy')
    }
  },
  {
    path: '/faq',
    component: () => import('@/views/base/PageWrapper.vue'),
    props: { fullWidth: true },
    children: [
      {
        path: '',
        component: () => import('@/views/static_pages/FAQ.vue'),
        name: 'faq'
      }
    ]
  },
  {
    path: '/terms-and-conditions/',
    component: () => import('@/views/base/PageWrapper.vue'),
    children: [
      {
        path: '',
        alias: ['purchaser', 'seller'],
        component: () => import('@/views/static_pages/TermsAndConditions.vue'),
        name: 'termsAndConditions'
      },
      {
        path: '19-11-2020',
        component: () => import('@/views/static_pages/terms_and_conditions/TermsAndConditions_19_11_2020.vue'),
        name: 'termsAndConditions_19_11_2020'
      },
      {
        path: 'purchaser-19-11-2020',
        component: () => import('@/views/static_pages/terms_and_conditions/PurchaserToC_19_11_2020.vue'),
        name: 'purchaserTerms_19_11_2020'
      }
    ]
  },
  {
    path: '/status',
    children: [{ path: '', component: { template: 'OK' }, name: 'status' }]
  }
]

export const representStaticPagesRoutes = [
  {
    name: 'Home',
    path: '/',
    component: () => import('@/views/Homepage.vue')
  },
  {
    name: 'WorkWithUs',
    path: '/work-with-us',
    component: () => import('@/views/WorkWithUs.vue')
  },
  {
    path: '/features',
    component: () => import('@/views/base/PageWrapper.vue'),
    props: { fullWidth: true },
    children: [
      {
        path: '',
        component: () => import('@/views/static_pages/Features.vue'),
        name: 'features'
      }
    ]
  },
  {
    path: '/directory',
    component: () => import('@/views/base/PageWrapper.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/static_pages/Directory.vue'),
        name: 'directory'
      }
    ]
  },
  {
    path: '/contact',
    component: () => import('@/views/base/PageWrapper.vue'),
    props: { fullWidth: true },
    children: [
      {
        path: '',
        component: () => import('@/views/static_pages/FAQ.vue'),
        name: 'contact'
      }
    ]
  },
  {
    path: '/account-status',
    component: () => import('@/views/base/PageWrapper.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/static_pages/AccountStatus.vue'),
        name: 'accountStatus'
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/views/base/LoginWrapper.vue'),
    children: [
      {
        path: '/sign-in',
        component: () => import('@/views/static_pages/login/SignIn.vue'),
        name: 'signIn',
        props: true,
        beforeEnter: checkCurrentUser
      },
      {
        path: '/sign-up',
        component: () => import('@/views/static_pages/login/SignUp.vue'),
        name: 'signUp',
        beforeEnter: checkCurrentUser
      },
      {
        path: '/sign-out',
        name: 'signOut',
        async beforeEnter(_to, _from, next: any) {
          await signOut()
          next({
            name: 'signIn',
            params: { alert: { type: 'info', message: 'Signed out successfully' } }
          })
        }
      },
      {
        path: '/reset-password',
        component: () => import('@/views/static_pages/login/ResetPassword.vue'),
        name: 'resetPassword',
        beforeEnter: checkCurrentUser
      },
      {
        path: '/change-password',
        component: () => import('@/views/static_pages/login/ChangePassword.vue'),
        name: 'changePassword'
      },
      {
        path: '/not-authorized',
        component: () => import('@/views/base/NotAuthorized.vue'),
        name: 'notAuthorized',
        props: true
      }
    ]
  },
  {
    path: '/2022-style-offering',
    component: () => import('@/views/base/PageWrapper.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/static_pages/2022StyleOffering.vue'),
        name: 'stylePricing'
      }
    ]
  }
]
