import Vue from 'vue'
import Vuex from 'vuex'

import { config } from '@/config'
import cart, { State as CartState } from '@/store/cartStore'
import home, { State as HomeState } from '@/store/home'
import main, { State as MainState } from '@/store/mainStore'
import visit, { State as VisitState } from '@/store/visit'

export type RootState = {
  cart: CartState
  main: MainState
  visit: VisitState
  home: HomeState
}

Vue.use(Vuex)

const store = new Vuex.Store<RootState>({
  // "Do not enable strict mode when deploying for production!"
  // https://vuex.vuejs.org/en/strict.html
  strict: config.env !== 'production',

  actions: {
    init() {
      store.dispatch('cart/init')
    }
  },

  modules: {
    cart,
    main,
    visit,
    home
  }
})

export default store
