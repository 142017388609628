<template>
  <div>
    <input ref="input" :accept="accept" class="hidden" :multiple="multiple" type="file" @input="handleFilePickEvent" />

    <div class="r-input space-y-2 p-2">
      <component
        :is="mediaListComponent"
        v-if="hasSomeMedia"
        class="space-y-2"
        :value="value"
        @input="handleMediaReorder($event)"
      >
        <div
          v-for="(medium, index) in value"
          :key="index"
          class="group relative flex h-28 self-stretch overflow-hidden rounded bg-gray-100"
          :class="{ 'cursor-grab': hasMoreThatOnMedium }"
        >
          <button
            v-if="!disabled"
            class="invisible absolute top-0 right-0 z-1 m-4 cursor-pointer gap-2 rounded-full bg-white/50 p-1 leading-none hover:bg-white group-hover:visible"
            @click="removeMedium(medium)"
          >
            <RIcon class="w-5" name="trash" />
          </button>

          <button
            v-if="withLink && !disabled"
            class="absolute right-0 bottom-0 z-1 m-4 cursor-pointer gap-2 rounded-full p-1 leading-none hover:bg-white group-hover:visible"
            :class="[medium.link ? 'visible bg-white' : 'bg-white/50 invisible']"
            @click="editLink(index)"
          >
            <RIcon class="w-5" name="link" />
          </button>

          <div v-if="hasMoreThatOnMedium" class="absolute inset-y-0 left-0 flex w-8 cursor-grab" drag-handle>
            <RIcon class="m-auto w-5 drop-shadow-[1px_1px_0_rgb(255_255_255/0.85)]" name="move-handle" />
          </div>

          <img
            v-if="medium.type.startsWith('image')"
            class="pointer-events-none h-full w-full"
            :class="{
              'object-cover': preview === 'cover',
              'object-contain': preview === 'contain'
            }"
            :src="medium.url"
          />

          <div v-else-if="medium.type.startsWith('video')">
            <video autoplay loop muted :src="medium.url" />
          </div>

          <div v-else class="flex h-full w-full bg-gray-200">
            <RIcon class="m-auto w-8" name="document" />
          </div>
        </div>
      </component>

      <button
        v-if="canAddMoreFiles"
        class="flex h-28 w-full flex-auto cursor-pointer self-stretch rounded border-2 border-dashed"
        :class="{ 'border-indigo-500': isDropzoneActive, 'pointer-events-none': disabled }"
        :disabled="disabled"
        @click="$refs.input.click()"
        @dragenter.prevent
        @dragleave.prevent="isDropzoneActive = false"
        @dragover.prevent="
          isDropzoneActive = true
          $event.dataTransfer.dropEffect = 'copy'
        "
        @drop.prevent="handleFileDropEvent($event)"
      >
        <div class="m-auto text-center text-gray-500">
          <RIcon :name="isUploading ? 'spinner' : 'upload'" />

          <div class="mt-2 text-sm">
            <div>Drag & drop or</div>
            <span class="link inline-block outline-none" @click.prevent>
              click to select file{{ multiple ? 's' : '' }}
            </span>
          </div>
        </div>
      </button>

      <div v-if="errors.length" class="mt-2 text-sm text-error">
        <div v-for="(error, index) in errors" :key="index">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { Component, PropType } from 'vue'
import Draggable from 'vuedraggable'

import EditLinkModal from '@/components/dashboard/store/modals/EditLinkModal.vue'
import RIcon from '@/components/ui/RIcon.vue'
import { uploadMedium } from '@/services/DesignerService'
import { Medium } from '@/types/common'
import { updateIndexInArray } from '@/utils/array'

const validateType = (file: File, accept: string): boolean => {
  return accept
    .split(',')
    .map((type) => type.trim())
    .some((acceptedType) => {
      if (acceptedType === file.type) {
        return true
      }

      if (acceptedType.endsWith('/*')) {
        return acceptedType.split('/').shift() === file.type.split('/').shift()
      }

      return false
    })
}

export default Vue.extend({
  components: {
    RIcon
  },

  props: {
    value: {
      type: Array as PropType<Medium[]>,
      default: () => []
    },

    accept: {
      type: String,
      default: 'image/*'
    },

    preview: {
      type: String as PropType<'cover' | 'contain'>,
      default: 'cover'
    },

    multiple: Boolean,

    withLink: Boolean,

    disabled: Boolean
  },

  data() {
    return {
      isDropzoneActive: false,
      isUploading: false,
      errors: []
    }
  },

  computed: {
    hasSomeMedia(): boolean {
      return this.value?.length > 0
    },

    canAddMoreFiles(): boolean {
      return this.multiple || !this.hasSomeMedia
    },

    hasMoreThatOnMedium(): boolean {
      return this.value?.length > 1
    },

    mediaListComponent(): string | Component {
      return this.hasMoreThatOnMedium && !this.disabled ? Draggable : 'div'
    }
  },

  methods: {
    handleFilePickEvent(e: Event) {
      const files = Array.from((e.target as any).files)
      this.handleFiles(files)
      ;(e.target as any).value = ''
    },

    handleFileDropEvent(e: DragEvent) {
      this.isDropzoneActive = false
      const files = Array.from(e.dataTransfer.files).filter((file) => validateType(file, this.accept))
      this.handleFiles(files)
    },

    async handleFiles(files: File[]) {
      if (!files.length) {
        return
      }

      if (!this.multiple) {
        files = files.slice(0, 1)
      }

      this.errors = []
      this.isUploading = true

      const results = await Promise.allSettled(files.map(uploadMedium))
      const media = results.reduce((acc, result) => (result.status === 'fulfilled' ? [...acc, result.value] : acc), [])
      const errors = results.reduce(
        (acc, result) =>
          result.status === 'rejected'
            ? [...acc, result.reason?.response?.data?.error || result.reason?.message || result.reason]
            : acc,
        []
      )

      if (errors.length) {
        console.error(errors)
        this.errors = errors
      }

      this.isUploading = false

      this.addMedia(media)
    },

    addMedia(media: Medium[]) {
      this.$emit('input', [...(this.value || []), ...media])
    },

    removeMedium(medium: Medium) {
      const value = this.value.filter(({ url }) => medium.url !== url)
      this.$emit('input', value)
    },

    updateMedium(index: number, change: Partial<Medium>) {
      const value = updateIndexInArray(this.value, index, (medium) => ({ ...medium, ...change }))
      this.$emit('input', value)
    },

    handleMediaReorder(value: Medium[]) {
      this.$emit('input', value)
    },

    async editLink(index: number) {
      const link = await this.$modal(EditLinkModal, { link: this.value[index]?.link })

      this.updateMedium(index, { link })
    }
  }
})
</script>
