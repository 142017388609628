export namespace Dashboard {
  export enum ProductStatus {
    Active = 'active',
    SoldOut = 'sold_out',
    Inactive = 'inactive'
  }

  export type BasicProductInfo = {
    title: string
    image: string
    slug: string
    status: ProductStatus
    short_description: string
    default_view: 'front' | 'back'
    published: boolean
    is_featured: boolean
    ends_at?: Date
    rolling_fulfilment_period?: number
    rolling_fulfilment_batch?: number
    default_variant_id: number
  }

  export type BasicProductAnalytics = {
    orders_count: number
    items_count: number
    profit: number
    page_views: number
  }

  export type BasicProductSeo = {
    seo_title: string
    seo_description: string
    long_slug: string
  }

  export type Merchandise = {
    from: string
    to: string
    refunded_amount: number
    variants: ProductVariant[]
    default_variant_id: number
  }

  export type ProductVariant = {
    hidden: boolean
    previews: Preview[]
    quantity: number
    total_profit: number
    variant: VariantInfo
    variant_price: string
  }

  export type Preview = {
    front: { url: string; custom: boolean }
  }

  export type VariantInfo = {
    id: number
    name: string
    color_code: string
    style_id: number
  }

  export type PromoCode = {
    code: string
    amount: number
    type: 'relative' | 'absolute'
    times_used: number
    is_active: boolean
    id?: number
    valid_until?: Date
    max_available?: number
    is_eternal: boolean
    is_unlimited: boolean
  }

  export type ProductCollection = {
    id: number
    name: string
  }

  export type ProductCollectionContainer = {
    used: Array<ProductCollection>
    available: Array<ProductCollection>
  }
}
