import store from '@/packs/main/store'

export default {
  saveVisit(queryParams) {
    let params = this.visitParams(queryParams)
    params.referrer_url = document.referrer.slice(0, 255)
    params.landing_url = window.location.href.slice(0, 255)

    store.dispatch('visit/setVisit', params)
  },

  visitParams(queryParams) {
    const KEYWORDS = ['kw', 'var', 'keyword', 'click', 'utm_campaign', 'utm_source', 'utm_medium']

    return KEYWORDS.reduce((visitParams, keyword) => {
      visitParams[keyword] = queryParams[keyword]
      return visitParams
    }, {})
  }
}
