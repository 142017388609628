<template>
  <div>
    <RInput type="datetime-local" :value="dateTime" @input="onInput" />
  </div>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Vue from 'vue'

dayjs.extend(utc)

import RInput from '@/components/ui/RInput.vue'

export default Vue.extend({
  components: {
    RInput
  },

  props: {
    value: {
      type: [String, Date],
      default: ''
    }
  },

  computed: {
    dateTime(): string {
      return dayjs(this.value).utc().format('YYYY-MM-DD HH:mm')
    }
  },

  methods: {
    onInput(value: string) {
      this.$emit('input', dayjs(value).utc(true).format())
    }
  }
})
</script>
