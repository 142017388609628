<template>
  <div
    class="RInput r-input relative"
    :class="{
      'r-input--invalid': invalid,
      'r-input--disabled': disabled,
      'r-input--small': size === 'small'
    }"
    @click="$refs.input.focus()"
  >
    <slot v-if="hasPrefix" name="prefix">
      <component
        :is="isPrefixIconClickable ? 'button' : 'div'"
        class="absolute inset-y-0 left-0 flex w-12 opacity-50 outline-none"
        :class="{ 'cursor-pointer hover:opacity-100': isPrefixIconClickable }"
        tabindex="-1"
        @click.prevent="$emit('prefix-icon-click')"
      >
        <RIcon class="m-auto w-5" :name="prefixIcon" />
      </component>
    </slot>

    <input
      ref="input"
      :aria-label="$attrs.placeholder || 'input'"
      v-bind="$attrs"
      class="r-next-input__nested w-full flex-auto self-stretch"
      :class="{ 'pl-12': prefixIcon, 'pr-12': suffixIcon }"
      :disabled="disabled"
      :type="$attrs.type || 'text'"
      :value="value"
      v-on="listeners"
    />

    <slot v-if="hasSuffix" name="suffix">
      <component
        :is="isSuffixIconClickable ? 'button' : 'div'"
        class="absolute inset-y-0 right-0 flex w-12 opacity-50 outline-none"
        :class="{ 'cursor-pointer hover:opacity-100': isSuffixIconClickable }"
        tabindex="-1"
        @click.prevent="$emit('suffix-icon-click')"
      >
        <RIcon class="m-auto w-5" :name="suffixIcon" />
      </component>
    </slot>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { transformInputListeners } from '@/components/mixins/transformInputListeners'
import RIcon from '@/components/ui/RIcon.vue'

const sizes = ['normal', 'small', 'large', 'xlarge'] as const

type Size = typeof sizes[number]

export default Vue.extend({
  components: {
    RIcon
  },

  mixins: [transformInputListeners],

  inheritAttrs: false,

  props: {
    value: {
      type: [Number, String, Date],
      default: ''
    },

    prefixIcon: {
      type: String,
      default: ''
    },

    suffixIcon: {
      type: String,
      default: ''
    },

    size: {
      type: String as PropType<Size>,
      default: 'normal',
      validator: (value: Size) => sizes.includes(value)
    },

    disabled: Boolean,

    invalid: Boolean
  },

  computed: {
    hasPrefix(): boolean {
      return Boolean(this.$slots.prefix || this.prefixIcon)
    },

    hasSuffix(): boolean {
      return Boolean(this.$slots.suffix || this.suffixIcon)
    },

    isPrefixIconClickable(): boolean {
      return Boolean(this.$listeners['prefix-icon-click'])
    },

    isSuffixIconClickable(): boolean {
      return Boolean(this.$listeners['suffix-icon-click'])
    }
  }
})
</script>

<style lang="postcss">
.RInput.RInput /* Intentional selector overload */ {
  @apply cursor-text flex items-stretch px-0 leading-none;
}
</style>
