<template>
  <div ref="element" class="Search">
    <div class="relative">
      <SearchInput
        v-model="query"
        class="w-full"
        :placeholder="placeholder"
        prefix-icon="search"
        size="large"
        :style="{ '--r-input-textColor': 'inherit' }"
        @focus="open()"
        @input="searchDebounced()"
        @keydown.down.prevent="moveCursorDown()"
        @keydown.enter="submit()"
        @keydown.up.prevent="moveCursorUp()"
      />

      <div
        v-if="isOpen && results.length"
        class="absolute top-full left-0 mt-1 min-w-full max-w-[calc(100vw-2rem)] rounded border bg-white text-black-300 shadow-md"
        :style="{
          'border-color': $color('base.border.light')
        }"
      >
        <div
          v-for="(item, index) in results"
          :key="item.product_slug"
          class="flex border-b border-solid border-gray-100 text-sm"
          :class="{ 'bg-gray-100': cursorIndex === index }"
        >
          <a class="flex w-full items-center p-4" :href="getSearchResultLink(item)" @mouseenter="setCursorIndex(index)">
            <img class="aspect-square w-12 mix-blend-multiply" :src="item.images.front" />

            <div class="ml-4">
              <div class="mb-1">{{ item.campaign_title }}</div>
              <div>${{ item.price }}</div>
            </div>
          </a>
        </div>

        <a
          class="block w-full py-4 text-center font-semibold hover:bg-gray-100"
          :class="{ 'bg-gray-100': cursorIndex === results.length }"
          :href="searchAllLink"
          @mouseenter="setCursorIndex(results.length)"
        >
          See all results
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import debounce from 'lodash/debounce'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router/composables'

import SearchInput from '@/components/SearchInput.vue'
import { searchProducts } from '@/services/SearchService'
import { CampaignVariant } from '@/types'
import { useOnClickOutside } from '@/utils/useOnClickOutside'
import { useOnEscapeKeyDown } from '@/utils/useOnEscapeKeyDown'

const searchLimit = 6

const getSearchPageLink = (query: string) => {
  return `${window.location.origin}/shop?text=${encodeURIComponent(query)}`
}

const getSearchResultLink = (product: CampaignVariant) => {
  return `${window.location.origin}/${product.product_slug}?variant=${product.id}&kw=campaign_search_bar&click=campaign_search_bar-${product.product_slug}`
}

const props = withDefaults(
  defineProps<{
    filters?: any
    placeholder?: string
  }>(),
  {
    filters: () => {},
    placeholder: undefined
  }
)

const route = useRoute()

const element = ref<HTMLElement>()
const query = ref<string>(route.query.text as string)
const results = ref<CampaignVariant[]>([])
const cursorIndex = ref<number>(-1)
const isOpen = ref(false)

const searchAllLink = computed(() => {
  return getSearchPageLink(query.value)
})

const activeItem = computed(() => {
  return results.value[cursorIndex.value]
})

const open = () => {
  if (results.value.length) {
    isOpen.value = true
  }
}

const close = () => {
  isOpen.value = false
  cursorIndex.value = -1
}

const search = async () => {
  if (!query.value) {
    results.value = []
    return
  }

  const params = { ...props.filters, text: query }
  results.value = (await searchProducts(params)).results.slice(0, searchLimit) as CampaignVariant[]

  open()
}

const searchDebounced = debounce(search, 300)

const setCursorIndex = (index: number) => {
  cursorIndex.value = Math.max(-1, Math.min(index, results.value.length))
}

const moveCursorDown = () => {
  if (!results.value.length) {
    return
  }

  if (!isOpen.value) {
    return open()
  }

  setCursorIndex(cursorIndex.value + 1)
}

const moveCursorUp = () => {
  if (!isOpen.value || !results.value.length) {
    return
  }

  setCursorIndex(cursorIndex.value - 1)
}

const submit = () => {
  if (!query.value) {
    return
  }

  if (!activeItem.value) {
    window.location.assign(searchAllLink.value)
    return
  }

  window.location.assign(getSearchResultLink(activeItem.value))
}

useOnEscapeKeyDown(close, () => isOpen.value)
useOnClickOutside(element, close, () => isOpen.value)
</script>
