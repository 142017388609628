import isEmpty from 'lodash/isEmpty'

import { config } from '@/config'

const scriptsLoaded = {}

export function dynamicallyLoadScript(url: string, callback?: Function): void {
  if (!url || !isEmpty(scriptsLoaded[url])) {
    return undefined
  }

  let script = document.createElement('script')
  script.setAttribute('src', url)

  if (callback) {
    script.onload = (script as any).onreadystatechange = function () {
      if (!this.readyState || this.readyState == 'complete') {
        callback()
      }
    }
  }

  document.body.appendChild(script)
  scriptsLoaded[url] = true
}

export function loadAttentive(hide?: boolean): boolean {
  const jvnPaths = ['/store/jvn', '/stun-queen', '/rainbow-stun-queen']
  const jvnStore = jvnPaths.some((path) => window.location.pathname.startsWith(path)) || config.slug === 'jvn'

  return config.attentive_enabled && !jvnStore && !hide && !(window as any).Cypress
}

export function loadGtm(): void {
  const w = window
  const d = document
  const s = 'script'
  const l = 'dataLayer'
  const i = config.google_tag_manager_id

  w[l] = w[l] || []
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : ''
  j.async = true
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
  f.parentNode.insertBefore(j, f)
}

export function loadFbPixel() {
  let f = window as any
  const b = document
  const e = 'script'
  const v = '//connect.facebook.net/en_US/fbevents.js'
  let n = undefined
  let t = undefined
  let s = undefined

  if (f.fbq) return
  n = f.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
  }
  if (!f._fbq) f._fbq = n
  n.push = n
  n.loaded = !0
  n.version = '2.0'
  n.queue = []
  t = b.createElement(e)
  t.async = !0
  t.src = v
  s = b.getElementsByTagName(e)[0]
  s.parentNode.insertBefore(t, s)

  f.fbq('set', 'autoConfig', false, config.fb_pixel_id)
  f.fbq('init', config.fb_pixel_id)
  f.fbq('track', 'PageView')
}

export function loadKlarna() {
  let node = document.createElement('script')
  node.src =
    config.env === 'production'
      ? 'https://na-library.klarnaservices.com/lib.js'
      : 'https://na-library.playground.klarnaservices.com/lib.js'
  node.setAttribute('data-client-id', config.klarna_client_id)
  node.async = true
  document.querySelector('head').appendChild(node)
}

export function loadOneTrust() {
  if (!config.one_trust_id) return

  let node = document.createElement('script')
  node.src = `https://cdn.cookielaw.org/consent/${config.one_trust_id}/OtAutoBlock.js`
  document.querySelector('head').appendChild(node)

  node = document.createElement('script')
  node.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
  node.setAttribute('data-domain-script', config.one_trust_id)
  document.querySelector('head').appendChild(node)
  ;(window as any).OptanonWrapper = (window as any).OptanonWrapper ? (window as any).OptanonWrapper : function () {}
}
