import axios from 'axios'

import { CampaignVariant, SearchResults } from '@/types'

export async function searchProducts(
  params: Record<string, string> = {}
): Promise<SearchResults<Partial<CampaignVariant>>> {
  const data = (await axios.get('/search.json', { params })).data

  // Converts the data to the CampaignVariant-ish form
  data.results = data.results.map(
    (product: any): Partial<CampaignVariant> => ({
      id: product.variants[0].id,
      active: product.active,
      price: product.price,
      product_slug: product.slug,
      campaign_title: product.title,
      images: { front: product.variants[0].preview, previews: product.variants.map((v: any) => v.preview) }
    })
  )

  return data
}
