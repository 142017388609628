<template>
  <div class="RColorInput" @keydown.delete="resetValue()" @keydown.esc="resetValue()">
    <label class="group relative m-0 flex cursor-pointer items-center gap-3">
      <div class="r-input relative -ml-0.5 h-7 min-h-0 w-7 overflow-hidden rounded-full p-0">
        <div
          class="absolute inset-0"
          :class="{ 'bg-[linear-gradient(-45deg,transparent_49%,red_49%,red_51%,transparent_51%)] bg-gray-50': !value }"
          :style="{ 'background-color': value }"
        />

        <input
          v-bind="$attrs"
          ref="input"
          class="absolute top-0 left-0 h-full w-full cursor-pointer opacity-0"
          type="color"
          :value="value"
          v-on="listeners"
        />
      </div>

      <div v-if="caption" class="text-[0.9rem]">
        {{ caption }}
      </div>

      <div
        v-if="value !== $props.default"
        class="invisible ml-auto flex w-12 cursor-pointer opacity-50 hover:opacity-100 group-hover:visible"
        @click.prevent="resetValue()"
      >
        <RIcon class="m-auto w-5" name="x" />
      </div>
    </label>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import { transformInputListeners } from '@/components/mixins/transformInputListeners'
import RIcon from '@/components/ui/RIcon.vue'

export default Vue.extend({
  components: {
    RIcon
  },

  mixins: [transformInputListeners],

  props: {
    value: {
      type: String,
      default: ''
    },

    caption: {
      type: String,
      default: ''
    },

    default: {
      type: String,
      default: ''
    }
  },

  methods: {
    resetValue() {
      this.$emit('input', this.default)
      this.$emit('change', this.default)
    }
  }
})
</script>
