import { apiClient } from '@/services/ApiService'

export default {
  async subscribe(email, storeId, productId) {
    const payload = {
      email: email,
      store_id: storeId,
      product_id: productId
    }

    return (await apiClient.post('/emails/subscribe', payload)).data
  },

  async confirm_subscribe(fingerprint, email) {
    const payload = {
      fingerprint: fingerprint,
      email: email
    }
    return (await apiClient.post('/emails/confirm-subscribe', payload)).data
  },

  async update_subscription(fingerprint, email, unsubscribeFrom) {
    const payload = {
      fingerprint: fingerprint,
      email: email,
      unsubscribe_from: unsubscribeFrom
    }
    return (await apiClient.post('/emails/unsubscribe', payload)).data
  }
}
