import { knownSocialNetworks } from '@/constants/knownSocialNetworks'

const getHostname = (url: string) => {
  try {
    return new URL(url).hostname
  } catch (e) {
    return url
  }
}

export type SocialLink = {
  link: string
  icon: string
  label: string
}

export function parseSocialLink(link: string): SocialLink {
  const { icon = 'globe-alt', label = getHostname(link) } =
    knownSocialNetworks.find(({ urlPattern }) => urlPattern.test(link)) || {}

  return {
    link,
    icon,
    label
  }
}
