import WebFont from 'webfontloader'

export function loadFonts(displayTypeface: string = 'Montserrat', bodyTypeface: string = 'Montserrat') {
  // XXX: This is prepared for future customization through the config
  const fallbackTypeface = `Helvetica Neue, Helvetica, Arial, sans-serif`

  const webFontConfig = {
    google: {
      families: [`${displayTypeface}:400,500,600,700`, `${bodyTypeface}:400,500,600,700`]
    }
  }

  WebFont.load({
    ...webFontConfig,
    fontloading: () => {
      let root = document.documentElement
      root.style.setProperty('--r-typeface-display', `${displayTypeface}, ${fallbackTypeface}`)
      root.style.setProperty('--r-typeface-body', `${displayTypeface}, ${fallbackTypeface}`)
    }
  })
}

export default loadFonts
