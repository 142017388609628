import { AsyncComponent, Component } from 'vue'

import { googleFonts } from '@/constants/googleFonts'
import { Medium } from '@/types/common'
import { FormStructure } from '@/types/forms'

export const storeSortOptions = {
  manual_with_sequence: 'manual with sequence',
  by_date: 'by date (newest first)'
}

export type Store = {
  id: number
  title: string
  brand: string
  slug: string
  description: string
  status: 'active' | 'inactive' | 'password_protected'
  password_layout?: boolean
  default_sort: keyof typeof storeSortOptions
  social_links: string[]
  fb_pixel_ids: string[]
  hide_attentive?: boolean
  cameo_merch?: boolean
  external_review_token?: string
  customization: Customization
  products: {
    id: number
    sequence: number
  }[]
  created_at: string
  user_created_at?: string
  custom_domain?: string
}

export type Layout = {
  id: string
  name: string
  preview: string
}

export type Customization = {
  header: Header
  footer: Footer
  banner?: AnnouncementsBanner // todo: should be optional
  content: ContentItem[]
  theme: Theme
  urls: {
    logo?: Medium[]
    favicon?: Medium[]
    preview?: Medium[]
  }
}

export type Theme = {
  display_typeface: keyof typeof googleFonts
  body_typeface: keyof typeof googleFonts
  animation: any // todo
  colors: any // todo
}

export const headerLayouts = {
  default: 'Default',
  centered: 'Centered'
}

export type Header = {
  layout: keyof typeof headerLayouts
  full_width: boolean
  background_color?: string
  text_color?: string
  static?: boolean
  transparent?: boolean
}

export type Footer = {
  full_width: boolean
  background_color?: string
  text_color?: string
}

export const announcementsBannerDeviceFields = [
  'text',
  'call_to_action_url',
  'call_to_action_text',
  'background_color',
  'text_color',
  'animated'
] as const

export type AnnouncementsBanner = {
  text: string
  call_to_action_url: string
  call_to_action_text: string
  background_color?: string
  text_color?: string
  animated?: boolean
  device: {
    mobile: Partial<Pick<AnnouncementsBanner, typeof announcementsBannerDeviceFields[number]>>
  }
}

export type Link = {
  id: string
  order: number
  title: string
  type: 'link'
  url: string
}

export type Page = {
  id: string
  order: number
  title: string
  type: 'page'
  unlisted?: boolean
  password?: boolean
  slug: string
  background?: Medium
  hideHeader?: boolean
  hideFooter?: boolean
  blocks: (
    | Blocks.Hero
    | Blocks.Image
    | Blocks.ImageWithText
    | Blocks.Text
    | Blocks.HorizontalSpace
    | Blocks.Embed
    | Blocks.Products
    | Blocks.NewsletterSignup
    | Blocks.Html
    | Blocks.CountdownTimer
    | Blocks.StorePassword
    | Blocks.NewsletterSignup
  )[]
}

export type ContentItem = Page | Link

export interface PerDeviceData {
  device?: Record<'mobile', any>
}

export interface BlockData extends PerDeviceData {
  id: string
  blueprint_id: string
}

type BlockBlueprintFormStructure<T> = FormStructure<Omit<T, 'id' | 'blueprint_id' | 'device'>>

export type BlockBlueprint<T extends BlockData = BlockData> = {
  id: T['blueprint_id']
  name: string
  icon: string
  component: Component | AsyncComponent
  formDeviceFields?: Readonly<string[]> | string[]
  formStructure: BlockBlueprintFormStructure<T> | ((data: any) => BlockBlueprintFormStructure<T>)
  create: () => T
}

export type BlockDataWithBlueprint<T extends BlockData = BlockData> = {
  data: T
  blueprint: BlockBlueprint<T>
}

export namespace Blocks {
  export const spacingOptions = {
    'none': 'None',
    'small': 'Small',
    'medium': 'Medium',
    'large': 'Large'
  }

  // Hero

  export const heroHeights = {
    image: 'Auto',
    screen: 'Screen height'
  }

  export const heroTransitions = {
    fade: 'Fade',
    slide: 'Slide'
  }

  export const heroDeviceFields = ['delay', 'height', 'images', 'transition'] as const

  export type Hero = BlockData & {
    blueprint_id: 'hero'
    delay: number
    height: keyof typeof heroHeights
    images: Medium[]
    transition: keyof typeof heroTransitions
    background_color?: string
    text_color?: string
    link?: string
    open_in_new_tab?: boolean
    device: {
      mobile: Partial<Pick<Hero, typeof heroDeviceFields[number]>>
    }
  }

  // Image

  export const imageLayouts = {
    default: 'Default',
    '2_1': '2:1',
    '1_2': '1:2',
    carousel: 'Carousel'
    // TODO: Follow up
    // gallery: 'Image gallery'
  }

  export const imageDeviceFields = [
    'images',
    'layout',
    'text_color',
    'background_color',
    'padding_vertical',
    'padding_horizontal',
    'spacing'
  ] as const

  export type Image = BlockData & {
    blueprint_id: 'image'
    layout: keyof typeof imageLayouts
    items_per_row: number
    images: Medium[]
    text_color: string
    background_color?: string
    full_width?: boolean
    padding_horizontal: boolean
    padding_vertical: boolean
    spacing: keyof typeof spacingOptions
    device: {
      mobile: Partial<Pick<Image, typeof imageDeviceFields[number]>>
    }
  }

  // Image with text

  export const imageWithTextLayouts = {
    centered_left: 'Centered left',
    centered_right: 'Centered right',
    text_over_image: 'Text over image',
    '2_1': '2:1',
    '1_2': '1:2'
  }

  export const imageWithTextDeviceFields = [
    'images',
    'layout',
    'padding_vertical',
    'padding_horizontal',
    'background_color',
    'text_color'
  ] as const

  export type ImageWithText = BlockData & {
    blueprint_id: 'image_with_text'
    layout: keyof typeof imageWithTextLayouts
    images: Medium[]
    content: string
    call_to_action_url?: string
    call_to_action_text?: string
    full_width: boolean
    padding_horizontal: boolean
    padding_vertical: boolean
    background_color?: string
    text_color?: string
    device: {
      mobile: Partial<Pick<ImageWithText, typeof imageWithTextDeviceFields[number]>>
    }
  }

  // Text

  export const textLayouts = {
    left: 'Left',
    center: 'Center',
    right: 'Right'
  }

  export type Text = BlockData & {
    blueprint_id: 'text'
    layout: keyof typeof textLayouts
    content: string
    background_color?: string
    text_color?: string
    full_width: boolean
    padding_horizontal: boolean
    padding_vertical: boolean
  }

  // Horizontal space

  export const horizontalSpaceLayout = {
    small: 'Small',
    medium: 'Medium',
    large: 'Large'
  }

  export type HorizontalSpace = BlockData & {
    blueprint_id: 'horizontal_space'
    layout: keyof typeof horizontalSpaceLayout
    background_color?: string
    text_color?: string
  }

  // Embed

  export type Embed = BlockData & {
    blueprint_id: 'embed'
    content: string
    background_color?: string
    text_color?: string
    full_width: boolean
    padding_horizontal: boolean
    padding_vertical: boolean
  }

  // Products

  export const productsLayouts = {
    grid: 'Grid',
    row: 'Row',
    '2_1': '2:1'
    // TODO: Follow up
    // grid_featured: 'Grid w/featured product'
  }

  export const productsSources = {
    all: 'All products',
    // collection: 'collection',
    manual: 'Select manually'
  }

  export const productsSort = {
    manual_with_sequence: 'Manual'
  }

  export const productsTextAlignments = {
    left: 'Left',
    center: 'Center',
    right: 'Right'
  }

  export const productsDeviceFields = [
    'layout',
    'show_filters',
    'show_variants',
    'show_back_preview',
    'items_per_row',
    'number_of_rows',
    'text_alignment',
    'product_background_color',
    'background_color',
    'text_color'
  ] as const

  export type Products = BlockData & {
    blueprint_id: 'products'
    layout: keyof typeof productsLayouts
    default_sort: keyof typeof productsSort
    show_filters?: boolean
    show_variants?: boolean
    show_back_preview?: boolean
    items_per_row: number
    number_of_rows: number
    text_alignment: keyof typeof productsTextAlignments
    product_background_color?: string
    background_color?: string
    text_color?: string
    full_width?: boolean
    padding_horizontal?: boolean
    padding_vertical?: boolean
    device: {
      mobile: Partial<Pick<Products, typeof productsDeviceFields[number]>>
    }
  } & (
      | {
          source: 'all'
        }
      | {
          source: 'manual'
          product_ids: number[]
        }
      | {
          source: 'collection'
          collection_id: number
        }
    )

  // Newsletter signup form

  export type NewsletterSignup = BlockData & {
    blueprint_id: 'newsletter_signup'
    full_width: boolean
    padding_horizontal: boolean
    padding_vertical: boolean
    background_color?: string
    text_color?: string
  }

  // Store password

  export type StorePassword = BlockData & {
    blueprint_id: 'store_password'
    full_width: boolean
    padding_horizontal: boolean
    padding_vertical: boolean
    background_color?: string
    text_color?: string
  }

  // Html

  export type Html = BlockData & {
    blueprint_id: 'html'
    content: string
    full_width: boolean
    padding_horizontal: boolean
    padding_vertical: boolean
    background_color?: string
    text_color?: string
  }

  // Countdown Timer

  export type CountdownTimer = BlockData & {
    blueprint_id: 'countdown_timer'
    date: Date
    full_width: boolean
    padding_horizontal: boolean
    padding_vertical: boolean
    background_color?: string
    text_color?: string
  }
}

export interface Uploader {
  (file: File): Promise<Medium>
}
