<template>
  <textarea v-bind="$attrs" class="RTextarea r-input" :value="value" v-on="listeners" />
</template>

<script lang="ts">
import Vue from 'vue'

import { transformInputListeners } from '@/components/mixins/transformInputListeners'

export default Vue.extend({
  mixins: [transformInputListeners],

  props: {
    value: {
      type: String,
      default: ''
    }
  }
})
</script>
