import { config } from '@/config'

function isSet(name: string): boolean {
  return !config.ignore_ab_tests && Boolean((window as any).ab_tests?.[name])
}

// A/B tests are defined as getters – reading them could reflect on-the-fly changes on window.ab_tests entrypoint,
// which could be modified by the test driver at any time. It also provides a place to evaluate if the test is
// eligible at the moment and return the value which does reflect that
export const abTests = {
  get productZoom() {
    return isSet('productZoom')
  },
  get guestbook() {
    return isSet('guestbook')
  },
  get sizePicker() {
    return isSet('sizePicker')
  },
  get shippingChange() {
    return isSet('shippingChange')
  },
  get test001() {
    return isSet('test001')
  },
  get shippedFrom() {
    return isSet('shippedFrom')
  },
  get satisfactionGuarantee() {
    // Customer satisfaction guarantee on product page
    return isSet('satisfactionGuarantee')
  },
  get placeOrderLockIcon() {
    return isSet('placeOrderLockIcon')
  },
  get expressCheckout() {
    return isSet('expressCheckout')
  },
  get optionalPhoneNumberInCheckout() {
    return isSet('optionalPhoneNumberInCheckout')
  },
  get shoppingCartUpsellOrder() {
    return isSet('shoppingCartUpsellOrder')
  },
  get disablePayPalExpressCheckout() {
    return isSet('disablePayPalExpressCheckout')
  }
}

export function listActiveAbTests(): string[] {
  return Object.entries(abTests)
    .filter(([_, value]) => value)
    .map(([name]) => name)
}
