<template>
  <button aria-label="Toggle menu" class="_hamburger" v-on="$listeners">
    <svg
      class="m-auto h-full w-full overflow-visible"
      viewBox="-1 -1 2 2"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g class="stroke-current transition-transform duration-150" :class="{ 'translate-x-[0.4px]': isOpen }">
        <g class="transition-all duration-150" :class="{ 'rotate-45': isOpen }" style="transform-origin: -1px -0.75px">
          <line stroke-width="0.25" x1="-1" x2="1" y1="-0.65" y2="-0.65" />
        </g>

        <g class="transition-all duration-150" :class="{ 'opacity-0': isOpen }">
          <line stroke-width="0.25" x1="-1" x2="1" y1="0" y2="0" />
        </g>

        <g class="transition-all duration-150" :class="{ '-rotate-45': isOpen }" style="transform-origin: -1px 0.75px">
          <line stroke-width="0.25" x1="-1" x2="1" y1="0.65" y2="0.65" />
        </g>
      </g>
    </svg>

    <div class="absolute inset-0">
      <slot />
    </div>
  </button>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style lang="postcss" scoped>
._hamburger {
  @apply relative inline-flex mx-3 w-5 h-5 outline-none appearance-none;
}
</style>
