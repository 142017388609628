import { queryParams } from '@/types'

export function parseUrl(url: string): { base: string; params: { [key: string]: string } } {
  const [base, query = ''] = url.split('?')
  const params = query
    .split('&')
    .filter(Boolean)
    .reduce((params, item) => {
      const [key, value] = item.split('=').map(decodeURIComponent)

      return {
        ...params,
        [key]: value !== undefined ? value : true
      }
    }, {})

  return { base, params }
}

export function makeUrl(base: string, params: queryParams): string {
  const query = Object.entries(params)
    .filter(([_key, value]) => value !== undefined)
    .map(([key, value]) => [key, value].map(encodeURIComponent).join('='))
    .join('&')

  return [base, query].join('?')
}

export function changeUrlQuery(url: string, params: queryParams): string {
  const { base, params: currentParams } = parseUrl(url)
  return makeUrl(base, { ...currentParams, ...params })
}
