<template>
  <div
    class="w-full bg-gray-600 p-4 pr-3 text-center text-sm text-white md:text-base"
    data-test-id="discount"
    v-html="content"
  />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { Discount } from '@/types'
import { formatDiscount } from '@/utils/formatDiscount'

export default Vue.extend({
  props: {
    discount: {
      type: Object as PropType<Discount>,
      required: true
    }
  },

  computed: {
    content(): string {
      return this.$t('views.partials.header.discount', { discount_value: formatDiscount(this.discount) })
    }
  }
})
</script>
