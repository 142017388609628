<template>
  <component
    :is="component"
    class="r-button"
    :class="[classModifiers, { 'w-full': block }]"
    :disabled="$attrs.disabled || loading"
    v-bind="attrs"
    v-on="$listeners"
  >
    <slot name="prefix">
      <RIcon
        v-if="prefixIcon && !loading"
        :class="{ '-ml-0.5 w-5': size === 'normal', 'w-4': size === 'small' }"
        :name="prefixIcon"
      />
    </slot>

    <RIcon v-if="loading" :class="{ '-ml-0.5 w-5': size === 'normal', 'w-3': size === 'small' }" name="spinner" />

    <span v-if="$slots.default || label">
      <slot>
        {{ label }}
      </slot>
    </span>

    <slot name="suffix">
      <RIcon
        v-if="suffixIcon"
        :class="{ '-mr-0.5 w-5': size === 'normal', 'w-4': size === 'small' }"
        :name="suffixIcon"
      />
    </slot>
  </component>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import RIcon from '@/components/ui/RIcon.vue'

const sizes = ['normal', 'small', 'large', 'xlarge'] as const
const variants = ['primary', 'secondary', 'danger', 'light', 'dark'] as const

type Size = typeof sizes[number]
type Variant = typeof variants[number]

export default Vue.extend({
  components: {
    RIcon
  },

  props: {
    size: {
      type: String as PropType<Size>,
      default: 'normal',
      validator: (value: Size) => sizes.includes(value)
    },

    variant: {
      type: String as PropType<Variant>,
      default: 'primary',
      validator: (value: Variant) => variants.includes(value)
    },

    prefixIcon: {
      type: String,
      default: ''
    },

    suffixIcon: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      default: ''
    },

    active: Boolean,

    loading: Boolean,

    block: Boolean
  },

  computed: {
    component(): string {
      if (this.$attrs.to) {
        return 'router-link'
      }

      if (this.$attrs.href) {
        return 'a'
      }

      return 'button'
    },

    classModifiers() {
      const classModifiers = [this.size, this.variant]

      if (this.active) {
        classModifiers.push('active')
      }

      if (this.loading) {
        classModifiers.push('loading')
      }

      if (this.disabled || this.loading) {
        classModifiers.push('disabled')
      }

      return classModifiers.map((modifier) => `r-button--${modifier}`)
    },

    attrs(): object {
      return {
        // Sanitize attrs so the button would have attribute `type="button"` set by default
        type: this.component === 'button' ? 'button' : undefined,
        ...this.$attrs
      }
    }
  }
})
</script>
