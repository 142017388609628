export default {
  persist(key: string, value: any) {
    localStorage?.setItem(key, JSON.stringify(value))
  },

  load(key: string, defaultValue = undefined): any {
    return JSON.parse(localStorage?.getItem(key)) || defaultValue
  },

  remove(key: string) {
    localStorage?.removeItem(key)
  }
}
