<template>
  <div>
    <RButton
      block
      :disabled="loadingCampaigns"
      :loading="loadingCampaigns"
      variant="primary"
      @click="openProductPickerModal()"
    >
      {{ loadingCampaigns ? 'Loading products' : 'Select products' }}
    </RButton>

    <div v-if="value" class="mt-1 text-sm text-gray-400">
      {{ value.length }} product{{ value.length === 1 ? '' : 's' }} selected
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import CampaignVariantsPickerModal from '@/components/dashboard/store/modals/CampaignVariantsPickerModal.vue'
import RButton from '@/components/ui/RButton.vue'
import { useStoreDesignerStore } from '@/packs/dashboard/storeDesignerStore'
import { Campaign } from '@/types'

export default Vue.extend({
  components: {
    RButton
  },

  props: {
    value: {
      type: Array as PropType<number[]>,
      default: () => []
    },
    availableCampaigns: {
      type: Array as PropType<Campaign[]>,
      default: () => undefined
    }
  },

  computed: {
    loadingCampaigns(): Boolean {
      return this.availableCampaigns
        ? !this.availableCampaigns.length
        : useStoreDesignerStore().isLoadingAvailableCampaigns
    }
  },

  methods: {
    async openProductPickerModal() {
      const selectedCampaignVariantIds = await this.$modal(CampaignVariantsPickerModal, {
        availableCampaigns: this.availableCampaigns,
        selectedCampaignVariantIds: this.value
      })

      if (selectedCampaignVariantIds) {
        this.$emit('input', selectedCampaignVariantIds)
      }
    }
  }
})
</script>
