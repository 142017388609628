<template>
  <div class="flex items-center justify-between">
    <img class="max-h-[20px]" :src="value | googleFontImageUrl" />
    <RButton size="small" variant="secondary" @click="openFontPickerModal()">Edit</RButton>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import FontPickerModal from '@/components/dashboard/store/modals/FontPickerModal.vue'
import RButton from '@/components/ui/RButton.vue'
import { googleFontImageUrl } from '@/utils/googleFontImageUrl'

export default Vue.extend({
  components: {
    RButton
  },

  filters: {
    googleFontImageUrl
  },

  props: {
    value: {
      type: String,
      default: 'Montserrat'
    }
  },

  methods: {
    async openFontPickerModal() {
      const selectedFont = await this.$modal(FontPickerModal, {
        selectedFont: this.value
      })

      if (selectedFont) {
        this.$emit('input', selectedFont)
      }
    }
  }
})
</script>
