import dayjs from 'dayjs'
import debouncePromise from 'debounce-promise'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import round from 'lodash/round'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

import { abTests } from '@/abTests'
import {
  countriesByContinent,
  countriesWithRequiredFiscalCode,
  countriesWithRequiredState,
  ipBasedLocation
} from '@/config'
import { RootState } from '@/packs/main/store'
import AnalyticsService from '@/services/AnalyticsService'
import BugsnagService from '@/services/BugsnagService'
import CartService from '@/services/CartService'
import PricingService from '@/services/PricingService'
import StorageService from '@/services/StorageService'
import StoreService from '@/services/StoreService'
import { CartItem, Discount, ShippingAddress } from '@/types'
import { validateShippingAddress } from '@/validators/address'

const VERSION = 5
const CART_STORAGE_KEY = 'cart'
const DISCOUNT_STORAGE_KEY = 'discount'
const EXPIRATION_DATE = dayjs().add(28, 'days').toDate()

const isDigitalItem = (item: CartItem) => item.video_recording_request
const isDeliveryItem = (item: CartItem) => !isDigitalItem(item)

export type State = {
  discount?: Discount
  expiresOn?: Date
  version: number
  cartId: number
  cartToken: string
  paymentIntentId: string
  items: any[]
  order: any
  shipping: ShippingAddress
  isShippingValidated: boolean
  quotes: any
  errors: any
  loadingQuotes: boolean
  updatingCart: boolean
  fbPixelData: any
  europeanUnionVisitor: boolean
  phoneNumberRequired: boolean
  phoneNumberOptional: boolean
  fiscalCodeRequired: boolean
  removedCampaignVariantIds: number[]
}

const getDefaultState = (): Partial<State> => ({
  version: VERSION,
  expiresOn: EXPIRATION_DATE,
  cartId: null,
  cartToken: null,
  paymentIntentId: null,
  items: [],
  order: {},
  shipping: {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    state: '',
    country_code: '',
    fiscal_code: ''
  },
  isShippingValidated: false,
  quotes: {},
  errors: {},
  loadingQuotes: false,
  updatingCart: false,
  fbPixelData: {},
  europeanUnionVisitor: false,
  phoneNumberRequired: false,
  phoneNumberOptional: false,
  fiscalCodeRequired: false
})

const types = {
  CLEAR_CART: 'CLEAR_CART',
  ITEM_ADDED: 'ITEM_ADDED',
  ITEM_REMOVED: 'ITEM_REMOVED',
  SET_CART_ID: 'SET_CART_ID',
  SET_CART_TOKEN: 'SET_CART_TOKEN',
  SET_PAYMENT_INTENT_ID: 'SET_PAYMENT_INTENT_ID',
  SET_FB_PIXEL: 'SET_FB_PIXEL',
  SET_QUANTITY: 'SET_QUANTITY',
  SET_ORDER: 'SET_ORDER',
  SET_SHIPPING: 'SET_SHIPPING',
  SET_SHIPPING_VALIDATED: 'SET_SHIPPING_VALIDATED',
  SET_QUOTES: 'SET_QUOTES',
  SET_ERRORS: 'SET_ERRORS',
  SET_LOADING_QUOTES: 'SET_LOADING_QUOTES',
  SET_UPDATING_CART: 'SET_UPDATING_CART',
  SET_EUROPEAN_UNION_VISITOR: 'SET_EUROPEAN_UNION_VISITOR',
  SET_DISCOUNT: 'SET_DISCOUNT',
  REMOVE_DISCOUNT: 'REMOVE_DISCOUNT',
  REMOVE_UPSELL: 'REMOVE_UPSELL',
  PHONE_NUMBER_REQUIRED: 'PHONE_NUMBER_REQUIRED',
  PHONE_NUMBER_OPTIONAL: 'PHONE_NUMBER_OPTIONAL',
  FISCAL_CODE_REQUIRED: 'FISCAL_CODE_REQUIRED',
  CART_ITEMS_SET: 'CART_ITEMS_SET',
  REMOVED_CAMPAIGN_VARIANTS_SET: 'REMOVED_CAMPAIGN_VARIANTS_SET'
}

const getPersistedCart = (): any => {
  const storageData = StorageService.load(CART_STORAGE_KEY)

  // Cart doesn't have a version
  if (!storageData?.version) {
    return undefined
  }

  // Cart version is outdated
  if (storageData?.version < VERSION) {
    return undefined
  }
  // Cart has expiration and it is expired
  if (dayjs(storageData?.expiresOn).isValid() && dayjs(storageData?.expiresOn).isBefore(dayjs())) {
    return undefined
  }

  return storageData
}

// check local storage version and purge loaded data if version is older, or cart is expired
const persistedCart = getPersistedCart()
const persistedDiscount = StorageService.load(DISCOUNT_STORAGE_KEY)

const state = (): State => {
  const defaultState = getDefaultState()

  return {
    discount: persistedDiscount,
    version: persistedCart?.version || defaultState.version,
    expiresOn: persistedCart?.expiresOn || defaultState.expiresOn,
    cartId: persistedCart?.cartId || defaultState.cartId,
    cartToken: persistedCart?.cartToken || defaultState.cartToken,
    paymentIntentId: persistedCart?.paymentIntentId || defaultState.paymentIntentId,
    items: persistedCart?.items || defaultState.items,
    order: persistedCart?.order || defaultState.order,
    shipping: persistedCart?.shipping || defaultState.shipping,
    isShippingValidated: defaultState.isShippingValidated,
    quotes: persistedCart?.quotes || defaultState.quotes,
    errors: defaultState.errors,
    loadingQuotes: defaultState.loadingQuotes,
    updatingCart: defaultState.updatingCart,
    fbPixelData: persistedCart?.fbPixelData || defaultState.fbPixelData,
    europeanUnionVisitor: persistedCart?.europeanUnionVisitor || defaultState.europeanUnionVisitor,
    phoneNumberRequired: persistedCart?.phoneNumberRequired || defaultState.phoneNumberRequired,
    phoneNumberOptional: persistedCart?.phoneNumberOptional || defaultState.phoneNumberOptional,
    fiscalCodeRequired: persistedCart?.fiscalCodeRequired || defaultState.fiscalCodeRequired,
    removedCampaignVariantIds: []
  }
}

const actions: ActionTree<State, RootState> = {
  async init({ commit, dispatch, state }) {
    if (state.items.length) {
      commit(types.SET_LOADING_QUOTES, true)
      dispatch('getQuotes')
    }

    if (!state.shipping.country_code) {
      const ipLocation = await ipBasedLocation()
      dispatch('setShipping', { ...state?.shipping, country_code: ipLocation.country_code })
    }

    if (abTests.optionalPhoneNumberInCheckout) {
      commit(types.PHONE_NUMBER_OPTIONAL, true)
    }
  },

  async addToCart({ commit, dispatch, state }, { item }) {
    commit(types.SET_UPDATING_CART, true)
    commit(types.SET_LOADING_QUOTES, true)

    const itemToUpdate = state.items.find(
      ({ product_variant_id, size, promotion_id, video_recording_request }) =>
        product_variant_id === item.product_variant_id &&
        size === item.size &&
        promotion_id === item.promotion_id &&
        item.video_recording_request === video_recording_request
    )

    if (itemToUpdate) {
      commit(types.SET_QUANTITY, {
        item: itemToUpdate,
        quantity: itemToUpdate.quantity + 1
      })
    } else {
      commit(types.ITEM_ADDED, { item })
    }

    AnalyticsService.trackAddToCart(item)
    commit(types.SET_UPDATING_CART, false)
    dispatch('persistCart')
    dispatch('getQuotes')
  },

  async setQuantity({ commit, dispatch, state }, { item, quantity }) {
    commit(types.SET_LOADING_QUOTES, true)
    commit(types.SET_QUANTITY, { item, quantity })
    if (quantity === 0) {
      commit(types.ITEM_REMOVED, { item })
      AnalyticsService.trackRemoveFromCart(item)
    }
    dispatch('persistCart')
    dispatch('getQuotes')
  },

  async persistCart({ state }) {
    StorageService.persist(CART_STORAGE_KEY, state)
  },

  getQuotes: debouncePromise(
    async (
      { commit, dispatch, state },
      { validate, removeInactiveProducts }: { validate?: boolean; removeInactiveProducts?: boolean } = {}
    ) => {
      if (!state.items.length) {
        return
      }

      const response = await CartService.getQuotes({
        email: state.shipping.email,
        cartId: state.cartId,
        cartToken: state.cartToken,
        items: state.items,
        shipping: state.shipping,
        validateAddress: validate,
        removeInactiveProducts
      })

      // Remove inactive items from cart
      if (response.actions?.removed_campaign_variant_ids) {
        const items = state.items.filter((item: CartItem) => {
          const keepThisItem = !response.actions.removed_campaign_variant_ids.includes(item.campaign_variant_id)

          if (!keepThisItem) {
            BugsnagService.info('Removing inactive product from Cart', item.id)
          }

          return keepThisItem
        })

        if (!items.length) {
          BugsnagService.info('Removed all products from Cart', '')
        }

        commit(types.CART_ITEMS_SET, { items })
      }

      commit(types.REMOVED_CAMPAIGN_VARIANTS_SET, {
        campaignVariantIds: response.actions?.removed_campaign_variant_ids || []
      })

      if (response.order_id) {
        // we should not have shopping cart with order ID. This happens only when there was a problem
        // during the order creation. Order has been successfully created on BE but FE doesn't know about it
        dispatch('removeDiscount')
        dispatch('clearCart')
        dispatch('visit/clearVisit', {}, { root: true })
      } else {
        let quotes = response.quotes

        let euVisitor = response?.european_union_visitor
        commit(types.SET_EUROPEAN_UNION_VISITOR, euVisitor)

        if (validate) {
          const serverValidationErrors = StoreService.setShippingErrors(response)
          await dispatch('validateShipping', serverValidationErrors)
        }

        commit(types.SET_FB_PIXEL, response.fb_pixel_data)
        commit(types.SET_EUROPEAN_UNION_VISITOR, response.european_union_visitor)
        commit(types.PHONE_NUMBER_REQUIRED, response.phone_number_required)
        commit(types.PHONE_NUMBER_OPTIONAL, response.phone_number_optional || abTests.optionalPhoneNumberInCheckout)
        commit(types.SET_CART_ID, response.id)
        commit(types.SET_CART_TOKEN, response.token)
        commit(types.SET_PAYMENT_INTENT_ID, response.payment_intent_id)
        commit(types.SET_QUOTES, { quotes })
        commit(types.SET_LOADING_QUOTES, false)
        dispatch('persistCart')
      }
    },
    500
  ),

  async validateShipping({ commit, dispatch, state }, serverErrors = {}) {
    dispatch('clearErrors')

    const requiredStateCountries = await countriesWithRequiredState()
    const addressValidation = await validateShippingAddress(state.shipping, {
      requireState: requiredStateCountries.includes(state.shipping.country_code),
      requireFiscalCode: state.fiscalCodeRequired,
      requireEmail: true,
      requirePhoneNumber: state.phoneNumberRequired
    })

    const isValid = addressValidation.isValid && isEmpty(serverErrors)

    if (!isValid) {
      const errors = { shipping: { ...addressValidation.errors, ...serverErrors?.shipping } }

      // If we have first_name or last_name error, we don't display full_name error
      if ((errors.shipping?.first_name || errors.shipping?.last_name) && errors.shipping?.full_name) {
        delete errors.shipping.full_name
      }

      dispatch('setErrors', errors)
    }

    commit(types.SET_SHIPPING_VALIDATED, { isShippingValidated: isValid })

    return addressValidation
  },

  clearCart({ commit, dispatch }) {
    commit(types.CLEAR_CART)
    dispatch('persistCart')
  },

  setOrder({ commit, dispatch }, order) {
    commit(types.SET_ORDER, { order })
    dispatch('persistCart')
  },

  async setShipping({ commit, dispatch }, shipping: ShippingAddress) {
    commit(types.SET_SHIPPING_VALIDATED, { isShippingValidated: false })
    commit(types.SET_SHIPPING, { shipping })

    const requiredFiscalCodeCountries = await countriesWithRequiredFiscalCode()
    const fiscalCodeRequired = requiredFiscalCodeCountries.includes(shipping.country_code)
    commit(types.FISCAL_CODE_REQUIRED, fiscalCodeRequired)

    dispatch('persistCart')
  },

  async loadQuotes(
    { commit, dispatch },
    { validate, removeInactiveProducts }: { validate?: boolean; removeInactiveProducts?: boolean } = {}
  ) {
    commit(types.SET_LOADING_QUOTES, true)
    await dispatch('getQuotes', { validate: validate, removeInactiveProducts: removeInactiveProducts })
  },

  // Validations

  clearErrors({ commit }) {
    commit(types.SET_ERRORS, { errors: {} })
  },

  setErrors({ commit, state }, errors) {
    commit(types.SET_ERRORS, { errors: { ...state.errors, ...errors } })
  },

  removeUpsell({ commit, dispatch }) {
    commit(types.REMOVE_UPSELL)
    dispatch('persistCart')
    dispatch('getQuotes')
  },

  setEuropeanUnionVisitor({ commit, dispatch }, europeanUnionVisitor) {
    commit(types.SET_EUROPEAN_UNION_VISITOR, europeanUnionVisitor)
    dispatch('persistCart')
  },

  setPaymentIntentId({ commit, dispatch }, paymentIntentId) {
    commit(types.SET_PAYMENT_INTENT_ID, paymentIntentId)
    dispatch('persistCart')
  },

  setDiscount({ commit }, discount: Discount) {
    commit(types.SET_DISCOUNT, { discount })
  },

  removeDiscount({ commit }) {
    commit(types.REMOVE_DISCOUNT)
  },

  setCartId({ commit }, cartId) {
    commit(types.SET_CART_ID, cartId)
  },

  setCartToken({ commit }, cartToken) {
    commit(types.SET_CART_TOKEN, cartToken)
  },

  async isEuropeanVisitor({ state }) {
    const countriesContinent = await countriesByContinent()
    const europeanCountries = countriesContinent?.find(({ name }) => name === 'Europe')?.countries || []
    const userCountry = state.shipping.country_code

    return europeanCountries.some((country) => country.code === userCountry)
  }
}

const mutations: MutationTree<State> = {
  [types.CLEAR_CART](state) {
    const defaultState = getDefaultState()

    state.cartId = defaultState.cartId
    state.cartToken = defaultState.cartToken
    state.paymentIntentId = defaultState.paymentIntentId
    state.items = defaultState.items
    state.order = defaultState.order
    state.shipping = defaultState.shipping
    state.quotes = defaultState.quotes
    state.updatingCart = defaultState.updatingCart
    state.loadingQuotes = defaultState.loadingQuotes
    state.europeanUnionVisitor = defaultState.europeanUnionVisitor
  },

  [types.ITEM_ADDED](state, { item }) {
    state.items = [...state.items, item]
  },

  [types.ITEM_REMOVED](state, { item }) {
    state.items = state.items.filter(
      ({ product_variant_id, size, promotion_id, video_recording_request }) =>
        product_variant_id !== item.product_variant_id ||
        size !== item.size ||
        promotion_id != item.promotion_id ||
        video_recording_request != item.video_recording_request
    )
  },

  [types.CART_ITEMS_SET](state, { items }) {
    state.items = items
  },

  [types.SET_QUANTITY](state, { item, quantity }) {
    let itemToUpdate = state.items.find(
      ({ product_variant_id, size, promotion_id, video_recording_request }) =>
        product_variant_id === item.product_variant_id &&
        size === item.size &&
        promotion_id == item.promotion_id &&
        video_recording_request == item.video_recording_request
    )
    itemToUpdate.quantity = quantity
  },

  [types.SET_CART_ID](state, cartId) {
    state.cartId = cartId
  },

  [types.SET_CART_TOKEN](state, cartToken) {
    state.cartToken = cartToken
  },

  [types.SET_ORDER](state, { order }) {
    state.order = order
  },

  [types.SET_SHIPPING](state, { shipping }) {
    state.shipping = shipping
  },

  [types.SET_SHIPPING_VALIDATED](state, { isShippingValidated }) {
    state.isShippingValidated = isShippingValidated
  },

  [types.SET_QUOTES](state, { quotes }) {
    state.quotes = {
      ...quotes,
      shipping: Number(quotes.shipping_price),
      tax: Number(quotes.sales_tax),
      discount: Number(quotes.discount)
    }
  },

  [types.SET_FB_PIXEL](state, fbPixelData) {
    state.fbPixelData = fbPixelData
  },

  [types.SET_EUROPEAN_UNION_VISITOR](state, europeanUnionVisitor) {
    state.europeanUnionVisitor = europeanUnionVisitor
  },

  [types.SET_PAYMENT_INTENT_ID](state, paymentIntentId) {
    state.paymentIntentId = paymentIntentId
  },

  [types.SET_ERRORS](state, { errors }) {
    state.errors = errors
  },

  [types.SET_LOADING_QUOTES](state, loadingQuotes) {
    state.loadingQuotes = loadingQuotes
  },

  [types.SET_UPDATING_CART](state, updatingCart) {
    state.updatingCart = updatingCart
  },

  [types.PHONE_NUMBER_REQUIRED](state, phoneNumberRequired) {
    state.phoneNumberRequired = phoneNumberRequired
  },

  [types.PHONE_NUMBER_OPTIONAL](state, phoneNumberOptional) {
    state.phoneNumberOptional = phoneNumberOptional
  },

  [types.FISCAL_CODE_REQUIRED](state, fiscalCodeRequired) {
    state.fiscalCodeRequired = fiscalCodeRequired
  },

  // If all items have upsell, remove the discount from first one
  [types.REMOVE_UPSELL](state) {
    let first_item = first(state.items)
    if (first_item) {
      first_item.promotion = ''
      first_item.promotion_id = ''
      first_item.upsell = false
      first_item.price = first_item.original_price
    }
  },

  [types.SET_DISCOUNT](state, { discount }) {
    state.discount = discount
    StorageService.persist(DISCOUNT_STORAGE_KEY, discount)
  },

  [types.REMOVE_DISCOUNT](state) {
    state.discount = undefined
    StorageService.remove(DISCOUNT_STORAGE_KEY)
  },

  [types.REMOVED_CAMPAIGN_VARIANTS_SET](state, { campaignVariantIds }) {
    state.removedCampaignVariantIds = campaignVariantIds
  }
}

const getters: GetterTree<State, RootState> = {
  cartId(state): number {
    return state.cartId
  },

  cartToken(state): string {
    return state.cartToken
  },

  items(state): CartItem[] {
    return state.items
  },

  subtotalPrice(state): number {
    if (state.quotes?.subtotal) {
      return parseFloat(state.quotes.subtotal)
    }

    if (state.items.length === 0) {
      return 0
    }

    return state.items.reduce(
      (sum, item) => sum + PricingService.effectivePrice(item.price, item.promotion) * item.quantity
    )
  },

  totalPrice(state): number {
    if (state.items.length === 0) {
      return 0
    }

    const quotedSubtotal = parseFloat(state.quotes?.subtotal)
    const calculatedSubtotal = state.items
      .map((item) => PricingService.effectivePrice(item.price, item.promotion) * item.quantity)
      .reduce((sum, i) => sum + i)

    const subtotal = quotedSubtotal || calculatedSubtotal
    const discount = state.quotes.discount || 0
    const totalPrice = subtotal + state.quotes.shipping + (state.quotes.tax || 0) - discount

    return round(Math.max(0, totalPrice), 2)
  },

  discountValue(state) {
    return state.quotes.discount || 0
  },

  order(state) {
    return state.order
  },

  shipping(state): ShippingAddress {
    return state.shipping
  },

  isShippingValidated(state) {
    return state.isShippingValidated
  },

  quotes(state) {
    return state.quotes
  },

  errors(state) {
    return state.errors
  },

  loadingQuotes(state) {
    return state.loadingQuotes
  },

  updatingCart(state) {
    return state.updatingCart
  },

  europeanUnionVisitor(state) {
    return state.europeanUnionVisitor
  },

  paymentIntentId(state) {
    return state.paymentIntentId
  },

  fbPixelData(state) {
    return state.fbPixelData
  },

  phoneNumberRequired(state) {
    return state.phoneNumberRequired
  },

  phoneNumberOptional(state) {
    return state.phoneNumberOptional
  },

  fiscalCodeRequired(state) {
    return state.fiscalCodeRequired
  },

  isDigital(state) {
    return state.items.every((item) => item.is_digital)
  },

  digitalItems(state): CartItem[] {
    return state.items.filter(isDigitalItem)
  },

  deliveryItems(state): CartItem[] {
    return state.items.filter(isDeliveryItem)
  },

  discount(state) {
    return state.discount
  },

  removedCampaignVariantIds(state) {
    return state.removedCampaignVariantIds
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
