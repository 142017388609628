import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import merge from 'lodash/merge'
import { VueConstructor } from 'vue'

import { config } from '@/config'
import { REPRESENT_PALETTE } from '@/constants'
import { Theme } from '@/types/store'

export const getDefaultTheme = (): Theme => cloneDeep(merge({}, REPRESENT_PALETTE, config.color_palette)) as any

const defaultTheme: Theme = getDefaultTheme()

export function translateColor(path: string, theme: Theme = defaultTheme): string {
  const color = get(theme, path) ?? get(theme, path.replace(/^.*?\./, 'base.')) // Fallback to base palette

  if (color === undefined) {
    throw `Missing color palette for ${path}`
  }

  return color
}

const cssVariablesToThemeValuesMap = {
  '--r-color-link': 'base.color.link',
  '--r-color-error': 'base.color.error',

  // Fields
  '--r-field-backgroundColor': 'base.form.control.background',
  '--r-field-borderColor': 'base.form.control.border',
  '--r-field-borderRadius': 'base.borderRadius.base',
  '--r-field-textColor--inactive': 'base.form.control.inactive',
  '--r-field-borderRadius--small': 'base.borderRadius.small',
  '--r-field-borderRadius--large': 'base.borderRadius.large',

  // Buttons
  '--r-button-bgColor--primary': 'base.button.primary.background',
  '--r-button-textColor--primary': 'base.button.primary.text',
  '--r-button-borderColor--primary': 'base.button.primary.border',
  '--r-button-borderWidth--primary': 'base.button.primary.borderWidth',
  '--r-button-bgColor--primary--hover': 'base.button.primary.hoverBackground',
  '--r-button-textColor--primary--hover': 'base.button.primary.hoverText',

  '--r-button-bgColor--secondary': 'base.button.secondary.background',
  '--r-button-textColor--secondary': 'base.button.secondary.text',
  '--r-button-borderColor--secondary': 'base.button.secondary.border',
  '--r-button-borderWidth--secondary': 'base.button.secondary.borderWidth',
  '--r-button-bgColor--secondary--hover': 'base.button.secondary.hoverBackground',
  '--r-button-textColor--secondary--hover': 'base.button.secondary.hoverText',

  // Inputs
  '--r-input-textColor': 'base.text.default',
  '--r-input-borderColor': 'base.form.input.border',
  '--r-input-borderColor--focus': 'base.form.input.border',
  '--r-input-bgColor': 'base.form.input.background',
  '--r-input-placeholderColor': 'base.form.input.placeholder',
  '--r-input-borderColor--disabled': 'base.form.input.inactive',
  '--r-input-textColor--disabled': 'base.form.input.inactive',

  // Legacy
  '--r-control-background-color': 'base.form.control.background',
  '--r-control-border-color': 'base.form.control.border',
  '--r-control-inactive-color': 'base.form.control.inactive'
}

const populateCssVariablesFromTheme = (theme: Theme) => {
  return Object.entries(cssVariablesToThemeValuesMap).reduce((acc, [variable, themeValuePath]) => {
    const value = get(theme, themeValuePath)
    return value ? { ...acc, [variable]: value } : acc
  }, {})
}

export const setCssVariables = (variables: Record<string, string>) => {
  Object.entries(variables).forEach(([key, value]) => document.documentElement.style.setProperty(key, value))
}

export const setCssVariablesFromTheme = (theme: Theme) => {
  setCssVariables(populateCssVariablesFromTheme(theme))
}

export default {
  install(Vue: VueConstructor) {
    Vue.prototype.$color = translateColor
  }
}

setCssVariablesFromTheme(defaultTheme)
