function findIndex(array: unknown[], item: unknown | ((item: unknown) => boolean)): number {
  return array.findIndex?.((i) => (typeof item === 'function' ? item(i) : item === i))
}

export function addItemToArray<T = unknown>(array: T[] | undefined, item: any, index?: number): T[] {
  const value = [...(array || [])]

  if (index === undefined) {
    value.push(item)
  } else {
    value.splice(index, 0, item)
  }

  return value
}

export function removeIndexFromArray<T = unknown>(array: T[] | undefined, index: number): T[] {
  const value = [...(array || [])]

  value.splice(index, 1)

  return value
}

export function removeItemFromArray<T = unknown>(array: T[] | undefined, item: T | ((item: T) => boolean)): T[] {
  return removeIndexFromArray(array, findIndex(array, item))
}

export function updateIndexInArray<T = unknown>(array: T[] | undefined, index: number, update: T): T[] {
  const value = [...(array || [])]

  if (typeof update === 'function') {
    update = update(value[index])
  }

  value.splice(index, 1, update)

  return value
}

export function updateItemInArray<T = unknown>(
  array: T[] | undefined,
  currentItem: T | ((item: T) => boolean),
  update: T
): T[] {
  return updateIndexInArray(array, findIndex(array, currentItem), update)
}

export function swapArrayItems<T = unknown>(array: T[] | undefined, index1: number, index2: number): T[] {
  const value = [...(array || [])]

  const item1 = value[index1]
  const item2 = value[index2]

  value.splice(index1, 1, item2)
  value.splice(index2, 1, item1)

  return value
}
