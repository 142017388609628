import axios from 'axios'

import { config } from '@/config'
import store from '@/packs/main/store'

export const apiClient = axios.create({
  baseURL: config.represent_api_url
})

export const apiAuthClient = axios.create({
  withCredentials: true,
  baseURL: config.represent_api_url,
  checkCurrentSessionOnUnauthorized: true
})

apiAuthClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 && error.config.checkCurrentSessionOnUnauthorized) {
      // check current session
      axios
        .get('/session', {
          withCredentials: true,
          baseURL: config.represent_api_url
        })
        .catch((err) => {
          // there is no session -> redirect user to sign in page
          if (err.response?.status === 401) {
            window.location.replace('/sign-in')
            return
          }

          throw err
        })
    } else {
      throw error
    }
  }
)

apiAuthClient.interceptors.request.use((request) => {
  if (['post', 'patch', 'put', 'delete'].includes(request.method)) {
    request.headers = { ...request.headers, 'X-CSRF-Token': store.getters['main/authenticityToken'] }
  }

  return request
})

apiAuthClient.interceptors.response.use((response) => {
  if (response?.headers['x-csrf-token']) {
    store.dispatch('main/setAuthenticityToken', response.headers['x-csrf-token'])
  }

  return response
})
