import Vue, { computed, Ref, ref } from 'vue'
import VueI18n from 'vue-i18n'

import StorageService from '@/services/StorageService'

export type Language = { code: string; name: string; flag: string }

Vue.use(VueI18n)

const localeStorageKey = 'locale'

export const languages: Readonly<Language[]> = Object.freeze([
  { code: 'en', name: 'English', flag: '🇺🇸' },
  { code: 'es', name: 'Español', flag: '🇪🇸' },
  { code: 'pt', name: 'Português', flag: '🇵🇹' },
  { code: 'de', name: 'Deutsch', flag: '🇩🇪' },
  { code: 'fr', name: 'Français', flag: '🇫🇷' },
  { code: 'it', name: 'Italiano', flag: '🇮🇹' },
  { code: 'cn', name: '中文', flag: '🇨🇳' },
  { code: 'id', name: 'Bahasa Indonesia', flag: '🇮🇩' },
  { code: 'th', name: 'ไทย', flag: '🇹🇭' }
])

const fallbackLocale = 'en'

const localeFiles = import.meta.glob('../locales/*.json', { eager: true })

// Transforms `path/to/locale.json` to `locale`
const getlanguageCodeFromPath = (path: string) => path.split('/').pop().split('.')[0]

function loadMessages() {
  return Object.entries(localeFiles).reduce(
    (acc, [path, content]) => ({
      ...acc,
      [getlanguageCodeFromPath(path)]: content
    }),
    {}
  )
}

export function getLanguageCode(): string {
  const locales = Object.keys(localeFiles).map(getlanguageCodeFromPath)

  const localeFromStore = StorageService.load(localeStorageKey)

  if (localeFromStore) {
    return localeFromStore
  }

  const localeFromUrl = window.navigator.language.slice(0, 2)

  if (locales.includes(localeFromUrl)) {
    return localeFromUrl
  }

  return fallbackLocale
}

const currentLocale = ref<string>(getLanguageCode())
const currentLanguage = computed<Language>(() => languages.find(({ code }) => code === currentLocale.value))

export function setLanguage(code: string) {
  i18n.locale = code
  currentLocale.value = code
  StorageService.persist(localeStorageKey, code)
}

export const i18n = new VueI18n({
  locale: getLanguageCode(),
  messages: loadMessages(),
  fallbackLocale
})

export function useLocale(): { currentLanguage: Ref<Language>; setLanguage: (code: string) => void } {
  return { currentLanguage, setLanguage }
}
