import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'

import { config, ipBasedLocation } from '@/config'
import { apiAuthClient } from '@/services/ApiService'
import { apiClient } from '@/services/ApiService'
import { apiResponseToPromoCodeList } from '@/services/dashboard/PromoCodeService'
import { Campaign, CampaignVariant } from '@/types'
import { Paginated } from '@/types/common'
import { Dashboard } from '@/types/dashboard'
import { Store } from '@/types/store'
import { Blocks } from '@/types/store'
import { loadFonts } from '@/utils/loadFonts'

export default {
  async index(page?: number, perPage: number = 8): Promise<Paginated<Store>> {
    return (await apiClient.get(`/stores/directory`, { params: { page: page, per_page: perPage } })).data
  },

  async store(store_slug: string, reviewToken?: string, preview?: string) {
    return axios
      .get('/stores/' + store_slug, {
        params: { review_token: reviewToken, preview: preview }
      })
      .then((response) => {
        let store = response.data
        const faviconUrl = store.next_customization
          ? store.next_customization?.urls?.favicon_url?.[0]?.url
          : store.urls?.favicon_url || store.customization?.favicon
        this.loadFavicon(faviconUrl, store.title)

        if (store.next_customization?.theme?.display_typeface || store.next_customization?.theme?.body_typeface) {
          loadFonts(store.next_customization?.theme?.display_typeface, store.next_customization?.theme?.body_typeface)
        }

        return {
          ...store,
          hero_url: store.urls?.header_image_url,
          mobile_hero_url: store.urls?.mobile_header_image_url,
          logo_url: store.urls?.logo_url,
          wide_logo_url: store.urls?.wide_logo_url
        }
      })
      .catch((error) => {
        console.error(error)
      })
  },

  async storeProducts({
    slug,
    countryCode,
    promotionCode,
    reviewToken,
    offset,
    limit,
    category,
    collectionId
  }: {
    slug: string
    countryCode?: string
    promotionCode?: string
    reviewToken?: string
    offset?: number
    limit?: number
    category?: string
    collectionId?: number
  }) {
    const ipLocation = await ipBasedLocation()
    return axios
      .get(`/stores/${slug}/products`, {
        params: {
          country: countryCode || ipLocation?.country_code,
          code: promotionCode,
          review_token: reviewToken,
          offset: offset,
          limit: limit,
          category: category,
          collection: collectionId
        }
      })
      .then((response) => response.data)
  },

  async storeCollectionProducts(campaignVariantIds: number[] = [], promotionCode?: string): Promise<CampaignVariant[]> {
    const { data } = await axios.get('/products/collection', {
      params: { campaign_variant_ids: campaignVariantIds.join(','), code: promotionCode }
    })

    return data.products
  },

  loadFavicon(faviconUrl, storeTitle) {
    if (faviconUrl && storeTitle) {
      let favicons = document.querySelectorAll('link[id="favicon"]') as NodeListOf<HTMLLinkElement>
      favicons.forEach((icon) => {
        icon.href = faviconUrl
      })
      let msFavicon = document.querySelector('meta[id="favicon"]') as HTMLLinkElement
      msFavicon.setAttribute('content', faviconUrl)

      const dynamicManifest = {
        'name': storeTitle,
        'icons': [
          {
            'src': faviconUrl,
            'sizes': '36x36',
            'type': 'image/jpg',
            'density': '0.75'
          },
          {
            'src': faviconUrl,
            'sizes': '48x48',
            'type': 'image/jpg',
            'density': '1.0'
          },
          {
            'src': faviconUrl,
            'sizes': '72x72',
            'type': 'image/jpg',
            'density': '1.5'
          },
          {
            'src': faviconUrl,
            'sizes': '96x96',
            'type': 'image/jpg',
            'density': '2.0'
          },
          {
            'src': faviconUrl,
            'sizes': '144x144',
            'type': 'image/jpg',
            'density': '3.0'
          },
          {
            'src': faviconUrl,
            'sizes': '192x192',
            'type': 'image/jpg',
            'density': '4.0'
          }
        ]
      }

      const oldManifest = document.getElementById('manifest')
      if (oldManifest) {
        oldManifest.remove()
      }
      let link = document.createElement('link') as HTMLLinkElement
      link.rel = 'manifest'
      link.setAttribute(
        'href',
        'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(dynamicManifest))
      )
      document.head.appendChild(link)
    }
  },

  setShippingErrors(response) {
    const errors = {}

    if (isEmpty(response.errors)) return errors

    const shippingAddressErrors = response.errors?.shipping_address
    if (isEmpty(shippingAddressErrors)) return errors

    // TODO remove this mapping when unified to either address or shipping
    if (!isEmpty(shippingAddressErrors?.shipping1)) {
      shippingAddressErrors.address1 = shippingAddressErrors.shipping1
      delete shippingAddressErrors.shipping1
    }
    if (!isEmpty(shippingAddressErrors?.shipping2)) {
      shippingAddressErrors.address2 = shippingAddressErrors.shipping2
      delete shippingAddressErrors.shipping2
    }

    errors['shipping'] = shippingAddressErrors

    return errors
  }
}

export async function loadAllStoreProducts(slug: string): Promise<Campaign[]> {
  return (await apiClient.get(`stores/${slug}/listed-products`)).data
}

export async function loadCollectionProducts(
  id: number,
  { promotionCode }: { promotionCode?: string } = {}
): Promise<Campaign[]> {
  const params = {
    code: promotionCode
  }

  return (await apiClient.get(`collections/${id}`, { params })).data.products
}

export function generateBlockColorStyles(blockData: any): Record<string, any> {
  return {
    backgroundColor: blockData.background_color,
    color: blockData.text_color
  }
}

export function generateBlockMarginStyles(blockData: any): Record<string, any> {
  const paddingVertical = blockData.padding_vertical ? '2rem' : '0'
  const paddingHorizontal = blockData.padding_horizontal ? '2rem' : '0'

  return {
    paddingLeft: paddingHorizontal,
    paddingRight: paddingHorizontal,
    paddingTop: paddingVertical,
    paddingBottom: paddingVertical
  }
}

export const namedSpaces: Readonly<Record<keyof typeof Blocks.spacingOptions, string>> = Object.freeze({
  none: '0rem',
  small: '0.5rem',
  medium: '1rem',
  large: '2rem'
})

export function generateBlockSpacingStyles(blockData: any): Record<string, any> {
  return {
    gap: namedSpaces[blockData.spacing] || '0'
  }
}

export async function getStorePromoCodes(storeId: number): Promise<Array<Dashboard.PromoCode>> | null {
  try {
    return apiResponseToPromoCodeList((await apiAuthClient.get(`/dashboard/stores/${storeId}/promo-codes`)).data)
  } catch (e) {
    return null
  }
}

export async function updateStorePromoCode(storeId: number, campaign_promotion: Dashboard.PromoCode) {
  return apiAuthClient.post(`/dashboard/stores/${storeId}/promo-codes/${campaign_promotion.id}`, { campaign_promotion })
}

export async function createStorePromoCode(storeId: number, campaign_promotion: Dashboard.PromoCode) {
  return apiAuthClient.post(`/dashboard/stores/${storeId}/promo-codes`, { campaign_promotion })
}

export async function loadStore(
  slug: string,
  options: { reviewToken?: string; preview?: string } = {}
): Promise<Store> {
  return (
    await axios.get(`/stores/${slug}`, {
      params: { review_token: options.reviewToken, preview: options.preview }
    })
  ).data
}
