import round from 'lodash/round'

import { Discount } from '@/types'

export default {
  // Price with applied discount
  effectivePrice(originalPrice: number, discount: Discount): number {
    let price = originalPrice

    if (discount && discount.type && discount.amount) {
      price -= this.discount(price, discount.type, discount.amount)
    }

    if (price < 0) {
      return 0
    }

    return round(price, 2)
  },

  discount(price: number, type: Discount['type'], amount: number): number {
    if (type === 'absolute') {
      return amount
    }

    return round(price * amount, 2) / 100
  }
}
