<template>
  <label class="RCheckbox" :class="{ 'items-center': $props.switch }">
    <input
      v-bind="$attrs"
      :checked="value"
      class="r-input"
      :class="{ 'opacity-0 w-10 h-[calc(1rem+6px)] absolute': $props.switch }"
      :required="required"
      type="checkbox"
      v-on="listeners"
    />

    <div
      v-if="$props.switch"
      class="-mt-px w-10 self-center rounded-full p-[3px] transition-colors duration-150 ease-out"
      :class="[value ? 'bg-primary' : 'bg-gray-400']"
    >
      <div
        class="aspect-square w-[1.1rem] rounded-full bg-white transition duration-150 ease-out"
        :class="{ 'translate-x-4': value }"
      ></div>
    </div>

    <div v-if="$slots.default || caption" class="mx-2 text-[0.96rem] leading-snug">
      <span v-if="required" class="text-error">*</span>

      <slot>
        {{ caption }}
      </slot>
    </div>
  </label>
</template>

<script lang="ts">
import Vue from 'vue'

import { transformInputListeners } from '@/components/mixins/transformInputListeners'

export default Vue.extend({
  mixins: [transformInputListeners],

  inheritAttrs: false,

  props: {
    value: Boolean,

    required: Boolean,

    switch: Boolean,

    caption: {
      type: String,
      default: ''
    }
  }
})
</script>

<style lang="postcss" scoped>
.RCheckbox {
  @apply relative inline-flex items-start align-top cursor-pointer;
}
</style>
