import { onUnmounted } from 'vue'

export function useOnEscapeKeyDown(callback: () => void, catchCondition?: () => boolean) {
  const handler = (e: KeyboardEvent) => {
    if ((catchCondition ? catchCondition() : true) && e.key === 'Escape') {
      e.preventDefault()
      callback()
    }
  }

  window.addEventListener('keydown', handler)

  onUnmounted(() => {
    window.removeEventListener('keydown', handler)
  })
}
