import { onUnmounted, Ref } from 'vue'

export function useOnClickOutside(
  element: Ref<HTMLElement | null>,
  callback: () => void,
  catchCondition?: () => boolean
) {
  const handler = (e: MouseEvent) => {
    if (!element.value?.contains(e.target as HTMLElement) && (catchCondition ? catchCondition() : true)) {
      callback()
    }
  }

  window.addEventListener('mousedown', handler)

  onUnmounted(() => {
    window.removeEventListener('mousedown', handler)
  })
}
