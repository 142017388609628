<template>
  <div v-if="alert">
    <div
      class="_alert relative rounded-lg py-5 px-4 md:px-32"
      :class="{
        'bg-red-50 text-red-700': alert.level === 'error',
        'bg-yellow-100 text-yellow-700': alert.level === 'warn',
        'bg-blue-100 text-blue-700': alert.level === 'info' || !alert.level,
        'bg-green-100 text-green-700': alert.level === 'success'
      }"
    >
      <div class="relative mx-auto max-w-4xl text-center text-lg">
        <span v-html="alert.message" />
      </div>

      <div
        class="_close-button absolute top-1/2 right-8 -translate-y-1/2 cursor-pointer p-2 text-xl leading-none"
        tabindex="-1"
        @click="hideAlert"
      >
        <RIcon class="h-5 w-5" name="x" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import RIcon from '@/components/ui/RIcon.vue'
import { config } from '@/config'

const alert = config.flash?.[0] as undefined | { level: string; message: string }

delete config.flash

export default Vue.extend({
  components: {
    RIcon
  },

  data() {
    return {
      alert
    }
  },

  watch: {
    $route() {
      this.alert = undefined
    }
  },

  mounted() {
    this.$root.$on('alert', this.handleAlert)

    if (config.flash) {
      delete config.flash
    }
  },

  beforeDestroy() {
    this.$root.$off('alert', this.handleAlert)
  },

  methods: {
    handleAlert(level: string, message: string) {
      this.alert = message ? { level, message } : undefined
    },

    hideAlert() {
      this.alert = undefined
    }
  }
})
</script>
