<template>
  <div class="space-y-4">
    <RFormGroup label="Style">
      <RSelect
        :disabled="Object.keys(styles) < 2"
        option-label-key="name"
        option-value-key="id"
        :options="styles"
        size="small"
        :value="selectedStyle.id"
        @input="selectStyle(Number($event))"
      />
    </RFormGroup>

    <RFormGroup label="Variant">
      <RSelect
        :disabled="Object.keys(selectedStyleVariants) < 2"
        option-label-key="name"
        option-value-key="id"
        :options="selectedStyleVariants"
        :value="selectedVariant.id"
        @input="selectCampaignVariant(Number($event))"
      />
    </RFormGroup>
  </div>
</template>

<script lang="ts">
import groupBy from 'lodash/groupBy'
import Vue, { PropType } from 'vue'

import RFormGroup from '@/components/ui/RFormGroup.vue'
import RSelect from '@/components/ui/RSelect.vue'
import { Campaign } from '@/types'

type CampaignVariant = {
  id: number
  name: string
  image: string
}

type Style = {
  id: number
  name: string
  campaignVariants: CampaignVariant[]
}

export default Vue.extend({
  components: {
    RSelect,
    RFormGroup
  },

  props: {
    value: {
      type: Number,
      required: true
    },

    campaign: {
      type: Object as PropType<Campaign>,
      required: true
    },

    checked: Boolean
  },

  computed: {
    styles(): Style[] {
      return Object.values(
        groupBy(this.campaign.campaign_variants, (campaign_variant) => campaign_variant.style.id)
      ).map((variants) => ({
        id: variants[0].style.id,
        name: variants[0].style.name,
        campaignVariants: (variants as any[]).map(({ id, variant, image }) => ({ id, image, name: variant.name }))
      }))
    },

    selectedStyleVariants(): CampaignVariant[] {
      return this.selectedStyle?.campaignVariants || []
    },

    selectedStyle(): Style {
      return this.styles.find((style) => style.campaignVariants.some(({ id }) => this.value === id))
    },

    selectedVariant(): CampaignVariant {
      return this.selectedStyle?.campaignVariants.find(({ id }) => this.value === id)
    }
  },

  methods: {
    selectStyle(styleId: number) {
      const style = this.styles.find((style) => style.id === styleId)

      if (style) {
        this.selectCampaignVariant(style.campaignVariants[0].id)
      }
    },

    selectCampaignVariant(value: number) {
      this.$emit('input', value)
    }
  }
})
</script>
