import Vue from 'vue'

export const transformInputListeners = Vue.extend({
  computed: {
    listeners(): any {
      const retrieveValue = (element: HTMLInputElement) => {
        if (element.getAttribute('type') === 'number') {
          return element.valueAsNumber
        }

        if (element.getAttribute('type') === 'checkbox') {
          return element.checked
        }

        return element.value
      }

      const targetValueEmitter = (name: string) => (e: KeyboardEvent) => {
        this.$emit(name, retrieveValue(e.target as HTMLInputElement))
      }

      return {
        ...this.$listeners,
        input: targetValueEmitter('input'),
        change: targetValueEmitter('change')
      }
    }
  }
})
