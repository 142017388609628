import { matchSorter } from 'match-sorter'
import { computed, ComputedRef, Ref, ref, unref } from 'vue'

export function useSearchFilter<T = any>(
  source: T[] | Ref<T[]>,
  options: Parameters<typeof matchSorter>[2] = {}
): { filteredItems: ComputedRef<T[]>; searchQuery: Ref<string> } {
  const searchQuery = ref<string>('')

  const filteredItems = computed<T[]>(() => {
    const query = searchQuery.value.trim()
    const items = unref(source)

    if (query) {
      return matchSorter(items, query, options) as T[]
    }

    return items
  })

  return { filteredItems, searchQuery }
}
