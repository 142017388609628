import { i18n } from '@/plugins/i18n'
import ValidationService, {
  isEmailValid,
  isPhoneValid,
  isRequired,
  ValidationResults,
  ValidationRules,
  Validator
} from '@/services/ValidationService'
import { ShippingAddress } from '@/types'

const zipRegex = {
  US: /(^\d{5}$)|(^\d{5}-\d{4}$)/i,
  IE: /^[AC-FHKNPRTV-Y]\d{2}\s?[0-9AC-FHKNPRTV-Y]{4}$/i
}

export const isFiscalCodeValid = (message?: string): Validator => {
  return ({ value, resolve, reject }) => {
    !/^\w{16}$/.test(value) ? reject(message) : resolve()
  }
}

export const zipValidator = (countryCode: string): Validator => {
  let regex = zipRegex[countryCode]

  return ({ value, resolve, reject }) => {
    regex && !regex.test(value) ? reject(i18n.tc('js.validations.shipping.invalid_zip')) : resolve()
  }
}

export async function validateShippingAddress(
  shippingAddress: Partial<ShippingAddress>,
  {
    requireEmail,
    requirePhoneNumber,
    requireFiscalCode,
    requireState
  }: {
    requireEmail?: boolean
    requirePhoneNumber?: boolean
    requireFiscalCode?: boolean
    requireState?: boolean
  } = {}
): Promise<ValidationResults> {
  const rules: ValidationRules<ShippingAddress> = {
    first_name: [isRequired(i18n.tc('js.address.enter_first_name'))],
    last_name: [isRequired(i18n.tc('js.address.enter_last_name'))],
    address1: [isRequired(i18n.tc('js.address.enter_street_address'))],
    city: [isRequired(i18n.tc('js.validations.shipping.city_blank'))],
    zip: [isRequired(i18n.tc('js.validations.shipping.zip_blank'))],
    country_code: [isRequired(i18n.tc('js.validations.shipping.country_blank'))],
    phone_number: [isPhoneValid('Phone number is too short')] // FIXME: non i18n string
  }

  if (requireEmail) {
    rules.email = [
      isRequired(i18n.tc('work_with_us_page.get_in_touch.validations.email')),
      isEmailValid(i18n.tc('work_with_us_page.get_in_touch.validations.email_format'))
    ]
  }

  rules.zip.push(zipValidator(shippingAddress.country_code))

  if (requireState) {
    rules.state = [isRequired(i18n.tc('js.validations.shipping.required_state'))]
  }

  if (requireFiscalCode) {
    rules.fiscal_code = [
      isRequired(i18n.tc('js.address.enter_fiscal_code')),
      isFiscalCodeValid(i18n.tc('js.address.invalid_fiscal_code'))
    ]
  }

  if (requirePhoneNumber) {
    rules.phone_number.unshift(isRequired('Enter your phone number')) // FIXME: non i18n string
  }

  const result = await ValidationService.validate(shippingAddress, rules)

  return result
}
