import axios from 'axios'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

import { RootState } from '@/packs/main/store'

const types = {
  SET_LOADING: 'SET_LOADING',
  SET_RESULT: 'SET_RESULT',
  SET_ERROR: 'SET_ERROR'
}

export type State = {
  isLoading: boolean
  error: any
  featured_assets: any[]
  categories: any[]
  flash_news: string[]
}

const state = (): State => ({
  isLoading: false,
  error: null,
  featured_assets: [],
  categories: [],
  flash_news: []
})

const mutations: MutationTree<State> = {
  [types.SET_LOADING](state) {
    state.isLoading = true
    state.error = null
  },

  [types.SET_RESULT](state, { featured_assets, categories, flash_news }) {
    state.isLoading = false
    state.error = null
    state.featured_assets = featured_assets
    state.categories = categories
    state.flash_news = flash_news
  },

  [types.SET_ERROR](state, error) {
    ;(state.isLoading = false), (state.error = error)
  }
}

const actions: ActionTree<State, RootState> = {
  load({ commit }) {
    commit(types.SET_LOADING)

    axios
      .get('/data/homepage')
      .then(({ data }) => {
        commit(types.SET_RESULT, data)
      })
      .catch((error) => {
        commit(types.SET_ERROR, error)
      })
  }
}

const getters: GetterTree<State, RootState> = {
  categories(state): any[] {
    return state.categories
  },
  featured_assets(state): { id: number; link: string; image: string; title: string }[] {
    return state.featured_assets.map((i) => ({
      id: i.id,
      link: i.url,
      image: i.image_url,
      title: i.title
    }))
  },
  flash_news(state): string[] {
    return state.flash_news || []
  },
  isLoading(state): boolean {
    return state.isLoading
  },
  error(state) {
    return state.error
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
