<template>
  <div>
    <div v-if="featured_assets" class="relative flex w-full flex-wrap items-start justify-start px-2 pt-6 pb-20">
      <a
        v-for="asset in featured_assets"
        :key="asset.id"
        class="_product my-5 w-full px-5 no-underline md:w-1/4"
        :href="kwTag ? asset.link + '?kw=' + kwTag : asset.link"
      >
        <img :alt="asset.title" :src="asset.image" />
        <div class="pt-4 text-center text-lg leading-tight">
          {{ asset.title }}
        </div>
      </a>
    </div>

    <div v-else>
      <Loading />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import Loading from '@/components/Loading.vue'

export default Vue.extend({
  components: {
    Loading
  },

  props: {
    kwTag: {
      type: String,
      default: ''
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters('home', ['featured_assets'])
  },

  mounted() {
    if (!this.featured_assets.length) {
      this.load()
    }
  },

  methods: {
    ...mapActions('home', ['load'])
  }
})
</script>
